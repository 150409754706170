import { useDispatch } from "react-redux";
import { useGetOrdersAppealMutation } from "../../Store/Builder/uvion.api";
import { setLoader, setOrdersAppeal } from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";

const useGetOrdersAppeal = () => {
  const dispatch = useDispatch();
  const [getOrdersAppeal] = useGetOrdersAppealMutation();

  const get = (params) => {
    dispatch(setLoader(true));
    getOrdersAppeal(params)
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        dispatch(setOrdersAppeal(res));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error account", "error");
      });
  };

  return {
    get,
  };
};

export default useGetOrdersAppeal;
