import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {CustomSelect} from "../../UI/Select";
import DatePicker from "rsuite/DatePicker";
import {DateRangePicker} from "rsuite";
import {FaClock} from "react-icons/fa";
import Button from "../../UI/Button";
import useCurrency from "../../Global/Api/currency.api";
import {useSelector} from "react-redux";
import useTeams from "../../Global/Api/getTeams.api";
import moment from "moment";
import useStatistics from "../../Global/Api/statistics.api";

import './index.scss'

export default function Statistics() {
  const apiCurrencies = useCurrency();
  const apiTeams = useTeams();
  const ApiStatistics = useStatistics();
  const [typeSelect, setTypeSelect] = useState(null);
  const [statusSelect, setStatusSelect] = useState(null);
  const [selectCurrency, setSelectCurrency] = useState(null);
  const [selectTeam, setSelectTeam] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [timeValue, setTimeValue] = useState(null);

  const [typeSelectTwo, setTypeSelectTwo] = useState(null);
  const [statusSelectTwo, setStatusSelectTwo] = useState(null);
  const [selectCurrencyTwo, setSelectCurrencyTwo] = useState(null);
  const [selectTeamTwo, setSelectTeamTwo] = useState(null);
  const [dateFromTwo, setDateFromTwo] = useState(null);
  const [dateToTwo, setDateToTwo] = useState(null);
  const [timeValueTwo, setTimeValueTwo] = useState(null);

  const [selectTeamThree, setSelectTeamThree] = useState(null);
  const [dateFromAvg, setDateFromAvg] = useState(null);
  const [dateToAvg, setDateToAvg] = useState(null);
  const [timeValueAvg, setTimeValueAvg] = useState(null);

  const [selectTeamFour, setSelectTeamFour] = useState(null);
  const [dateFromPercent, setDateFromPercent] = useState(null);
  const [dateToPercent, setDateToPercent] = useState(null);
  const [timeValuePercent, setTimeValuePercent] = useState(null);
  const [typeSelectThree, setTypeSelectThree] = useState(null);

  const [selectTeamFive, setSelectTeamFive] = useState(null);
  const [dateFromTraderAmounts, setDateFromTraderAmounts] = useState(null);
  const [dateToTraderAmounts, setDateToTraderAmounts] = useState(null);
  const [timeValueTraderAmounts, setTimeValueTraderAmounts] = useState(null);
  const [statusSelectThree, setStatusSelectThree] = useState(null);

  const [selectTeamSix, setSelectTeamSix] = useState(null);
  const [dateFromMerchantAmounts, setDateFromMerchantAmounts] = useState(null);
  const [dateToMerchantAmounts, setDateToMerchantAmounts] = useState(null);
  const [timeValueMerchantAmounts, setTimeValueMerchantAmounts] = useState(null);
  const [statusSelectFour, setStatusSelectFour] = useState(null);
  const [selectCurrencyThree, setSelectCurrencyThree] = useState(null);

  const {currencies, teams, profitTraders, profitMerchants, agentAvgStatistic, percentStatistic, traderAmountsStatistic, merchantAmountsStatistic} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );
  const statusData = [
    {
      label: 'По завершеным',
      value: 'success'
    },
    {
      label: 'По отмененным',
      value: 'cancel'
    },
  ]
  const typeData = [
    {
      label: 'Получение',
      value: 'buy'
    },
    {
      label: 'Выплата',
      value: 'sell'
    }
  ]

  useEffect(() => {
    const formattedParams = formatParams();
    apiCurrencies.get()
    apiTeams.get(formattedParams)
  }, [])

  useEffect(() => {

  }, []);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', 10000);
    return params
  }

  function extractTime(datetimeStr) {
    return moment(datetimeStr).format('HH:mm:ss');
  }

  const formatParamsProfitTraders = () => {
    const params = new URLSearchParams();
    params.append('currency_id', selectCurrency.value);
    params.append('order_type', typeSelect.value);
    params.append('order_status', statusSelect.value);
    if (selectTeam !== null) params.append('trader_id', selectTeam.value);
    if (dateFrom !== null) {
      if (timeValue !== null) {
        let newArray
        newArray = timeValue?.map(extractTime)
        params.append('date_from', moment(dateFrom.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFrom.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateTo !== null) {
      if (timeValue !== null) {
        let newArray
        newArray = timeValue?.map(extractTime)
        params.append('date_to', moment(dateTo.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateTo.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const formatParamsProfitMerchants = () => {
    const params = new URLSearchParams();
    params.append('currency_id', selectCurrencyTwo.value);
    params.append('order_type', typeSelectTwo.value);
    params.append('order_status', statusSelectTwo.value);
    if (selectTeamTwo !== null) params.append('merchant_id', selectTeamTwo.value);
    if (dateFromTwo !== null) {
      if (timeValueTwo !== null) {
        let newArray
        newArray = timeValueTwo?.map(extractTime)
        params.append('date_from', moment(dateFromTwo.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFromTwo.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateToTwo !== null) {
      if (timeValueTwo !== null) {
        let newArray
        newArray = timeValueTwo?.map(extractTime)
        params.append('date_to', moment(dateToTwo.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateToTwo.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const formatParamsAvg = () => {
    const params = new URLSearchParams();
    if (selectTeamThree !== null) params.append('trader_id', selectTeamThree.value);
    if (dateFromAvg !== null) {
      if (timeValueAvg !== null) {
        let newArray
        newArray = timeValueAvg?.map(extractTime)
        params.append('date_from', moment(dateFromAvg.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFromAvg.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateToAvg !== null) {
      if (timeValueAvg !== null) {
        let newArray
        newArray = timeValueAvg?.map(extractTime)
        params.append('date_to', moment(dateToAvg.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateToAvg.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const formatParamsPercent = () => {
    const params = new URLSearchParams();
    if (selectTeamFour !== null) params.append('trader_id', selectTeamFour.value);
    if (typeSelectThree !== null) params.append('order_type', typeSelectThree.value);
    if (dateFromAvg !== null) {
      if (timeValueAvg !== null) {
        let newArray
        newArray = timeValueAvg?.map(extractTime)
        params.append('date_from', moment(dateFromAvg.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFromAvg.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateToAvg !== null) {
      if (timeValueAvg !== null) {
        let newArray
        newArray = timeValueAvg?.map(extractTime)
        params.append('date_to', moment(dateToAvg.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateToAvg.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const formatParamsTraderAmounts = () => {
    const params = new URLSearchParams();
    if (selectTeamFive !== null) params.append('trader_id', selectTeamFive.value);
    if (statusSelectThree !== null) params.append('order_status', statusSelectThree.value);
    if (dateFromTraderAmounts !== null) {
      if (timeValueTraderAmounts !== null) {
        let newArray
        newArray = timeValueTraderAmounts?.map(extractTime)
        params.append('date_from', moment(dateFromTraderAmounts.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFromTraderAmounts.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateToTraderAmounts !== null) {
      if (timeValueTraderAmounts !== null) {
        let newArray
        newArray = timeValueTraderAmounts?.map(extractTime)
        params.append('date_to', moment(dateToTraderAmounts.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateToTraderAmounts.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const formatParamsMerchantAmounts = () => {
    const params = new URLSearchParams();
    if (selectTeamSix !== null) params.append('merchant_id', selectTeamSix.value);
    if (statusSelectFour !== null) params.append('order_status', statusSelectFour.value);
    if (selectCurrencyThree !== null) params.append('currency_id', selectCurrencyThree.value);
    if (dateFromMerchantAmounts !== null) {
      if (timeValueMerchantAmounts !== null) {
        let newArray
        newArray = timeValueMerchantAmounts?.map(extractTime)
        params.append('date_from', moment(dateFromMerchantAmounts.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFromMerchantAmounts.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateToMerchantAmounts !== null) {
      if (timeValueMerchantAmounts !== null) {
        let newArray
        newArray = timeValueMerchantAmounts?.map(extractTime)
        params.append('date_to', moment(dateToMerchantAmounts.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateToMerchantAmounts.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const sendProfitTraders = () => {
    const formattedParams = formatParamsProfitTraders();
    ApiStatistics.getProfitTraders(formattedParams)
  }

  const sendProfitMerchants = () => {
    const formattedParams = formatParamsProfitMerchants();
    ApiStatistics.getProfitMerchants(formattedParams)
  }

  const sendAvgStatistics = () => {
    const formattedParams = formatParamsAvg();
    ApiStatistics.getAvg(formattedParams)
  }

  const sendStatisticPercent = () => {
    const formattedParams = formatParamsPercent();
    ApiStatistics.getPercent(formattedParams)
  }

  const sendStatisticTraderAmounts = () => {
    const formattedParams = formatParamsTraderAmounts();
    ApiStatistics.getTraderAmounts(formattedParams)
  }

  const sendStatisticMerchantAmounts = () => {
    const formattedParams = formatParamsMerchantAmounts();
    ApiStatistics.getMerchantAmounts(formattedParams)
  }


  return (
    <div className='statistics'>
      <Helmet>
        <title>UVION | СТАТИСТИКА</title>
      </Helmet>
      <p className="profile__title">Статистика по возврату прибыли трейдера агенту</p>
      <div className="profile__box">
        <div className="profile__box-filter">
          <div className="profile__box-filter-row">
            <div className="profile__select-container">
              <div className="profile__select">
                <CustomSelect
                  options={typeData}
                  selected={typeSelect}
                  placeholder='Тип заявок*'
                  isSearchable={false}
                  handleSelect={(e) => {
                    setTypeSelect(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
              <div className="profile__select">
                <CustomSelect
                  options={statusData}
                  selected={statusSelect}
                  placeholder='Статус заявок*'
                  isSearchable={false}
                  handleSelect={(e) => {
                    setStatusSelect(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="profile__select-container">
              <div className="profile__select">
                <CustomSelect
                  options={currencies.map((currency) => {
                    return {
                      value: currency.id,
                      label: currency.currency,
                    };
                  })}
                  selected={selectCurrency}
                  placeholder='Выберите валюту*'
                  isSearchable={false}
                  handleSelect={(e) => {
                    setSelectCurrency(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
              <div className="profile__select">
                <CustomSelect
                  options={teams?.filter(item => item?.role === 'trader')?.map((team) => {
                    return {
                      value: team.id,
                      label: team.notice,
                    };
                  })}
                  selected={selectTeam}
                  placeholder='Выберите команду'
                  isSearchable={true}
                  handleSelect={(e) => {
                    setSelectTeam(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="profile__box-filter-row">
            <div className="profile__select-container">
              <div className="profile__date">
                <DatePicker value={dateFrom} onChange={setDateFrom} locale={{ok: 'Применить'}}
                            className='filter-calendar' placeholder='От'
                            format="MM/dd/yyyy"/>
              </div>
              <div className="profile__date">
                <DatePicker value={dateTo} onChange={setDateTo} locale={{ok: 'Применить'}} className='filter-calendar'
                            placeholder='До'
                            format="MM/dd/yyyy"/>
              </div>
            </div>
            <div className="profile__select-container">
              <div className="profile__time">
                <DateRangePicker
                  format="HH:mm:ss"
                  caretAs={FaClock}
                  value={timeValue}
                  onChange={setTimeValue}
                  locale={{ok: 'Применить'}}
                  placeholder={'Выберите время'}/>
              </div>
              <div className="profile__btn">
                <Button
                  text='Применить'
                  type='accept'
                  disabled={((dateFrom !== null || dateTo !== null) && timeValue === null) || (selectCurrency === null || typeSelect === null || statusSelect === null) ? true : false}
                  bottomPadding={8}
                  topPadding={8}
                  onClick={sendProfitTraders}
                />
              </div>
            </div>
          </div>
          <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
        </div>
        <div className={`orders__top`}>
          <div className="orders__top-left">
            <div className="orders__top-block block__pattern">
              <div className='pattern-1'></div>
              <p className="orders__top-block-title">Оборот за выбранный период</p>
              <p
                className="orders__top-block-value">{Number(profitTraders?.agent_trader_amounts ? profitTraders?.agent_trader_amounts : 0).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} </p>
              <p className="orders__top-block-subtitle">Сумма заработанная агентом за выбранный период</p>
            </div>
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок</p>
              <p
                className="orders__top-block-value">{profitTraders?.count_orders ? profitTraders?.count_orders : 0}</p>
              <p className="orders__top-block-subtitle">Количество заявок за выбранный период</p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile__wrapper">
        <p className="profile__title">Статистика по общему обороту трейдера</p>
        <div className="profile__box profile__box_ml">
          <div className="profile__box-filter">
            <div className="profile__box-filter-mobile">
              <div className="profile__box-filter-row">
                <div className="profile__select">
                  <CustomSelect
                    options={statusData}
                    selected={statusSelectThree}
                    placeholder='Статус заявок*'
                    isSearchable={false}
                    handleSelect={(e) => {
                      setStatusSelectThree(e ?? null)
                    }}
                    isClearable={true}
                  />
                </div>
                <div className="profile__select">
                  <CustomSelect
                    options={teams?.filter(item => item?.role === 'trader')?.map((team) => {
                      return {
                        value: team.id,
                        label: team.notice,
                      };
                    })}
                    selected={selectTeamFive}
                    placeholder='Выберите команду*'
                    isSearchable={true}
                    handleSelect={(e) => {
                      setSelectTeamFive(e ?? null)
                    }}
                    isClearable={true}
                  />
                </div>
                <div className="profile__btn profile__btn_pc">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={((dateFromTraderAmounts !== null || dateToTraderAmounts !== null) && timeValueTraderAmounts === null) || (statusSelectThree === null || selectTeamFive === null) ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendStatisticTraderAmounts}
                  />
                </div>
              </div>
              <div className="profile__box-filter-row">
                <div className="profile__time">
                  <DateRangePicker
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    value={timeValueTraderAmounts}
                    onChange={setTimeValueTraderAmounts}
                    locale={{ok: 'Применить'}}
                    placeholder={'Выберите время'}/>
                </div>
                <div className="profile__date">
                  <DatePicker value={dateFromTraderAmounts} onChange={setDateFromTraderAmounts} locale={{ok: 'Применить'}}
                              className='filter-calendar' placeholder='От'
                              format="MM/dd/yyyy"
                  />
                </div>
                <div className="profile__date">
                  <DatePicker value={dateToTraderAmounts} onChange={setDateToTraderAmounts} locale={{ok: 'Применить'}}
                              className='filter-calendar'
                              placeholder='До'
                              format="MM/dd/yyyy"
                  />
                </div>
                <div className="profile__btn profile__btn_mobile">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={((dateFromTraderAmounts !== null || dateToTraderAmounts !== null) && timeValueTraderAmounts === null) || (statusSelectThree === null || selectTeamFive === null) ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendStatisticTraderAmounts}
                  />
                </div>
              </div>
              <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
            </div>
          </div>
        </div>
      </div>
      <div className="profile__box-counters">
        <div className={`orders__top`}>
          <div className="orders__top-left">
            <div className="orders__top-block block__pattern">
              <div className='pattern-5'></div>
              <p className="orders__top-block-title">Оборот по получениям</p>
              <p
                className="orders__top-block-value">{Number(traderAmountsStatistic?.common_amount_buy ? traderAmountsStatistic?.common_amount_buy : 0).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} {traderAmountsStatistic?.currency?.currency}</p>
              <p className="orders__top-block-subtitle">Сумма заявок на получение
                в {traderAmountsStatistic?.currency?.currency}</p>
            </div>
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок на получение</p>
              <p
                className="orders__top-block-value">{traderAmountsStatistic?.count_orders_buy ? traderAmountsStatistic?.count_orders_buy : 0}</p>
              <p className="orders__top-block-subtitle">Количество заявок на получение</p>
            </div>
          </div>
        </div>
        <div className={`orders__top`}>
          <div className="orders__top-left">
            <div className="orders__top-block block__pattern">
              <div className='pattern-6'></div>
              <p className="orders__top-block-title">Оборот по выплатам</p>
              <p
                className="orders__top-block-value">{Number(traderAmountsStatistic?.common_amount_sell ? traderAmountsStatistic?.common_amount_sell : 0).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} {traderAmountsStatistic?.currency?.currency}</p>
              <p className="orders__top-block-subtitle">Сумма заявок на выплату
                в {traderAmountsStatistic?.currency?.currency}</p>
            </div>
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок на выплату</p>
              <p
                className="orders__top-block-value">{traderAmountsStatistic?.count_orders_sell ? traderAmountsStatistic?.count_orders_sell : 0}</p>
              <p className="orders__top-block-subtitle">Количество заявок на выплаты</p>
            </div>
          </div>
        </div>
      </div>

      <p className="profile__title mt-12">Статистика по возврату прибыли мерчанта агенту</p>
      <div className="profile__box">
        <div className="profile__box-filter">
          <div className="profile__box-filter-row">
            <div className="profile__select-container">
              <div className="profile__select">
                <CustomSelect
                  options={typeData}
                  selected={typeSelectTwo}
                  placeholder='Тип заявок*'
                  isSearchable={false}
                  handleSelect={(e) => {
                    setTypeSelectTwo(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
              <div className="profile__select">
                <CustomSelect
                  options={statusData}
                  selected={statusSelectTwo}
                  placeholder='Статус заявок*'
                  isSearchable={false}
                  handleSelect={(e) => {
                    setStatusSelectTwo(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="profile__select-container">
              <div className="profile__select">
                <CustomSelect
                  options={currencies.map((currency) => {
                    return {
                      value: currency.id,
                      label: currency.currency,
                    };
                  })}
                  selected={selectCurrencyTwo}
                  placeholder='Выберите валюту*'
                  isSearchable={false}
                  handleSelect={(e) => {
                    setSelectCurrencyTwo(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
              <div className="profile__select">
                <CustomSelect
                  options={teams?.filter(item => item?.role === 'merchant_server')?.map((team) => {
                    return {
                      value: team.id,
                      label: team.notice,
                    };
                  })}
                  selected={selectTeamTwo}
                  placeholder='Выберите команду'
                  isSearchable={true}
                  handleSelect={(e) => {
                    setSelectTeamTwo(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="profile__box-filter-row">
            <div className="profile__select-container">
              <div className="profile__date">
                <DatePicker value={dateFromTwo} onChange={setDateFromTwo} locale={{ok: 'Применить'}}
                            className='filter-calendar' placeholder='От'
                            format="MM/dd/yyyy"/>
              </div>
              <div className="profile__date">
                <DatePicker value={dateToTwo} onChange={setDateToTwo} locale={{ok: 'Применить'}} className='filter-calendar'
                            placeholder='До'
                            format="MM/dd/yyyy"/>
              </div>
            </div>
            <div className="profile__select-container">
              <div className="profile__time">
                <DateRangePicker
                  format="HH:mm:ss"
                  caretAs={FaClock}
                  value={timeValueTwo}
                  onChange={setTimeValueTwo}
                  locale={{ok: 'Применить'}}
                  placeholder={'Выберите время'}/>
              </div>
              <div className="profile__btn">
                <Button
                  text='Применить'
                  type='accept'
                  disabled={((dateFromTwo !== null || dateToTwo !== null) && timeValueTwo === null) || (selectCurrencyTwo === null || typeSelectTwo === null || statusSelectTwo === null) ? true : false}
                  bottomPadding={8}
                  topPadding={8}
                  onClick={sendProfitMerchants}
                />
              </div>
            </div>
          </div>
          <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
        </div>
        <div className={`orders__top`}>
          <div className="orders__top-left">
            <div className="orders__top-block block__pattern">
              <div className='pattern-1'></div>
              <p className="orders__top-block-title">Оборот за выбранный период</p>
              <p
                className="orders__top-block-value">{Number(profitMerchants?.agent_trader_amounts ? profitMerchants?.agent_trader_amounts : 0).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} </p>
              <p className="orders__top-block-subtitle">Сумма заработанная агентом за выбранный период</p>
            </div>
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок</p>
              <p
                className="orders__top-block-value">{profitMerchants?.count_orders ? profitMerchants?.count_orders : 0}</p>
              <p className="orders__top-block-subtitle">Количество заявок за выбранный период</p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile__wrapper">
        <p className="profile__title">Статистика по общему обороту мерчанта</p>
        <div className="profile__box profile__box_ml">
          <div className="profile__box-filter">
            <div className="profile__box-filter-mobile">
              <div className="profile__box-filter-row">
                <div className="profile__select">
                  <CustomSelect
                    options={statusData}
                    selected={statusSelectFour}
                    placeholder='Статус заявок*'
                    isSearchable={false}
                    handleSelect={(e) => {
                      setStatusSelectFour(e ?? null)
                    }}
                    isClearable={true}
                  />
                </div>
                <div className="profile__select">
                  <CustomSelect
                    options={teams?.filter(item => item?.role === 'merchant_server')?.map((team) => {
                      return {
                        value: team.id,
                        label: team.notice,
                      };
                    })}
                    selected={selectTeamSix}
                    placeholder='Выберите команду*'
                    isSearchable={true}
                    handleSelect={(e) => {
                      setSelectTeamSix(e ?? null)
                    }}
                    isClearable={true}
                  />
                </div>
                <div className="profile__select">
                  <CustomSelect
                    options={currencies?.map((currency) => {
                      return {
                        value: currency.id,
                        label: currency.currency,
                      };
                    })}
                    selected={selectCurrencyThree}
                    placeholder='Выберите валюту*'
                    isSearchable={true}
                    handleSelect={(e) => {
                      setSelectCurrencyThree(e ?? null)
                    }}
                    isClearable={true}
                  />
                </div>
                <div className="profile__btn profile__btn_pc">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={((dateFromMerchantAmounts !== null || dateToMerchantAmounts !== null) && timeValueMerchantAmounts === null) || (statusSelectFour === null || selectTeamSix === null || selectCurrencyThree === null) ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendStatisticMerchantAmounts}
                  />
                </div>
              </div>
              <div className="profile__box-filter-row">
                <div className="profile__time">
                  <DateRangePicker
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    value={timeValueMerchantAmounts}
                    onChange={setTimeValueMerchantAmounts}
                    locale={{ok: 'Применить'}}
                    placeholder={'Выберите время'}/>
                </div>
                <div className="profile__date">
                  <DatePicker value={dateFromMerchantAmounts} onChange={setDateFromMerchantAmounts} locale={{ok: 'Применить'}}
                              className='filter-calendar' placeholder='От'
                              format="MM/dd/yyyy"
                  />
                </div>
                <div className="profile__date">
                  <DatePicker value={dateToMerchantAmounts} onChange={setDateToMerchantAmounts} locale={{ok: 'Применить'}}
                              className='filter-calendar'
                              placeholder='До'
                              format="MM/dd/yyyy"
                  />
                </div>
                <div className="profile__btn profile__btn_mobile">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={((dateFromMerchantAmounts !== null || dateToMerchantAmounts !== null) && timeValueMerchantAmounts === null) || (statusSelectFour === null || selectTeamSix === null || selectCurrencyThree === null) ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendStatisticMerchantAmounts}
                  />
                </div>
              </div>
              <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
            </div>
          </div>
        </div>
      </div>
      <div className="profile__box-counters">
        <div className={`orders__top`}>
          <div className="orders__top-left">
            <div className="orders__top-block block__pattern">
              <div className='pattern-5'></div>
              <p className="orders__top-block-title">Оборот по получениям</p>
              <p
                className="orders__top-block-value">{Number(merchantAmountsStatistic?.common_amount_buy ? merchantAmountsStatistic?.common_amount_buy : 0).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} {merchantAmountsStatistic?.currency?.currency}</p>
              <p className="orders__top-block-subtitle">Сумма заявок на получение
                в {merchantAmountsStatistic?.currency?.currency}</p>
            </div>
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок на получение</p>
              <p
                className="orders__top-block-value">{merchantAmountsStatistic?.count_orders_buy ? merchantAmountsStatistic?.count_orders_buy : 0}</p>
              <p className="orders__top-block-subtitle">Количество заявок на получение</p>
            </div>
          </div>
        </div>
        <div className={`orders__top`}>
          <div className="orders__top-left">
            <div className="orders__top-block block__pattern">
              <div className='pattern-6'></div>
              <p className="orders__top-block-title">Оборот по выплатам</p>
              <p
                className="orders__top-block-value">{Number(merchantAmountsStatistic?.common_amount_sell ? merchantAmountsStatistic?.common_amount_sell : 0).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} {merchantAmountsStatistic?.currency?.currency}</p>
              <p className="orders__top-block-subtitle">Сумма заявок на выплату
                в {merchantAmountsStatistic?.currency?.currency}</p>
            </div>
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок на выплату</p>
              <p
                className="orders__top-block-value">{merchantAmountsStatistic?.count_orders_sell ? merchantAmountsStatistic?.count_orders_sell : 0}</p>
              <p className="orders__top-block-subtitle">Количество заявок на выплаты</p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile__inner">
        <div className="profile__wrapper">
          <p className="profile__title">Статистика среднего времени выполнения заявкок</p>
          <div className="profile__box profile__box_ml">
            <div className="profile__box-filter">
              <div className="profile__box-filter-row">
                <div className="profile__select">
                  <div className="profile__select">
                    <CustomSelect
                      options={teams?.filter(item => item?.role === 'trader')?.map((team) => {
                        return {
                          value: team.id,
                          label: team.notice,
                        };
                      })}
                      selected={selectTeamThree}
                      placeholder='Выберите команду*'
                      isSearchable={true}
                      handleSelect={(e) => {
                        setSelectTeamThree(e ?? null)
                      }}
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className="profile__btn profile__btn_pc">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={((dateFromAvg !== null || dateToAvg !== null) && timeValueAvg === null) || selectTeamThree === null ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendAvgStatistics}
                  />
                </div>
              </div>
              <div className="profile__box-filter-row">
                <div className="profile__date">
                  <DatePicker value={dateFromAvg} onChange={setDateFromAvg} locale={{ok: 'Применить'}}
                              className='filter-calendar' placeholder='От'
                              format="MM/dd/yyyy"/>
                </div>
                <div className="profile__date">
                  <DatePicker value={dateToAvg} onChange={setDateToAvg} locale={{ok: 'Применить'}}
                              className='filter-calendar'
                              placeholder='До'
                              format="MM/dd/yyyy"/>
                </div>
                <div className="profile__time">
                  <DateRangePicker
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    value={timeValueAvg}
                    onChange={setTimeValueAvg}
                    locale={{ok: 'Применить'}}
                    placeholder={'Выберите время'}/>
                </div>
                <div className="profile__btn profile__btn_mobile">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={((dateFromAvg !== null || dateToAvg !== null) && timeValueAvg === null) || selectTeamThree === null ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendAvgStatistics}
                  />
                </div>
              </div>
              <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
            </div>
            <div className={`orders__top`}>
              <div className="orders__top-block orders__top-block_yellow orders__top-block_min">
                <div className='pattern-4'></div>
                <p className="orders__top-block-title">Среднее время выволнения заявок</p>
                <p className="orders__top-block-value">{agentAvgStatistic?.avg_time ? agentAvgStatistic?.avg_time : 0}</p>
                <p className="orders__top-block-subtitle">Среднее время выволнения заявок за выбранный период</p>
              </div>
            </div>
          </div>
        </div>
        <div className="profile__wrapper">
          <p className="profile__title">Статистика успешных заявок в процентах</p>
          <div className="profile__box profile__box_ml">
            <div className="profile__box-filter">
              <div className="profile__box-filter-mobile">

                <div className="profile__box-filter-row">
                  <div className="profile__select">
                    <CustomSelect
                      options={typeData}
                      selected={typeSelectThree}
                      placeholder='Тип заявок*'
                      isSearchable={false}
                      handleSelect={(e) => {
                        setTypeSelectThree(e ?? null)
                      }}
                      isClearable={true}
                    />
                  </div>
                  <div className="profile__select">
                    <CustomSelect
                      options={teams?.filter(item => item?.role === 'trader')?.map((team) => {
                        return {
                          value: team.id,
                          label: team.notice,
                        };
                      })}
                      selected={selectTeamFour}
                      placeholder='Выберите команду*'
                      isSearchable={true}
                      handleSelect={(e) => {
                        setSelectTeamFour(e ?? null)
                      }}
                      isClearable={true}
                    />
                  </div>
                  <div className="profile__btn profile__btn_pc">
                    <Button
                      text='Применить'
                      type='accept'
                      disabled={((dateFromPercent !== null || dateToPercent !== null) && timeValuePercent === null) || (selectTeamFour === null || typeSelectThree === null) ? true : false}
                      bottomPadding={8}
                      topPadding={8}
                      onClick={sendStatisticPercent}
                    />
                  </div>
                </div>
                <div className="profile__box-filter-row">
                  <div className="profile__time">
                    <DateRangePicker
                      format="HH:mm:ss"
                      caretAs={FaClock}
                      value={timeValuePercent}
                      onChange={setTimeValuePercent}
                      locale={{ok: 'Применить'}}
                      placeholder={'Выберите время'}/>
                  </div>
                  <div className="profile__date">
                    <DatePicker value={dateFromPercent} onChange={setDateFromPercent} locale={{ok: 'Применить'}}
                                className='filter-calendar' placeholder='От'
                                format="MM/dd/yyyy"
                    />
                  </div>
                  <div className="profile__date">
                    <DatePicker value={dateToPercent} onChange={setDateToPercent} locale={{ok: 'Применить'}}
                                className='filter-calendar'
                                placeholder='До'
                                format="MM/dd/yyyy"
                    />
                  </div>
                  <div className="profile__btn profile__btn_mobile">
                    <Button
                      text='Применить'
                      type='accept'
                      disabled={((dateFromPercent !== null || dateToPercent !== null) && timeValuePercent === null) || (selectTeamFour === null || typeSelectThree === null) ? true : false}
                      bottomPadding={8}
                      topPadding={8}
                      onClick={sendStatisticPercent}
                    />
                  </div>
                </div>
                <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>

              </div>
            </div>

            <div className="profile__block">
              <div className="orders__top-block">
                <p className="orders__top-block-title">Кол-во заявок</p>
                <p className="orders__top-block-value">{percentStatistic?.all_orders_count ? percentStatistic?.all_orders_count : 0}</p>
                <p className="orders__top-block-subtitle">Общее количество заявок</p>
              </div>
            </div>

            <div className="profile__block">
              <div className="orders__top-block">
                <p className="orders__top-block-title">Кол-во заявок</p>
                <p className="orders__top-block-value">{percentStatistic?.success_orders_count ? percentStatistic?.success_orders_count : 0}</p>
                <p className="orders__top-block-subtitle">Количество заявок в успехе</p>
              </div>
            </div>

            <div className="profile__block">
              <div className="orders__top-block orders__top-block_yellow">
                <p className="orders__top-block-title">Процент успешных</p>
                <p className="orders__top-block-value">{percentStatistic?.success_orders_percentage ? percentStatistic?.success_orders_percentage + '%' : 0}</p>
                <p className="orders__top-block-subtitle">Процент заявок в успехе</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
