import {useDispatch} from "react-redux";
import {
  setLoader,
  setProfitTraders,
  setProfitMerchants,
  setAgentAvgStatistic,
  setPercentStatistic, setTraderAmountsStatistic, setMerchantAmountsStatistic
} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";
import {
  useGetProfitTradersMutation, useGetProfitMerchantsMutation, useGetAvgMutation,
  useGetPercentMutation,
  useGetTraderAmountsMutation,
  useGetMerchantAmountsMutation
} from "../../Store/Builder/uvion.api";

const useStatistics = () => {
  const dispatch = useDispatch();
  const [apiProfitTraders] = useGetProfitTradersMutation();
  const [apiProfitMerchants] = useGetProfitMerchantsMutation();
  const [apiGetAvg] = useGetAvgMutation();
  const [apiGetPercent] = useGetPercentMutation();
  const [apiGetTraderAmounts] = useGetTraderAmountsMutation();
  const [apiGetMerchantAmounts] = useGetMerchantAmountsMutation();

  const getProfitTraders = (params) => {
    dispatch(setLoader(true))
    apiProfitTraders(params)
      .unwrap()
      .then((res) => {
        dispatch(setProfitTraders(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getProfitMerchants = (params) => {
    dispatch(setLoader(true))
    apiProfitMerchants(params)
      .unwrap()
      .then((res) => {
        dispatch(setProfitMerchants(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getAvg = (params) => {
    dispatch(setLoader(true))
    apiGetAvg(params)
      .unwrap()
      .then((res) => {
        dispatch(setAgentAvgStatistic(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getPercent = (params) => {
    dispatch(setLoader(true))
    apiGetPercent(params)
      .unwrap()
      .then((res) => {
        dispatch(setPercentStatistic(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getTraderAmounts = (params) => {
    dispatch(setLoader(true))
    apiGetTraderAmounts(params)
      .unwrap()
      .then((res) => {
        dispatch(setTraderAmountsStatistic(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getMerchantAmounts = (params) => {
    dispatch(setLoader(true))
    apiGetMerchantAmounts(params)
      .unwrap()
      .then((res) => {
        dispatch(setMerchantAmountsStatistic(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    getProfitTraders,
    getProfitMerchants,
    getAvg,
    getPercent,
    getTraderAmounts,
    getMerchantAmounts,
  };
};

export default useStatistics;


