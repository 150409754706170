import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import Device from "../../Components/Device";
import useDevises from "../../Global/Api/devises.api";

import './index.scss'
import Popup from "../../Components/Popup";
import Button from "../../UI/Button";
import {Helmet} from "react-helmet";

export default function Automatic() {
  const getDevises = useDevises();
  const [deleteModal, setDeleteModal] = useState(false);
  const [infoDevise, setInfoDevise] = useState(null);

  const devisesData = useSelector(
    (state) => state.rootReducer.uvionSlice.devisesData
  );

  useEffect(() => {
    getDevises.get()
  }, [])

  useEffect(() => {
    if (deleteModal) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = 'visible'
    }
  }, [deleteModal])

  const openDeleteModal = (item) => {
    setInfoDevise(item)
    setDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setInfoDevise(null)
    setDeleteModal(false)
  }

  const deleteSendData = () => {
    getDevises.deletedDevise({devise_id: infoDevise?.id}, closeDeleteModal)
  }

  const renderDevises = () => {
    return devisesData?.map(item => (
      <Device key={item?.id} item={item} openDeleteModal={openDeleteModal}/>
    ))
  }

  return (
    <div className='automatic'>
      <Helmet>
        <title>UVION | АВТОМАТИКА</title>
      </Helmet>
      {deleteModal && (
        <Popup title='Удалить устройство' closePopup={closeDeleteModal}>
          <div className="ads__modal-wrapper">
            <p className="ads__modal-text">Вы уверены что хотите удалить устройство?</p>
            <div className="ads__modal-btns">
              <div className="ads__modal-btn">
                <Button
                  text='Удалить'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={deleteSendData}
                />
              </div>
              <div className="ads__modal-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      <div className="automatic__top">

      </div>
      <p className="automatic__title">Список устройств</p>
      <div className="automatic__table-container">
        <div className="automatic__table">
          <div className="automatic__table-top">
            <div className="automatic__table-head">
              <p>Название</p>
            </div>
            <div className="automatic__table-head">
              <p>Статус</p>
            </div>
            <div className="automatic__table-head">
              <p>Версия</p>
            </div>
            <div className="automatic__table-head">
              <p>Слот</p>
            </div>
            <div className="automatic__table-head">
              <p>Действия</p>
            </div>
          </div>
          <div className="orders__table-list">
            {renderDevises()}
          </div>
        </div>
      </div>
    </div>
  )
}
