import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";

import Button from "../../UI/Button";
import Popup from "../Popup";
import useOnboarding from "../../Global/Api/onboarding.api";

import './index.scss'
import {useSelector} from "react-redux";

export default function NavBar({socket}) {
  const Onboarding = useOnboarding();
  const [logoutModal, setLogoutModal] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [isOnline, setIsOnline] = useState();
  const [isHighPriority, setIsHighPriority] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const profile = useSelector(
    (state) => state.rootReducer.uvionSlice.profile_info
  );
  const sellActive = useSelector(
    (state) => state.rootReducer.uvionSlice.ordersActiveCount
  );
  const buyActive = useSelector(
    (state) => state.rootReducer.uvionSlice.ordersBuyCount
  );
  const appealActive = useSelector(
    (state) => state.rootReducer.uvionSlice.ordersAppealCount
  );

  useEffect(() => {
    if (profile === null && localStorage.getItem('uvion_role') === 'trader') {
      Onboarding.get()
    }
    if (profile === null && localStorage.getItem('uvion_role') === 'agent') {
      Onboarding.getAgent()
    }
    setIsOnline(profile?.type_active)
    setIsHighPriority(profile?.is_highest_priority_buy)
  }, [profile])

  const closeLogoutModal = () => {
    setLogoutModal(false)
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  return (
    <div className="navbar__container">
      <div onClick={toggleMobileNav} className="navbar__tablet">
        UVION
        <span>
            <div className="navbar__tablet-menu"></div>
            <div className="navbar__tablet-menu"></div>
            <div className="navbar__tablet-menu"></div>
          </span>
      </div>
      <div className={`navbar ${isMobileNavOpen ? "mobile-open" : ""}`}>
        <div className="navbar__mobile-toggle" onClick={toggleMobileNav}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.6389 14.3962H17.5906C16.1042 14.3953 14.8993 13.1914 14.8984 11.7049C14.8984 10.2185 16.1042 9.01458 17.5906 9.01367H21.6389"
              stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.0485 11.6432H17.7369" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z"
                  stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.03564 7.53772H12.4346" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
          </svg>
        </div>
        {logoutModal && (
          <Popup title='Вы уверены что хотите выйти из аккаунта?' closePopup={closeLogoutModal}>
            <div className="orders__popup-wrapper">
              <p className="orders__popup-text">Вы потеряете доступ к личному кабинету</p>
              <div className="orders__popup-btns">
                <div className="orders__popup-btn">
                  <Button
                    text='Остаться'
                    type='accept'
                    bottomPadding={12}
                    topPadding={12}
                    onClick={closeLogoutModal}
                  />
                </div>
                <div className="orders__popup-btn">
                  <Button
                    text='Все равно выйти'
                    bottomPadding={12}
                    topPadding={12}
                    onClick={() => {
                      Onboarding.logoutHandle({})
                      socket.current.close()
                    }}
                  />
                </div>
              </div>
            </div>
          </Popup>
        )}
        <div className="navbar__top">
          <Link className='navbar__logo' to='/' onClick={closeMobileNav}>
            <img className="navbar__logo-img" src="/assets/images/logo.svg" alt="uvion"/>
          </Link>
          <div className="navbar__links">
            <span className='navbar__subtitle'>Информация</span>
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/profile"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                          fill="#525252"/>
                  </svg>
                </div>
                <p className="navbar__link-text">Профиль</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <NavLink
                to="/"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Iconly/Bold/Wallet">
                      <g id="Wallet">
                        <path id="Wallet_2" fillRule="evenodd" clipRule="evenodd"
                              d="M17.7689 8.3818H22C22 4.98459 19.9644 3 16.5156 3H7.48444C4.03556 3 2 4.98459 2 8.33847V15.6615C2 19.0154 4.03556 21 7.48444 21H16.5156C19.9644 21 22 19.0154 22 15.6615V15.3495H17.7689C15.8052 15.3495 14.2133 13.7975 14.2133 11.883C14.2133 9.96849 15.8052 8.41647 17.7689 8.41647V8.3818ZM17.7689 9.87241H21.2533C21.6657 9.87241 22 10.1983 22 10.6004V13.131C21.9952 13.5311 21.6637 13.8543 21.2533 13.8589H17.8489C16.8548 13.872 15.9855 13.2084 15.76 12.2643C15.6471 11.6783 15.8056 11.0736 16.1931 10.6122C16.5805 10.1509 17.1573 9.88007 17.7689 9.87241ZM17.92 12.533H18.2489C18.6711 12.533 19.0133 12.1993 19.0133 11.7877C19.0133 11.3761 18.6711 11.0424 18.2489 11.0424H17.92C17.7181 11.0401 17.5236 11.1166 17.38 11.255C17.2364 11.3934 17.1555 11.5821 17.1556 11.779C17.1555 12.1921 17.4964 12.5282 17.92 12.533ZM6.73778 8.3818H12.3822C12.8044 8.3818 13.1467 8.04812 13.1467 7.63649C13.1467 7.22487 12.8044 6.89119 12.3822 6.89119H6.73778C6.31903 6.89116 5.9782 7.2196 5.97333 7.62783C5.97331 8.04087 6.31415 8.37705 6.73778 8.3818Z"
                              fill="#525252"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <p className="navbar__link-text">Валюты</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <NavLink
                to="/teams"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                          fill="#525252"/>
                  </svg>
                </div>
                <p className="navbar__link-text">Команды</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <NavLink
                to="/kpi"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Iconly/Bold/Chart">
                      <g id="Chart">
                        <path id="Chart_2" fillRule="evenodd" clipRule="evenodd"
                              d="M7.33049 2.00049H16.6695C20.0705 2.00049 21.9905 3.92949 22.0005 7.33049V16.6705C22.0005 20.0705 20.0705 22.0005 16.6695 22.0005H7.33049C3.92949 22.0005 2.00049 20.0705 2.00049 16.6705V7.33049C2.00049 3.92949 3.92949 2.00049 7.33049 2.00049ZM12.0495 17.8605C12.4805 17.8605 12.8395 17.5405 12.8795 17.1105V6.92049C12.9195 6.61049 12.7705 6.29949 12.5005 6.13049C12.2195 5.96049 11.8795 5.96049 11.6105 6.13049C11.3395 6.29949 11.1905 6.61049 11.2195 6.92049V17.1105C11.2705 17.5405 11.6295 17.8605 12.0495 17.8605ZM16.6505 17.8605C17.0705 17.8605 17.4295 17.5405 17.4805 17.1105V13.8305C17.5095 13.5095 17.3605 13.2105 17.0895 13.0405C16.8205 12.8705 16.4805 12.8705 16.2005 13.0405C15.9295 13.2105 15.7805 13.5095 15.8205 13.8305V17.1105C15.8605 17.5405 16.2195 17.8605 16.6505 17.8605ZM8.21949 17.1105C8.17949 17.5405 7.82049 17.8605 7.38949 17.8605C6.95949 17.8605 6.59949 17.5405 6.56049 17.1105V10.2005C6.53049 9.88949 6.67949 9.58049 6.95049 9.41049C7.21949 9.24049 7.56049 9.24049 7.83049 9.41049C8.09949 9.58049 8.25049 9.88949 8.21949 10.2005V17.1105Z"
                              fill="#525252"/>
                      </g>
                    </g>
                  </svg>

                </div>
                <p className="navbar__link-text">KPI</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <NavLink
                to="/statistics"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Iconly/Bold/Activity">
                      <g id="Activity">
                        <path id="Activity_2" fillRule="evenodd" clipRule="evenodd"
                              d="M17.1799 4.41C17.1799 3.08 18.2599 2 19.5899 2C20.9199 2 21.9999 3.08 21.9999 4.41C21.9999 5.74 20.9199 6.82 19.5899 6.82C18.2599 6.82 17.1799 5.74 17.1799 4.41ZM13.3298 14.7593L16.2198 11.0303L16.1798 11.0503C16.3398 10.8303 16.3698 10.5503 16.2598 10.3003C16.1508 10.0503 15.9098 9.8803 15.6508 9.8603C15.3798 9.8303 15.1108 9.9503 14.9498 10.1703L12.5308 13.3003L9.75976 11.1203C9.58976 10.9903 9.38976 10.9393 9.18976 10.9603C8.99076 10.9903 8.81076 11.0993 8.68976 11.2593L5.73076 15.1103L5.66976 15.2003C5.49976 15.5193 5.57976 15.9293 5.87976 16.1503C6.01976 16.2403 6.16976 16.3003 6.33976 16.3003C6.57076 16.3103 6.78976 16.1893 6.92976 16.0003L9.43976 12.7693L12.2898 14.9103L12.3798 14.9693C12.6998 15.1393 13.0998 15.0603 13.3298 14.7593ZM15.4498 3.7803C15.4098 4.0303 15.3898 4.2803 15.3898 4.5303C15.3898 6.7803 17.2098 8.5993 19.4498 8.5993C19.6998 8.5993 19.9398 8.5703 20.1898 8.5303V16.5993C20.1898 19.9903 18.1898 22.0003 14.7898 22.0003H7.40076C3.99976 22.0003 1.99976 19.9903 1.99976 16.5993V9.2003C1.99976 5.8003 3.99976 3.7803 7.40076 3.7803H15.4498Z"
                              fill="#525252"/>
                      </g>
                    </g>
                  </svg>

                </div>
                <p className="navbar__link-text">Статистика</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/wallet"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo_stroke'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.6389 14.3962H17.5906C16.1042 14.3953 14.8993 13.1914 14.8984 11.7049C14.8984 10.2185 16.1042 9.01458 17.5906 9.01367H21.6389"
                      stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18.0485 11.6432H17.7369" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z"
                          stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.03564 7.53772H12.4346" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                  </svg>

                </div>
                <p className="navbar__link-text">Кошелек</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <span className='navbar__subtitle'>Торговля</span>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo_stroke'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8125 12.0225H3.77148" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M12.8848 9.10645L15.8128 12.0224L12.8848 14.9384" stroke="#525252" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M8.50439 7.38946V6.45646C8.50439 4.42146 10.1534 2.77246 12.1894 2.77246H17.0734C19.1034 2.77246 20.7484 4.41746 20.7484 6.44746V17.5875C20.7484 19.6225 19.0984 21.2725 17.0634 21.2725H12.1784C10.1494 21.2725 8.50439 19.6265 8.50439 17.5975V16.6555"
                      stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <div className="navbar__link-wrapper">
                  <p className="navbar__link-text">Получение</p>
                  {sellActive > 0 && (
                    <div className="navbar__link-count">{sellActive > 99 ? '99+' : sellActive}</div>
                  )}
                </div>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/buy"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo_stroke'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.016 7.38948V6.45648C15.016 4.42148 13.366 2.77148 11.331 2.77148H6.45597C4.42197 2.77148 2.77197 4.42148 2.77197 6.45648V17.5865C2.77197 19.6215 4.42197 21.2715 6.45597 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545"
                      stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M21.8094 12.0215H9.76843" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M18.8812 9.10645L21.8092 12.0214L18.8812 14.9374" stroke="#525252" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>

                </div>

                <div className="navbar__link-wrapper">
                  <p className="navbar__link-text">Выплаты</p>
                  {buyActive > 0 && (
                    <div className="navbar__link-count">{buyActive > 99 ? '99+' : buyActive}</div>
                  )}
                </div>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/orders-appeal"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo_stroke'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 13.0391L8.00022 5.72903C8.04922 3.65003 9.75425 1.99203 11.8343 2.00003L16.935 2.00008L20.5002 6.03906L20.5 14.0274C20.515 16.1234 18.865 17.8534 16.7709 17.9384L11.8343 17.9384C11.8033 17.9394 11.7733 17.9384 11.7423 17.9384C11.3481 17.9296 10.9691 17.8615 10.6137 17.7429"
                      stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16.5 2.575V4C16.5 5.575 17.3232 6.575 19.068 6.575H20.3232" stroke="#525252"
                          strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.5 8H11.5" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M13 5.5H12.25H11.5" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                      d="M8 9.02734L5.72223 10.9797C4.94647 11.6447 4.5 12.6154 4.5 13.6371V22.5391M4.5 22.5391H3.51953C3.50874 22.5391 3.5 22.5303 3.5 22.5195V22.5195C3.5 22.5087 3.50874 22.5 3.51953 22.5H12.4805C12.4913 22.5 12.5 22.5087 12.5 22.5195V22.5195C12.5 22.5303 12.4913 22.5391 12.4805 22.5391H11.5M4.5 22.5391H11.5M11.5 22.5391V20.5L12.6708 19.9146C13.179 19.6605 13.5 19.1411 13.5 18.5729V17.9883"
                      stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M7.5 13.5L11.0498 10.3939C11.2843 10.1887 11.6474 10.2457 11.8078 10.513L12.2903 11.3171C12.7175 12.0292 12.6603 12.9313 12.1464 13.5837L11 15.0391V16.5391C11 17.6436 10.1046 18.5391 9 18.5391V18.5391"
                      stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>

                <div className="navbar__link-wrapper">
                  <p className="navbar__link-text">Апелляции</p>
                  {appealActive > 0 && (
                    <div className="navbar__link-count">{appealActive > 99 ? '99+' : appealActive}</div>
                  )}
                </div>

              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/ads"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo_stroke'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M16.3341 2.75H7.66512C4.64412 2.75 2.75012 4.889 2.75012 7.916V16.084C2.75012 19.111 4.63512 21.25 7.66512 21.25H16.3331C19.3641 21.25 21.2501 19.111 21.2501 16.084V7.916C21.2501 4.889 19.3641 2.75 16.3341 2.75Z"
                          stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.9948 16V12" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M11.9899 8.2041H11.9999" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                  </svg>
                </div>
                <p className="navbar__link-text">Объявления</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <span className='navbar__subtitle'>Прочее</span>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/automatic"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo_stroke'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M17.2779 13.8887C17.9527 13.8887 18.519 14.4455 18.4158 15.1118C17.8106 19.0318 14.4548 21.9423 10.4074 21.9423C5.92952 21.9423 2.30005 18.3129 2.30005 13.836C2.30005 10.1476 5.10215 6.71181 8.25689 5.93497C8.93479 5.7676 9.62952 6.24445 9.62952 6.94234C9.62952 11.6708 9.78847 12.8939 10.6864 13.5592C11.5843 14.2244 12.64 13.8887 17.2779 13.8887Z"
                          stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M21.6926 9.95121C21.7463 6.91331 18.0147 2.01647 13.4674 2.10068C13.1137 2.107 12.8305 2.40173 12.8147 2.75437C12.7 5.25226 12.8547 8.4891 12.9411 9.95647C12.9674 10.4133 13.3263 10.7723 13.7821 10.7986C15.2905 10.8849 18.6453 11.0028 21.1074 10.6302C21.4421 10.5796 21.6874 10.2891 21.6926 9.95121Z"
                          stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <p className="navbar__link-text">Автоматика</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <NavLink
                to="/orders-history"
                className='navbar__link'
                onClick={closeMobileNav}
              >
                <div className='navbar__link-logo_stroke'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.09265 9.40445H20.9166" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M16.4421 13.3097H16.4514" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M12.0046 13.3097H12.0139" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M7.55793 13.3097H7.5672" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M16.4421 17.1964H16.4514" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M12.0046 17.1964H12.0139" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M7.55793 17.1964H7.5672" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M16.0438 2V5.29078" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M7.96552 2V5.29078" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                          stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>

                </div>
                <p className="navbar__link-text">История заявок</p>
              </NavLink>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
                <NavLink
                    to="/sms-history"
                    className='navbar__link'
                    onClick={closeMobileNav}
                >
                  <div className='navbar__link-logo_stroke'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.09265 9.40445H20.9166" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M16.4421 13.3097H16.4514" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M12.0046 13.3097H12.0139" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M7.55793 13.3097H7.5672" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M16.4421 17.1964H16.4514" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M12.0046 17.1964H12.0139" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M7.55793 17.1964H7.5672" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M16.0438 2V5.29078" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M7.96552 2V5.29078" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                            stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                  </div>
                  <p className="navbar__link-text">История смс</p>
                </NavLink>
            )}

            {/*<NavLink*/}
            {/*  to="/faq"*/}
            {/*  className='navbar__link'*/}
            {/*>*/}
            {/*  <div className='navbar__link-logo_stroke'>*/}
            {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*      <path fillRule="evenodd" clipRule="evenodd"*/}
            {/*            d="M8.26953 14.9524L9.86221 9.86319L14.9515 8.27051L13.3588 13.3598L8.26953 14.9524Z"*/}
            {/*            stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
            {/*      <circle cx="11.611" cy="11.611" r="9.61098" stroke="#525252" strokeWidth="1.5" strokeLinecap="round"*/}
            {/*              strokeLinejoin="round"/>*/}
            {/*    </svg>*/}

            {/*  </div>*/}
            {/*  <p className="navbar__link-text">FAQ</p>*/}
            {/*</NavLink>*/}
          </div>
        </div>
        <div className="navbar__bottom">
          {/*<div className="navbar__profile">*/}
          {/*  <div className="navbar__profile-left">*/}
          {/*    <img src="/assets/images/user-example.svg" alt="user placeholder"/>*/}
          {/*  </div>*/}
          {/*  <div className="navbar__profile-right">*/}
          {/*    <p className='navbar__profile-title'>Login Name <span>9:30PM</span></p>*/}
          {/*    <p className='navbar__profile-subtitle'>Name team</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="navbar__auth">*/}
          {/*  <div className={`navbar__auth-popup ${authOpen ? "navbar__auth-popup-active" : ''}`}>*/}
          {/*    <div className="navbar__auth-popup-top">*/}
          {/*      <p>Аутентификация</p>*/}
          {/*      <span onClick={() => {*/}
          {/*        setAuthOpen(false)*/}
          {/*      }}>*/}
          {/*        <img src="/assets/icons/close.svg" alt="close"/>*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*    <p className="navbar__auth-popup-text">Ваш 2FA ключ</p>*/}
          {/*    <div className="navbar__auth-popup-qr">*/}
          {/*      <img src={profile?.crypto_wallet?.qr_code_path} alt="qr"/>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <Button*/}
          {/*    text='Аутентификация'*/}
          {/*    bottomPadding={12}*/}
          {/*    topPadding={12}*/}
          {/*    onClick={() => {*/}
          {/*      setAuthOpen(true)*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          {localStorage.getItem('uvion_role') === 'trader' && (
            <div className={`navbar__online ${isOnline === 'paused' ? 'navbar__online-active' : ''}`}>
              <div className="navbar__online-wrapper">
                <p className={`navbar__online-text ${isOnline === 'paused' ? 'navbar__online-text-active' : ''}`}>Спящий
                  режим</p>
                <div onClick={() => {
                  setIsOnline(profile?.type_active === 'active' ? 'paused' : 'active')
                  Onboarding.isActiveHandle({type_active: profile?.type_active === 'active' ? 'paused' : 'active'})
                }} className={`navbar__online-toggle ${isOnline === 'paused' ? 'navbar__online-toggle-active' : ''}`}>
                  <div
                    className={`navbar__online-toggle-circle ${isOnline === 'paused' ? 'navbar__online-toggle-circle-active' : ''}`}></div>
                </div>
              </div>
            </div>
          )}
          <div className={`navbar__online ${isHighPriority === true ? 'navbar__online-active' : ''}`}>
            <div className="navbar__online-wrapper">
              <p className={`navbar__online-text ${isHighPriority === true ? 'navbar__online-text-active' : ''}`}>Повышенный залив</p>
              <div onClick={() => {
                setIsHighPriority(profile?.is_highest_priority_buy !== true)
                Onboarding.isHighPriority({is_highest_priority_buy: profile?.is_highest_priority_buy !== true})
              }} className={`navbar__online-toggle ${isHighPriority === true ? 'navbar__online-toggle-active' : ''}`}>
                <div
                    className={`navbar__online-toggle-circle ${isHighPriority === true ? 'navbar__online-toggle-circle-active' : ''}`}></div>
              </div>
            </div>
          </div>
          <div className="navbar__logout">
            <Button
              text='Выйти'
              type='remove'
              bottomPadding={8}
              topPadding={8}
              onClick={() => {
                setLogoutModal(true)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
