import React from 'react'

import './index.scss'

export default function Popup({title, children, closePopup, amount, size}) {

  return (
    <div className="popup">
      <div className={`popup__content ${size}`}>
        <div className="popup__content-head">
          <div className='popup__title-inner'>
            <p className="popup__title">{title}</p>
            {amount && <p className="popup__title-amount">{amount}</p>}
          </div>
          <div onClick={closePopup} className="popup__close">
            <img src='/assets/icons/close.svg' alt="close"/>
          </div>
        </div>
        {children}
      </div>
      <div onClick={closePopup} className="popup__bg"></div>
    </div>
  )
}
