import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import useCurrency from "../../Global/Api/currency.api";

import './index.scss'

export default function Currencies() {
  const apiCurrencies = useCurrency();
  const {currencies} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );

  useEffect(() => {
    apiCurrencies.get()
  }, [])

  const renderCurrencies = () => {
    return currencies?.map(currency => (
      <div key={currency?.id}
           className={`team`}>
        <div
          className={`team__wrapper team__wrapper_small`}>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{currency?.currency}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{currency?.code}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{currency?.usdt_price}</p>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div className='currencies'>
      <Helmet>
        <title>UVION | ВАЛЮТЫ</title>
      </Helmet>
      <div className={`orders__banner`}>
        <div className="orders__banner-top">
          <p className="orders__banner-name">Валюты</p>
          <div className='orders__banner-icon'>
            <img src="/assets/icons/appeal-banner-icon.svg" alt="orders"/>
          </div>
        </div>
        <div className="orders__banner-bottom">
          <p className="orders__banner-title">Актуальные направления</p>
          <p className="orders__banner-subtitle">Это раздел актуальных направлений на платформе</p>
        </div>
      </div>
      <div className="teams__table">
        <div className="teams__table-top teams__table-top_small">
          <div className="teams__table-head">
            <p>Валютв</p>
          </div>
          <div className="teams__table-head">
            <p>Код валюты</p>
          </div>
          <div className="teams__table-head">
            <p>Курс</p>
          </div>
        </div>
        <div className="teams__table-list">
          {renderCurrencies()}
        </div>
      </div>
    </div>
  )
}
