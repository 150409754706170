import React, {useEffect, useRef} from "react";

import './index.scss'
import {useSelector} from "react-redux";
import useKPI from "../../Global/Api/kpi.api";
import {Link} from "react-router-dom";
import moment from "moment";

export default function KPIStatistic() {
  const apiKPI = useKPI();
  const currentURL = window.location.href.match(/\/([^\/]+)$/)[1];
  const limitCount = 200;
  const lastCreatedAt = useRef(null);
  const {kpiStatisticData, kpiStatisticArray} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );

  useEffect(() => {
    apiKPI.getStatistic({
      limit: limitCount,
      trader_id: currentURL
    })
  }, [])

  const loadMore = () => {
    lastCreatedAt.current = kpiStatisticArray[kpiStatisticArray.length - 1]?.created_at
    apiKPI.getStatistic({
      limit: limitCount,
      trader_id: currentURL,
      last_created_at: lastCreatedAt.current
    })
  }

  const renderKPIStatisticArray = () => {
    return kpiStatisticArray?.map(team => (
      <div key={team?.id}
           className={`team`}>
        <div
          className={`team__wrapper team__wrapper_large`}>
          <div className="team__block">
            <p className="team__block-text">
              {moment.utc(team?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
            </p>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{kpiStatisticData?.agent_fee}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{team?.total_buy_orders} / {team?.total_sell_orders}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{team?.total_success_buy_orders} / {team?.total_success_sell_orders}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{team?.total_cancel_buy_orders} / {team?.total_cancel_sell_orders}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{team?.total_appeal_buy_orders} / {team?.total_appeal_sell_orders}</p>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div className='kpi'>
      <p className="profile__title">Итоговая статистика ({kpiStatisticData?.notice})</p>
      <div className="teams__table">
        <div className="teams__table-top teams__table-top_large">
          <div className="teams__table-head">
            <p>Название</p>
          </div>
          <div className="teams__table-head">
            <p>Ставка агента</p>
          </div>
          <div className="teams__table-head">
            <p>Заявки на ввод/вывод</p>
          </div>
          <div className="teams__table-head">
            <p>Заявки на ввод/вывод в успехе</p>
          </div>
          <div className="teams__table-head">
            <p>Заявки на ввод/вывод в отмене</p>
          </div>
          <div className="teams__table-head">
            <p>Апелляции на ввод/вывод</p>
          </div>
        </div>
        <div className="teams__table-list">
          <div
               className={`team`}>
            <div
              className={`team__wrapper team__wrapper_large`}>
              <div className="team__block">
                <p className="team__block-text">
                  {kpiStatisticData?.notice !== null ? kpiStatisticData?.notice : kpiStatisticData?.id}
                </p>
              </div>
              <div className="team__block">
                <div className="team__block-wrapper">
                  <p className="team__block-text">{kpiStatisticData?.agent_fee}</p>
                </div>
              </div>
              <div className="team__block">
                <div className="team__block-wrapper">
                  <p className="team__block-text">{kpiStatisticData?.final_total_statistics?.final_total_buy_orders} / {kpiStatisticData?.final_total_statistics?.final_total_sell_orders}</p>
                </div>
              </div>
              <div className="team__block">
                <div className="team__block-wrapper">
                  <p className="team__block-text">{kpiStatisticData?.final_total_statistics?.final_total_success_buy_orders} / {kpiStatisticData?.final_total_statistics?.final_total_success_sell_orders}</p>
                </div>
              </div>
              <div className="team__block">
                <div className="team__block-wrapper">
                  <p className="team__block-text">{kpiStatisticData?.final_total_statistics?.final_total_cancel_buy_orders} / {kpiStatisticData?.final_total_statistics?.final_total_cancel_sell_orders}</p>
                </div>
              </div>
              <div className="team__block">
                <div className="team__block-wrapper">
                  <p className="team__block-text">{kpiStatisticData?.final_total_statistics?.final_total_appeal_buy_orders} / {kpiStatisticData?.final_total_statistics?.final_total_appeal_sell_orders}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="profile__title mt-12">Статистика по дням ({kpiStatisticData?.notice})</p>
      <div className="teams__table">
        <div className="teams__table-top teams__table-top_large">
          <div className="teams__table-head">
            <p>Дата</p>
          </div>
          <div className="teams__table-head">
            <p>Ставка агента</p>
          </div>
          <div className="teams__table-head">
            <p>Заявки на ввод/вывод</p>
          </div>
          <div className="teams__table-head">
            <p>Заявки на ввод/вывод в успехе</p>
          </div>
          <div className="teams__table-head">
            <p>Заявки на ввод/вывод в отмене</p>
          </div>
          <div className="teams__table-head">
            <p>Апелляции на ввод/вывод</p>
          </div>
        </div>
        <div className="teams__table-list">
          {renderKPIStatisticArray()}
        </div>
        {kpiStatisticArray?.length >= limitCount && (
          <div className="loadMore">
            <p onClick={() => {
              loadMore()
            }}>
              Загрузить еще...
            </p>
            <span>
            <img src="/assets/icons/arrow-down.svg" alt=""/>
          </span>
          </div>
        )}
      </div>
    </div>
  )
}
