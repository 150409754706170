import {useDispatch} from "react-redux";
import {
  setCurrencies
} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";
import {useGetCurrenciesMutation} from "../../Store/Builder/uvion.api";

const useCurrency = () => {
  const dispatch = useDispatch();
  const [getCurrencies] = useGetCurrenciesMutation();

  const get = () => {
    getCurrencies()
      .unwrap()
      .then((res) => {
        dispatch(setCurrencies(res))
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    get,
  };
};

export default useCurrency;


