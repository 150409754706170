import {useDispatch} from "react-redux";
import showToast from "../Hooks/toast";
import {setIsHighPriority, setProfileInfo} from "../../Store/Slice/uvionSlice";
import {useNavigate} from "react-router-dom";
import {
  useAccountIsActiveMutation,
  useLazyGetProfileInfoQuery,
  useLazyLoginQuery,
  useLazyLogoutQuery,
  useLazyGetProfileAgentInfoQuery, useIsHighPriorityMutation
} from "../../Store/Builder/uvion.api";
import {jwtDecode} from "jwt-decode";
import {useEffect} from "react";

const useOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = useLazyLoginQuery();
  const [logout] = useLazyLogoutQuery();
  const [apiIsActive] = useAccountIsActiveMutation();
  const [highPriority] = useIsHighPriorityMutation();
  const [getProfile] = useLazyGetProfileInfoQuery();
  const [getProfileAgent] = useLazyGetProfileAgentInfoQuery();
  // let refreshTimeoutId = null
  //
  // const refreshToken = async (expiration) => {
  //   const timeoutTrigger = (expiration * 1000) - 10000;
  //   refreshTimeoutId = setTimeout(async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/refresh-logout/refresh-token`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({refresh_token: localStorage.getItem('uvion_refresh_token')}),
  //       });
  //
  //       if (response.ok) {
  //         const newTokens = await response.json();
  //         const decodedToken = jwtDecode(newTokens?.access_token);
  //         const currentTime = Math.floor(Date.now() / 1000);
  //         const timeUntilExpiration = decodedToken.exp - currentTime;
  //         setToken(newTokens?.access_token, timeUntilExpiration);
  //       }
  //     } catch (error) {
  //       navigate("/login");
  //     }
  //   }, timeoutTrigger);
  // };
  //
  // const abortRefreshToken = () => {
  //   if (refreshTimeoutId) {
  //     clearInterval(refreshTimeoutId)
  //   }
  // }
  //
  // const setToken = (token, tokenExpiration) => {
  //   localStorage.setItem("uvion_token_auth", token);
  //   refreshToken(tokenExpiration)
  // }

  let refreshTimeoutId = null;

  const refreshToken = async (expiration) => {
    const timeoutTrigger = (expiration * 1000) - 20000; // Обновляем токен за 10 секунд до истечения

    refreshTimeoutId = setTimeout(async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/refresh-logout/refresh-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh_token: localStorage.getItem('uvion_refresh_token') }),
        });

        if (response.ok) {
          const newTokens = await response.json();
          const decodedToken = jwtDecode(newTokens?.access_token);
          const currentTime = Math.floor(Date.now() / 1000);
          const timeUntilExpiration = decodedToken.exp - currentTime;
          setToken(newTokens?.access_token, timeUntilExpiration);
        } else {
          throw new Error('Failed to refresh token');
        }
      } catch (error) {
        console.error('Error during refresh token:', error);
        navigate("/login");
      }
    }, timeoutTrigger);
  };

  const abortRefreshToken = () => {
    if (refreshTimeoutId) {
      clearTimeout(refreshTimeoutId);
    }
  };

  const setToken = (token, tokenExpiration) => {
    localStorage.setItem("uvion_token_auth", token);
    localStorage.setItem("token_expiration", (Date.now() / 1000) + tokenExpiration); // Сохраняем время истечения токена
    refreshToken(tokenExpiration);
  };

  const restoreToken = () => {
    const token = localStorage.getItem("uvion_token_auth");
    const tokenExpiration = localStorage.getItem("token_expiration");
    if (token && tokenExpiration) {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiration = tokenExpiration - currentTime;
      if (timeUntilExpiration > 0) {
        refreshToken(timeUntilExpiration);
      } else {
        localStorage.removeItem('uvion_refresh_token')
        localStorage.removeItem('uvion_token_auth')
        localStorage.removeItem('token_expiration')
        navigate("/login"); // Если токен истек, перенаправляем на страницу входа
      }
    }
  };

  useEffect(() => {
    restoreToken(); // Восстанавливаем таймер при загрузке страницы
  }, []);

  const get = () => {
    getProfile()
      .unwrap()
      .then((res) => {
        dispatch(setProfileInfo(res))
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getAgent = () => {
    getProfileAgent()
      .unwrap()
      .then((res) => {
        dispatch(setProfileInfo(res))
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const loginHandle = (data) => {
    login(data)
      .unwrap()
      .then((res) => {
        const decodedToken = jwtDecode(res?.access_token)
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiration = decodedToken.exp - currentTime;

        localStorage.setItem('uvion_refresh_token', res?.refresh_token)
        localStorage.setItem('uvion_role', res?.role)
        setToken(res?.access_token, timeUntilExpiration)
        if(res?.role === 'trader') {
          get()
        }
        showToast("Вход выполнен успешно", "success");
        navigate('/')
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const logoutHandle = (data) => {
    logout(data)
      .unwrap()
      .then(() => {
        localStorage.removeItem('uvion_token_auth')
        localStorage.removeItem('uvion_refresh_token')
        localStorage.removeItem('token_expiration')
        localStorage.removeItem('uvion_role')
        abortRefreshToken()
        navigate("/login");
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const isActiveHandle = (body) => {
    apiIsActive(body)
      .unwrap()
      .then((res) => {
        if(res?.type_active === 'active') {
          dispatch(setProfileInfo(res))
          showToast("Ваш аккаунт в работе", "success");
        } else {
          showToast("Ваш аккаунт в спящем режиме", "success");
        }
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };
  const isHighPriority = (body) => {
    highPriority(body)
      .unwrap()
      .then((res) => {
        dispatch(setIsHighPriority(res))
        if (res?.is_highest_priority_buy === true) {
          showToast("Повышенный залив активен", "success");
        } else {
            showToast("Повышенный залив отключен", "success");
        }
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };


  return {
    loginHandle,
    logoutHandle,
    get,
    setToken,
    isActiveHandle,
    getAgent,
    isHighPriority
  };
};

export default useOnboarding;
