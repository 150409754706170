import React, {useState} from "react";

import Status from "../Status";
import useAds from "../../Global/Api/ads.api";

import './index.scss'

export default function Announcements({
                             id, editAd, deleteAd, item, closeModal, tab, openAddMinLimitModal, openSubMinLimitModal,
                             openAddMaxLimitModal,
                             openSubMaxLimitModal,
                                        openEditMaxOrderAmountModal
                           }) {
  const createAd = useAds();
  const [more, setMore] = useState(false);
  const [initialState, setInitialState] = useState({
    card_name: false,
    card_number: false,
  })

  const toggleFieldTemporarily = (fieldName) => {
    const updatedState = {...initialState};

    updatedState[fieldName] = true;

    setInitialState(updatedState);

    setTimeout(() => {
      setInitialState((prevState) => ({
        ...prevState,
        [fieldName]: false
      }));
    }, 5000);
  };

  const copyElement = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      // Создаем временный элемент для копирования
      let tempElement = document.createElement('span');
      tempElement.textContent = text;

      // Скрываем элемент и добавляем его в DOM
      tempElement.style.position = 'fixed';
      tempElement.style.opacity = '0';
      tempElement.style.pointerEvents = 'none';

      document.body.appendChild(tempElement);

      // Создаем Range и выделяем содержимое элемента
      let range = document.createRange();
      range.selectNodeContents(tempElement);

      // Используем Selection для выделения Range
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Копируем в буфер обмена
      try {
        document.execCommand('copy');
      } catch (err) {
      }

      // Удаляем временный элемент и очищаем выделение
      document.body.removeChild(tempElement);
      selection.removeAllRanges();
    }
  };

  return (
    <div key={id} className='announcements'>
      <div className="announcements__wrapper">
        <div className="announcements__block">
          <div onClick={() => {
            let body

            body = {
              'id': item?.id,
              'type': item?.type,
              'ad_category': item?.ad_category,
              'card_holder': item?.card_holder,
              'card_number': item?.card_number,
              'phone_number': item?.phone_number,
              'device_id': item?.device_id,
              'comment': item?.comment,
              "payment_method_id": item?.payment_method_id,
              "max_order_amount": item?.max_order_amount,
              "currency_id": item?.currency_id,
              "is_active": !item?.is_active,
              "is_interbank": item?.is_interbank,
              "requisite": item?.requisite,
              "requisite_type": (item?.card_number !== null && item?.phone_number === null && item?.requisite === null)
                ? 'card'
                : (item?.card_number === null && item?.phone_number !== null && item?.requisite === null)
                  ? 'phone'
                  : 'transfer',
            }

            createAd.editedAd(body, closeModal, tab, {action: "toggle"})
          }} className="announcements__block-play cur-p">
            <Status stage={!item?.is_active ? 'success' : 'cancel'}
                    text={!item?.is_active ? 'Запустить' : 'Остановить'}/>
          </div>
        </div>
        <div className="announcements__block">
          <Status
            text={item?.type === 'buy' ? 'Получение' + ` ${item?.ad_category?.toUpperCase()}` : 'Выплата' + ` ${item?.ad_category?.toUpperCase()}`}/>
        </div>
        <div className="announcements__block">
          <div className="announcements__block-wrapper">
            {/*Тут сделаны наша имя карты*/}
            <p onClick={() => {
              toggleFieldTemporarily('card_name')
              copyElement(item?.card_holder)
            }} className="announcements__block-text cur-p">
            <span className='announcements__copy'>
            {!initialState?.card_name ? (
              <img src="/assets/icons/copy.svg" alt="copy"/>
            ) : (
              <img src="/assets/icons/check-circle.svg" alt="check"/>
            )}
          </span>
              {item?.card_holder}
            </p>
            {/*Тут сделаны наш номер карты*/}
            <p onClick={() => {
              toggleFieldTemporarily('card_number')
              copyElement(item?.card_number !== null ? item?.card_number?.replaceAll(' ', '') : item?.phone_number !== null ? item?.phone_number : item?.requisite)
            }} className="announcements__block-text cur-p">
            <span className='announcements__copy'>
            {!initialState?.card_number ? (
              <img src="/assets/icons/copy.svg" alt="copy"/>
            ) : (
              <img src="/assets/icons/check-circle.svg" alt="check"/>
            )}
          </span>
              {item?.card_number !== null ? item?.card_number : item?.phone_number !== null ? item?.phone_number : item?.requisite}
            </p>
          </div>
        </div>
        <div className="announcements__block">
          {/*Тут сделаны название банка*/}
          <div className="announcements__block-bank ">
            <p>{item?.payment_method.bank_name}</p>
          </div>
        </div>
        <div className="announcements__block announcements__block_df">
          {/*Тут сделаны минимальный лимит*/}
          <p className="order__block-text">{Number(item?.min_amount).toFixed(2)?.toLocaleString('en-US')}</p>
        </div>
        <div className="announcements__block announcements__block_df">
          {/*Тут сделаны максимальный лимит*/}
          <div className="announcements__block-innerText">
            <p className="order__block-text">{Number(item?.max_amount).toFixed(2)?.toLocaleString('en-US')}</p>
            <p className="order__block-text">{Number(item?.max_order_amount).toFixed(2)?.toLocaleString('en-US')}</p>
          </div>
          <div className="announcements__block-btns-limit">
            <div onClick={() => {
              openAddMaxLimitModal(item)
            }} className="announcements__block-btn-limit">
              <img src="/assets/icons/plus-limit.svg" alt="plus"/>
            </div>
            <div onClick={() => {
              openSubMaxLimitModal(item)
            }} className="announcements__block-btn-limit">
              <img src="/assets/icons/minus-limit.svg" alt="minus"/>
            </div>
          </div>
        </div>
        <div className="announcements__block announcements__block_df">
          {/*Тут сделаны макс объем объявления*/}
          <p  className="order__block-text">{item?.max_order_amount_per_hour}</p>
          <div className="announcements__block-btn-limit" onClick={() => {
            openEditMaxOrderAmountModal(item)
          }}>
            <img src="/assets/icons/editAdAmount.svg" alt="edit"/>
          </div>
        </div>
        <div className="announcements__block">
          {/*Тут сделаны макс объем объявления*/}
          <p className="order__block-text">{item?.max_order_amount_per_hour}</p>
        </div>
        <div className="announcements__block">
          {/*Тут сделаны комментарий*/}
          <p className="order__block-text">{item?.comment}</p>
        </div>
        <div className="announcements__block">
          <div className={`announcements__block-btn ${more ? 'announcements__block-btn_active' : ''}`}>
            <div className={`announcements__block-more-btns ${more ? 'announcements__block-more-btns_active' : ''}`}>
              <div onClick={() => {
                editAd(item)
              }} className="announcements__block-more-btn">
                <img src="/assets/icons/edit.svg" alt="edit"/>
              </div>
              <div onClick={() => {
                deleteAd(item)
              }} className="announcements__block-more-btn">
                <img src="/assets/icons/delete.svg" alt="delete"/>
              </div>
            </div>
            <img className="announcements__block-btn-more" onClick={() => {
              setMore(!more)
            }} src={!more ? `/assets/icons/more.svg` : `/assets/icons/close.svg`} alt={!more ? "more" : 'close'}/>
          </div>
        </div>
      </div>
    </div>
  )
}
