import React, {useState} from "react";

import Status from "../Status";

import './index.scss'

export default function Device({item, openDeleteModal}) {
  const [more, setMore] = useState(false);


  return (
    <div className='device'>
      <div className="device__wrapper">
        <div className="device__block">
          <p className="device__block-text">
            <span>
              <img src="/assets/icons/phone.svg" alt="phone"/>
            </span>
            {item?.name} {item?.phone_number !== null ? `(${item?.phone_number})` : '(PUSH)'}
          </p>
        </div>
        <div className="device__block">
          <Status stage='success' text={'Подключен'}/>
        </div>
        <div className="device__block">
          <p className="device__block-text">
            <span>
              <img src="/assets/icons/android.svg" alt="android"/>
            </span>
            {item?.android_version}
          </p>
        </div>
        <div className="device__block">
          <p className="device__block-text">{item?.sim_slot === null ? 'PUSH' : `SIM SLOT:${item?.sim_slot}`}</p>
        </div>
        <div className="device__block">
          <div className={`announcements__block-btn ${more ? 'announcements__block-btn_active' : ''}`}>
            <div className={`announcements__block-more-btns ${more ? 'announcements__block-more-btns_active' : ''}`}>
              <div onClick={() => {
                openDeleteModal(item)
              }} className="announcements__block-more-btn">
                <img src="/assets/icons/delete.svg" alt="delete"/>
              </div>
              {/*<div onClick={() => {*/}
              {/*}} className="order__block-more-btn">*/}
              {/*  <img src="/assets/icons/info.svg" alt="info"/>*/}
              {/*</div>*/}
            </div>
            <img className="announcements__block-btn-more" onClick={() => {
              setMore(!more)
            }} src={!more ? `/assets/icons/more.svg` : `/assets/icons/close.svg`} alt={!more ? "more" : 'close'}/>
          </div>
        </div>
      </div>
    </div>
  )
}
