import React, {useEffect, useRef} from "react";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import useTeams from "../../Global/Api/getTeams.api";
import moment from "moment";

import './index.scss'

export default function Teams() {
  const apiTeams = useTeams();
  const limitCount = 200;
  const lastCreatedAt = useRef(null);
  const {teams} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );

  useEffect(() => {
    const formattedParams = formatParams();
    apiTeams.get(formattedParams)
  }, []);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    return params
  }

  const loadMore = () => {
    lastCreatedAt.current = teams[teams.length - 1]?.created_at
    const formattedParams = formatParams();
    apiTeams.get(formattedParams)
  }

  const renderTeamsData = () => {
    return teams?.map(team => (
      <div key={team?.id}
           className={`team`}>
        <div
          className={`team__wrapper`}>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{team?.notice}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p
                className="team__block-text">{team?.role === 'merchant_server' ? 'Мерчант' : team?.role === 'trader' ? 'Трейдер' : team?.role}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{team?.agent_fee}</p>
            </div>
          </div>
          <div className="team__block">
            <div className="team__block-wrapper">
              <p className="team__block-text">{moment.utc(team?.created_at).local().format("DD.MM.YY, HH:mm:ss")}</p>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div className='teams'>
      <Helmet>
        <title>UVION | КОМАНДЫ</title>
      </Helmet>
      {teams?.length === 0 && (
        <div className={`orders__banner ${teams?.length === 0 ? 'orders__banner_empty' : ''}`}>
          <div className="orders__banner-top">
            <p className="orders__banner-name">Команды</p>
            <div className='orders__banner-icon'>
              <img src="/assets/icons/appeal-banner-icon.svg" alt="orders"/>
            </div>
          </div>
          <div className="orders__banner-bottom">
            <p className="orders__banner-title">Нет подключенных команд</p>
            <p className="orders__banner-subtitle">На данный момент у вас нет ни одной подключенной команды</p>
          </div>
        </div>
      )}
      {teams?.length !== 0 && (
        <div className="teams__table">
          <div className="teams__table-top">
            <div className="teams__table-head">
              <p>Название</p>
            </div>
            <div className="teams__table-head">
              <p>Роль</p>
            </div>
            <div className="teams__table-head">
              <p>Ставка агента</p>
            </div>
            <div className="teams__table-head">
              <p>Время создания</p>
            </div>
          </div>
          <div className="teams__table-list">
            {renderTeamsData()}
          </div>
          {teams?.length >= limitCount && (
            <div className="loadMore">
              <p onClick={() => {
                loadMore()
              }}>
                Загрузить еще...
              </p>
              <span>
            <img src="/assets/icons/arrow-down.svg" alt=""/>
          </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
