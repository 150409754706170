import { useLazyGetBanksQuery} from "../../Store/Builder/uvion.api";
import showToast from "../Hooks/toast";
import {useDispatch} from "react-redux";
import {setBanks} from "../../Store/Slice/uvionSlice";

const useGetBanks = () => {
  const dispatch = useDispatch();
  const [getBanks] = useLazyGetBanksQuery();

  const get = () => {
    getBanks()
      .unwrap()
      .then((res) => {
        const newArray = res.map(item => ({
          label: item.bank_name,
          value: item.id,
          ...item,
        }));
        dispatch(setBanks(newArray))
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error account", "error");
      });
  };

  return {
    get,
  };
};

export default useGetBanks;
