import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {DateRangePicker} from "rsuite";
import {FaClock} from 'react-icons/fa';
import {CustomSelect} from "../../UI/Select";
import DatePicker from "rsuite/DatePicker";
import {useSelector} from "react-redux";
import Button from "../../UI/Button";
import moment from "moment";
import useGetOrders from "../../Global/Api/getOrders.api";

import './index.scss'
import 'rsuite/DatePicker/styles/index.css';

export default function Profile() {
  const getOrders = useGetOrders();
  const [typeSelect, setTypeSelect] = useState(null)
  const [typeFiler, setTypeFiler] = useState(null)
  const [statusSelect, setStatusSelect] = useState(null);
  const [requisiteSelect, setRequisiteSelect] = useState(null);
  const [timeValue, setTimeValue] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [avgSelect, setAvgSelect] = useState(null);
  const [dateFromAvg, setDateFromAvg] = useState(null);
  const [dateToAvg, setDateToAvg] = useState(null);
  const [timeValueAvg, setTimeValueAvg] = useState(null);
  const [cancelTypeSelect, setCancelTypeSelect] = useState(null);
  const [dateFromCancel, setDateFromCancel] = useState(null);
  const [dateToCancel, setDateToCancel] = useState(null);
  const [timeValueCancel, setTimeValueCancel] = useState(null);
  const [rejectSelect, setRejectSelect] = useState(null);
  const profileInfo = useSelector(
    (state) => state.rootReducer.uvionSlice.profile_info
  );
  const {ordersStatistics, allOrdersStatistics, cancelPercent, avgStatistics} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );

  const statusData = [
    {
      label: 'В обработке',
      value: 'pending'
    },
    {
      label: 'Завершен',
      value: 'success'
    },
    {
      label: 'Отменен',
      value: 'cancel'
    },
    {
      label: 'Апелляции',
      value: 'appeal'
    }
  ]
  const typeData = [
    {
      label: 'Получение',
      value: 'buy'
    },
    {
      label: 'Выплата',
      value: 'sell'
    }
  ]
  const filerData = [
    {
      label: 'По созданию',
      value: 'created'
    },
    {
      label: 'По обновлению',
      value: 'updated'
    },
    {
      label: 'По завершению',
      value: 'completed'
    },
  ]
  const requisiteData = [
    {
      label: 'По картам',
      value: 'card'
    },
    {
      label: 'По СБП',
      value: 'phone'
    },
  ]
  const avgData = [
    {
      label: 'Завершенные/Отмененные',
      value: 'normal'
    },
    {
      label: 'Апелляции',
      value: 'appeal'
    },
  ]
  const rejectData = [
    {
      label: 'Клиент указал неверный банк',
      value: 'Клиент указал неверный банк'
    },
    {
      label: 'Карта заблокирована',
      value: 'Карта заблокирована'
    },
    {
      label: 'Высокий риск',
      value: 'Высокий риск'
    },
  ]

  useEffect(() => {
    getOrders.getAllStatistics()
  }, [])

  const formatParamsStatistic = () => {
    const params = new URLSearchParams();
    params.append('currency_id', profileInfo?.currency?.id)
    if (typeSelect !== null) params.append('order_type', typeSelect.value);
    if (statusSelect !== null) params.append('order_stage', statusSelect.value);
    if (requisiteSelect !== null) {
      if (requisiteSelect.value === 'card') {
        params.append('phone_number', false);
        params.append('card_number', true);
      }
      if (requisiteSelect.value === 'phone') {
        params.append('phone_number', true);
        params.append('card_number', false);
      }
    }
    if (typeFiler !== null) params.append('time_filer', typeFiler.value);
    if (dateFrom !== null) {
      if (timeValue !== null) {
        let newArray
        newArray = timeValue?.map(extractTime)
        params.append('date_from', moment(dateFrom.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFrom.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateTo !== null) {
      if (timeValue !== null) {
        let newArray
        newArray = timeValue?.map(extractTime)
        params.append('date_to', moment(dateTo.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateTo.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const formatParamsCancelPercent = () => {
    const params = new URLSearchParams();
    if (cancelTypeSelect !== null) params.append('order_type', cancelTypeSelect.value);
    if (rejectSelect !== null) params.append('reason', rejectSelect.value);
    if (dateFromCancel !== null) {
      if (timeValueCancel !== null) {
        let newArray
        newArray = timeValueCancel?.map(extractTime)
        params.append('date_from', moment(dateFromCancel.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFromCancel.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateToCancel !== null) {
      if (timeValueCancel !== null) {
        let newArray
        newArray = timeValueCancel?.map(extractTime)
        params.append('date_to', moment(dateToCancel.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateToCancel.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  const formatParamsAvgStatistics = () => {
    const params = new URLSearchParams();
    if (avgSelect !== null) params.append('avg_type', avgSelect.value);
    if (dateFromAvg !== null) {
      if (timeValueAvg !== null) {
        let newArray
        newArray = timeValueAvg?.map(extractTime)
        params.append('date_from', moment(dateFromAvg.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      } else {
        params.append('date_from', moment(dateFromAvg.toString()).format('YYYY-MM-DD'))
      }
    }
    if (dateToAvg !== null) {
      if (timeValueAvg !== null) {
        let newArray
        newArray = timeValueAvg?.map(extractTime)
        params.append('date_to', moment(dateToAvg.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      } else {
        params.append('date_to', moment(dateToAvg.toString()).format('YYYY-MM-DD'))
      }
    }
    return params
  }

  function extractTime(datetimeStr) {
    return moment(datetimeStr).format('HH:mm:ss');
  }

  const sendStatistics = () => {
    const formattedParamsStatistic = formatParamsStatistic();
    getOrders.getStatistic(formattedParamsStatistic)
  }

  const sendCancelPercent = () => {
    const formattedParamsCancelPercent = formatParamsCancelPercent();
    getOrders.getCancelPercent(formattedParamsCancelPercent)
  }

  const sendAvgStatistics = () => {
    const formattedParamsAvgStatistics = formatParamsAvgStatistics();
    getOrders.getAvgStatistics(formattedParamsAvgStatistics)
  }


  return (
    <div className='profile'>
      <Helmet>
        <title>UVION | ПРОФИЛЬ</title>
      </Helmet>
      <p className="profile__title">Общий оборот по токену за все время</p>
      <div className="profile__box">
        <div className="profile__box-counters">
          <div className={`orders__top`}>
            <div className="orders__top-left">
              <div className="orders__top-block block__pattern">
                <div className='pattern-5'></div>
                <p className="orders__top-block-title">Оборот по получениям</p>
                <p
                  className="orders__top-block-value">{Number(allOrdersStatistics?.common_amount_buy ? allOrdersStatistics?.common_amount_buy : 0).toLocaleString('ru-RU', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3
                })} {profileInfo?.currency?.currency}</p>
                <p className="orders__top-block-subtitle">Сумма заявок на получение
                  в {profileInfo?.currency?.currency}</p>
              </div>
            </div>
            <div className="orders__top-right">
              <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
                <p className="orders__top-block-title">Количество заявок на получение</p>
                <p
                  className="orders__top-block-value">{allOrdersStatistics?.count_orders_buy ? allOrdersStatistics?.count_orders_buy : 0}</p>
                <p className="orders__top-block-subtitle">Количество заявок на получение за все время</p>
              </div>
            </div>
          </div>
          <div className={`orders__top`}>
            <div className="orders__top-left">
              <div className="orders__top-block block__pattern">
                <div className='pattern-6'></div>
                <p className="orders__top-block-title">Оборот по выплатам</p>
                <p
                  className="orders__top-block-value">{Number(allOrdersStatistics?.common_amount_sell ? allOrdersStatistics?.common_amount_sell : 0).toLocaleString('ru-RU', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3
                })} {profileInfo?.currency?.currency}</p>
                <p className="orders__top-block-subtitle">Сумма заявок на выплату
                  в {profileInfo?.currency?.currency}</p>
              </div>
            </div>
            <div className="orders__top-right">
              <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
                <p className="orders__top-block-title">Количество заявок на выплату</p>
                <p
                  className="orders__top-block-value">{allOrdersStatistics?.count_orders_sell ? allOrdersStatistics?.count_orders_sell : 0}</p>
                <p className="orders__top-block-subtitle">Количество заявок на выплату за все время</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="profile__title">Оборот по токену за период</p>
      <div className="profile__box">
        <div className="profile__box-filter">
          <div className="profile__box-filter-row">
            <div className="profile__select-container">
              <div className="profile__select">
                <CustomSelect
                    options={typeData}
                    selected={typeSelect}
                    placeholder='Тип'
                    isSearchable={false}
                    handleSelect={(e) => {
                      setTypeSelect(e ?? null)
                    }}
                    isClearable={true}
                />
              </div>
              <div className="profile__select">
                <CustomSelect
                    options={statusData}
                    selected={statusSelect}
                    placeholder='Статус'
                    isSearchable={false}
                    handleSelect={(e) => {
                      setStatusSelect(e ?? null)
                    }}
                    isClearable={true}
                />
              </div>
            </div>
            <div className="profile__select-container">
              <div className="profile__select">
                <CustomSelect
                    options={requisiteData}
                    selected={requisiteSelect}
                    placeholder='По картам/По СБП'
                    isSearchable={false}
                    handleSelect={(e) => {
                      setRequisiteSelect(e ?? null)
                    }}
                    isClearable={true}
                />
              </div>
              <div className="profile__select">
                <CustomSelect
                    options={filerData}
                    selected={typeFiler}
                    placeholder='Тип времени'
                    isSearchable={false}
                    handleSelect={(e) => {
                      setTypeFiler(e ?? null)
                    }}
                    isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="profile__box-filter-row">
            <div className="profile__select-container">
              <div className="profile__date">
                <DatePicker value={dateFrom} onChange={setDateFrom} locale={{ok: 'Применить'}}
                            className='filter-calendar' placeholder='От'
                            format="MM/dd/yyyy"/>
              </div>
              <div className="profile__date">
                <DatePicker value={dateTo} onChange={setDateTo} locale={{ok: 'Применить'}} className='filter-calendar'
                            placeholder='До'
                            format="MM/dd/yyyy"/>
              </div>
            </div>
            <div className="profile__select-container">
              <div className="profile__time">
                <DateRangePicker
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    value={timeValue}
                    onChange={setTimeValue}
                    locale={{ok: 'Применить'}}
                    placeholder={'Выберите время'}/>
              </div>
              <div className="profile__btn">
                <Button
                    text='Применить'
                    type='accept'
                    disabled={(dateFrom !== null || dateTo !== null) && timeValue === null ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendStatistics}
                />
              </div>
            </div>
          </div>
          <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
        </div>
        <div className={`orders__top`}>
          <div className="orders__top-left">
            <div className="orders__top-block block__pattern">
              <div className='pattern-1'></div>
              <p className="orders__top-block-title">Оборот за выбранный период</p>
              <p
                className="orders__top-block-value">{Number(ordersStatistics?.common_amount ? ordersStatistics?.common_amount : 0).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} {profileInfo?.currency?.currency}</p>
              <p className="orders__top-block-subtitle">Сумма заявок за выбранный период
                в {profileInfo?.currency?.currency}</p>
            </div>
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок</p>
              <p
                className="orders__top-block-value">{ordersStatistics?.count_orders ? ordersStatistics?.count_orders : 0}</p>
              <p className="orders__top-block-subtitle">Количество заявок за выбранный период</p>
            </div>
          </div>
        </div>
      </div>
      <div className="profile__inner">
        <div className="profile__wrapper">
          <p className="profile__title">Статистика среднего времени выполнения заявкок</p>
          <div className="profile__box profile__box_ml">
            <div className="profile__box-filter">
              <div className="profile__box-filter-row">
                <div className="profile__select">
                  <CustomSelect
                    options={avgData}
                    selected={avgSelect}
                    placeholder='Тип'
                    isSearchable={false}
                    handleSelect={(e) => {
                      setAvgSelect(e ?? null)
                    }}
                    isClearable={true}
                  />
                </div>
                <div className="profile__btn profile__btn_pc">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={(dateFromAvg !== null || dateToAvg !== null) && timeValueAvg === null ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendAvgStatistics}
                  />
                </div>
              </div>
              <div className="profile__box-filter-row">
                <div className="profile__date">
                  <DatePicker value={dateFromAvg} onChange={setDateFromAvg} locale={{ok: 'Применить'}}
                              className='filter-calendar' placeholder='От'
                              format="MM/dd/yyyy"/>
                </div>
                <div className="profile__date">
                  <DatePicker value={dateToAvg} onChange={setDateToAvg} locale={{ok: 'Применить'}}
                              className='filter-calendar'
                              placeholder='До'
                              format="MM/dd/yyyy"/>
                </div>
                <div className="profile__time">
                  <DateRangePicker
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    value={timeValueAvg}
                    onChange={setTimeValueAvg}
                    locale={{ok: 'Применить'}}
                    placeholder={'Выберите время'}/>
                </div>
                <div className="profile__btn profile__btn_mobile">
                  <Button
                    text='Применить'
                    type='accept'
                    disabled={(dateFromAvg !== null || dateToAvg !== null) && timeValueAvg === null ? true : false}
                    bottomPadding={8}
                    topPadding={8}
                    onClick={sendAvgStatistics}
                  />
                </div>
              </div>
              <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
            </div>
            <div className={`orders__top`}>
              <div className="orders__top-block orders__top-block_yellow orders__top-block_min">
                <div className='pattern-4'></div>
                <p className="orders__top-block-title">Среднее время выволнения заявок</p>
                <p
                  className="orders__top-block-value">{avgStatistics?.avg_time ? avgStatistics?.avg_time : 0}</p>
                <p className="orders__top-block-subtitle">Среднее время выволнения заявок за выбранный период</p>
              </div>
            </div>
          </div>
        </div>
        <div className="profile__wrapper">
          <p className="profile__title">Статистика отмен по токену в процентах</p>
          <div className="profile__box profile__box_ml">
            <div className="profile__box-filter">
              <div className="profile__box-filter-mobile">

                <div className="profile__box-filter-row">
                  <div className="profile__select">
                    <CustomSelect
                        options={typeData}
                        selected={cancelTypeSelect}
                        placeholder='Тип'
                        isSearchable={false}
                        handleSelect={(e) => {
                          setCancelTypeSelect(e ?? null)
                        }}
                        isClearable={true}
                    />
                  </div>
                  <div className="profile__select">
                    <CustomSelect
                        options={rejectData}
                        selected={rejectSelect}
                        placeholder='Выберите причину отмены'
                        handleSelect={(e) => {
                          setRejectSelect(e ?? null)
                        }}
                        isClearable={true}
                        isSearchable={false}
                    />
                  </div>
                  <div className="profile__btn profile__btn_pc">
                    <Button
                        text='Применить'
                        type='accept'
                        disabled={(dateFromCancel !== null || dateToCancel !== null) && timeValueCancel === null ? true : false}
                        bottomPadding={8}
                        topPadding={8}
                        onClick={sendCancelPercent}
                    />
                  </div>
                </div>
                <div className="profile__box-filter-row">
                  <div className="profile__time">
                    <DateRangePicker
                        format="HH:mm:ss"
                        caretAs={FaClock}
                        value={timeValueCancel}
                        onChange={setTimeValueCancel}
                        locale={{ok: 'Применить'}}
                        placeholder={'Выберите время'}/>
                  </div>
                  <div className="profile__date">
                    <DatePicker value={dateFromCancel} onChange={setDateFromCancel} locale={{ok: 'Применить'}}
                                className='filter-calendar' placeholder='От'
                                format="MM/dd/yyyy"
                    />
                  </div>
                  <div className="profile__date">
                    <DatePicker value={dateToCancel} onChange={setDateToCancel} locale={{ok: 'Применить'}}
                                className='filter-calendar'
                                placeholder='До'
                                format="MM/dd/yyyy"
                    />
                  </div>
                  <div className="profile__btn profile__btn_mobile">
                    <Button
                      text='Применить'
                      type='accept'
                      disabled={(dateFromCancel !== null || dateToCancel !== null) && timeValueCancel === null ? true : false}
                      bottomPadding={8}
                      topPadding={8}
                      onClick={sendCancelPercent}
                    />
                  </div>
                </div>
                <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>

              </div>
            </div>

            <div className="profile__block">
              <div className="orders__top-block">
                <p className="orders__top-block-title">Отмененные авто-отменой</p>
                <p
                  className="orders__top-block-value">{cancelPercent?.auto_cancel ? cancelPercent?.auto_cancel + '%' : 0}</p>
                <p className="orders__top-block-subtitle">Процент отмененных заявок авто-отменой</p>
              </div>
            </div>

            <div className="profile__block">
              <div className="orders__top-block">
                <p className="orders__top-block-title">Отмененные трейдером</p>
                <p
                  className="orders__top-block-value">{cancelPercent?.trader_cancel ? cancelPercent?.trader_cancel + '%' : 0}</p>
                <p className="orders__top-block-subtitle">Процент отмененных заявок трейдером</p>
              </div>
            </div>

            <div className="profile__block">
              <div className="orders__top-block orders__top-block_yellow">
                <p className="orders__top-block-title">Количество заявок</p>
                <p
                  className="orders__top-block-value">{cancelPercent?.all_orders_count ? cancelPercent?.all_orders_count : 0}</p>
                <p className="orders__top-block-subtitle">Количество отмененных заявок за выбранный период</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
