import {useDispatch} from "react-redux";
import showToast from "../Hooks/toast";
import {
  setDeleteAd,
  setEditAd,
  setEditAdLimit,
  setLoader,
  setSelectedBanksClear,
  setToggleAd
} from "../../Store/Slice/uvionSlice";
import {
  useLazyCreateAdQuery, useLazyDeleteAdQuery,
  useLazyEditAdQuery, useLazyMakeAdsQuery,
  useAddMinLimitAdMutation, useSubMinLimitAdMutation, useAddMaxLimitAdMutation, useSubMaxLimitAdMutation, useEditMaxLimitPerHourMutation
} from "../../Store/Builder/uvion.api";
import useGetAds from "./getAds.api";

const useAds = () => {
  const dispatch = useDispatch();
  const [createAd] = useLazyCreateAdQuery();
  const [editAd] = useLazyEditAdQuery();
  const [deleteAd] = useLazyDeleteAdQuery();
  const [makeAdsApi] = useLazyMakeAdsQuery();
  const [apiAddMinLimitAd] = useAddMinLimitAdMutation();
  const [apiSubMinLimitAd] = useSubMinLimitAdMutation();
  const [apiAddMaxLimitAd] = useAddMaxLimitAdMutation();
  const [apiSubMaxLimitAd] = useSubMaxLimitAdMutation();
  const [editMaxLimitPerHour] = useEditMaxLimitPerHourMutation();
  const getAds = useGetAds()

  const createdAd = (data, closeModal, tab) => {
    createAd(data)
      .unwrap()
      .then(() => {
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', 50);
          params.append('ad_type', tab ? 'sell' : 'buy')
          return params
        }
        getAds.get(formatParams())
        showToast("Объявление успешно создано", "success");
        closeModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const addMinLimitAd = (data, closeModal) => {
    apiAddMinLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAdLimit(res))
        showToast("Лимит успешно изменен", "success");
        closeModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const addMaxLimitAd = (data, closeModal) => {
    apiAddMaxLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAdLimit(res))
        showToast("Лимит успешно изменен", "success");
        closeModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const subMinLimitAd = (data, closeModal) => {
    apiSubMinLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAdLimit(res))
        showToast("Лимит успешно изменен", "success");
        closeModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const subMaxLimitAd = (data, closeModal) => {
    apiSubMaxLimitAd(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAdLimit(res))
        showToast("Лимит успешно изменен", "success");
        closeModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const editedAd = (data, closeModal, tab, infoAction) => {
    editAd(data)
      .unwrap()
      .then(() => {
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', 50);
          params.append('ad_type', tab ? 'sell' : 'buy')
          return params
        }
        getAds.get(formatParams())
        if(infoAction?.action === 'toggle') {
          dispatch(setToggleAd(data?.id))
        }
        if(infoAction?.action === 'edit') {
          dispatch(setEditAd(data))
          showToast("Объявление успешно редактировано", "success");
        }
        closeModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const editedMaxLimitPerHour = (data, closeEditMaxOrderAmountModal) => {
    editMaxLimitPerHour(data)
      .unwrap()
      .then((res) => {
        dispatch(setEditAdLimit(res))
        closeEditMaxOrderAmountModal()
        showToast("Макс. объем за час успешно изменен", "success");
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const deletedAd = (data, closeDeleteModal, tab) => {
    deleteAd(data)
      .unwrap()
      .then(() => {
        dispatch(setDeleteAd(data?.ad_id))
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', 50);
          params.append('ad_type', tab ? 'sell' : 'buy')
          return params
        }
        getAds.get(formatParams())
        showToast("Объявление успешно удалено", "success");
        closeDeleteModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const makeAds = (data, tab) => {
    makeAdsApi(data)
      .unwrap()
      .then(() => {
        dispatch(setSelectedBanksClear([]))
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', 50);
          params.append('ad_type', tab ? 'sell' : 'buy')
          return params
        }
        getAds.get(formatParams())
        showToast("Статус объявлений успешно изменен", "success");
      })
      .catch(() => {
      })
  }


  return {
    createdAd,
    editedAd,
    deletedAd,
    makeAds,
    addMinLimitAd,
    addMaxLimitAd,
    subMinLimitAd,
    subMaxLimitAd,
    editedMaxLimitPerHour
  };
};

export default useAds
