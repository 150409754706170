import { useDispatch } from "react-redux";
import {
  useGetWalletBillingsMutation,
  useGenerateQrCodeMutation,
  useCryptoWalletsMutation, useTransferBalanceMutation
} from "../../Store/Builder/uvion.api";
import {
  setCryptoWallets,
  setLoader,
  setProfileData,
  setWalletBillings,
  setWalletData
} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";

const useGetWalletBillings = () => {
  const dispatch = useDispatch();
  const [getWalletBillings] = useGetWalletBillingsMutation();
  const [postGenerateQrCode] = useGenerateQrCodeMutation();
  const [apiCryptoWallets] = useCryptoWalletsMutation();
  const [apiTransferBalance] = useTransferBalanceMutation();

  const get = (params) => {
    dispatch(setLoader(true))
    getWalletBillings(params)
      .unwrap()
      .then((res) => {
        dispatch(setWalletData(res))
        dispatch(setWalletBillings(res?.crypto_billings))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        if(err?.data?.code_error !== 'cryptoWalletNotFound') {
          showToast(err?.data?.message ?? "Error", "error");
        }
      });
  };

  const getCryptoWallets = () => {
    dispatch(setLoader(true))
    apiCryptoWallets()
      .unwrap()
      .then((res) => {
        dispatch(setCryptoWallets(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };


  const generateQrCode = () => {
    dispatch(setLoader(true))
    postGenerateQrCode()
      .unwrap()
      .then((res) => {
        dispatch(setProfileData(res?.qr_code_path))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const transferBalance = (body, closeModal) => {
    dispatch(setLoader(true))
    apiTransferBalance(body)
      .unwrap()
      .then((res) => {
        dispatch(setCryptoWallets(res))
        dispatch(setLoader(false))
        closeModal()
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    get,
    generateQrCode,
    getCryptoWallets,
    transferBalance
  };
};

export default useGetWalletBillings;


