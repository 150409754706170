import React from "react";
import "./index.scss";

export default function Button({
                                 text,
                                 type = "primary",
                                 disabled = false,
                                 topPadding = 0,
                                 bottomPadding = 0,
                                 rightPadding = 0,
                                 leftPadding = 0,
                                 onClick,
                                 style,
                                 imglink
                               }) {
  const className = `btn ${type}`;

  return (
    <button
      className={className}
      style={{
        ...style,
        paddingTop: topPadding,
        paddingBottom: bottomPadding,
        paddingRight: rightPadding,
        paddingLeft: leftPadding
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {imglink && (<span>
        <img src={imglink} alt=""/>
      </span>)}
      {text}
    </button>
  );
}
