import {useDispatch} from "react-redux";
import {
  useGetOrdersHistoryIdMutation,
  useGetOrdersHistoryMutation,
} from "../../Store/Builder/uvion.api";
import {
  setLoader,
  setOrdersHistory, setOrdersHistoryPagination,
} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";

const useGetOrdersHistory = () => {
  const dispatch = useDispatch();
  const [getOrdersHistory] = useGetOrdersHistoryMutation();
  const [getOrdersHistoryId] = useGetOrdersHistoryIdMutation();

  const get = (params, pagination) => {
    dispatch(setLoader(true));
    getOrdersHistory(params)
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        if (pagination === 'pagination') {
          dispatch(setOrdersHistoryPagination(res))
        } else {
          dispatch(setOrdersHistory(res));
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error account", "error");
      });
  };

  const getId = (body) => {
    dispatch(setLoader(true));
    getOrdersHistoryId(body)
      .unwrap()
      .then((res) => {
        dispatch(setOrdersHistory(res));
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error account", "error");
      });
  };

  return {
    get,
    getId
  };
};

export default useGetOrdersHistory;
