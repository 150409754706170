import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Popup from "../../Components/Popup";
import Button from "../../UI/Button";
import useInputHandler from "../../Global/Hooks/inputHandler";
import {setOrderDetails, setOrdersActiveCountNull, setOrdersStatisticsKeys} from "../../Store/Slice/uvionSlice";
import useGetOrders from "../../Global/Api/getOrders.api";
import useOrder from "../../Global/Api/orders.api";
import Order from "../../Components/Order";
import {Link, useLocation} from "react-router-dom";
import moment from "moment";
import Status from "../../Components/Status";

import './index.scss'
import {Helmet} from "react-helmet";

export default function OrdersSell() {
  const location = useLocation();
  const dispatch = useDispatch();
  const getOrderDetails = useOrder();
  const [acceptModal, setAcceptModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false);
  const amountValue = useInputHandler("");
  const getOrders = useGetOrders();
  const orderApi = useOrder();
  const searchId = useRef(null);
  const limitCount = 200;
  const [reloadInterval, setReloadInterval] = useState(null);
  const [initialState, setInitialState] = useState({
    id: false,
    card_name: false,
    card_number: false,
    user_card_number: false,
    amount_crypto: false,
    amount_fiat: false,
  });
  const ordersActive = useSelector(
    (state) => state.rootReducer.uvionSlice.ordersActive
  );
  const profileInfo = useSelector(
    (state) => state.rootReducer.uvionSlice.profile_info
  );
  const {orderDetails, orderBillings, messageData, ordersStatistics} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );


  useEffect(() => {
    dispatch(setOrdersActiveCountNull())
  }, [])

  useEffect(() => {
    if(profileInfo?.currency?.id) {
      const formattedParamsStatistic = formatParamsStatistic();
      getOrders.getStatistic(formattedParamsStatistic)
    }
  }, [profileInfo])

  useEffect(() => {
    const formattedParams = formatParams();
    getOrders.get(formattedParams);
  }, []);

  useEffect(() => {
    if (acceptModal || detailsModal) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = 'visible'
    }
  }, [acceptModal, detailsModal])

  const formatParamsStatistic = () => {
    const params = new URLSearchParams();
    params.append('order_type', 'buy');
    params.append('order_stage', 'pending');
    params.append('currency_id', profileInfo?.currency?.id);
    return params
  }

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    params.append('order_type', 'buy');
    if (searchId.current !== null) params.append('last_time_to_end', searchId.current);
    return params
  }

  const loadMore = () => {
    searchId.current = ordersActive[ordersActive.length - 1]?.time_to_end_trader
    const formattedParams = formatParams();
    getOrders.get(formattedParams)
  }

  const toggleFieldTemporarily = (fieldName) => {
    const updatedState = {...initialState};

    updatedState[fieldName] = true;

    setInitialState(updatedState);

    setTimeout(() => {
      setInitialState((prevState) => ({
        ...prevState,
        [fieldName]: false
      }));
    }, 5000);
  };

  const copyElement = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      // Создаем временный элемент для копирования
      let tempElement = document.createElement('span');
      tempElement.textContent = text;

      // Скрываем элемент и добавляем его в DOM
      tempElement.style.position = 'fixed';
      tempElement.style.opacity = '0';
      tempElement.style.pointerEvents = 'none';

      document.body.appendChild(tempElement);

      // Создаем Range и выделяем содержимое элемента
      let range = document.createRange();
      range.selectNodeContents(tempElement);

      // Используем Selection для выделения Range
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Копируем в буфер обмена
      try {
        document.execCommand('copy');
      } catch (err) {
      }

      // Удаляем временный элемент и очищаем выделение
      document.body.removeChild(tempElement);
      selection.removeAllRanges();
    }
  };

  const openAcceptModal = (item) => {
    setAcceptModal(true)
    setOrderInfo(item)
  }

  const openDetailsModal = (item) => {
    getOrderDetails.get({order_id: item?.id})
    getOrderDetails.getBillings({order_id: item?.id})
    setDetailsModal(true)
  }

  const closeAcceptModal = () => {
    setAcceptModal(false)
    setOrderInfo(null)
    amountValue.setChange('')
  }

  const closeDetailsModal = () => {
    dispatch(setOrderDetails(null))
    setDetailsModal(false)
  }

  const renderOrders = useMemo(() => {
    if (searchId !== '') {
      if (ordersActive?.length === 0) {
        return (
          <div className='orders__empty'>
          </div>
        );
      }
      return ordersActive?.map(item => (
        <Order key={item?.id} item={item} openAcceptModal={openAcceptModal} openDetailsModal={openDetailsModal}/>
      ));
    } else {
      if (ordersActive?.length === 0) {
        return (
          <div className='orders__empty'>
          </div>
        );
      }
      return ordersActive?.map(item => (
        <Order key={item?.id} item={item} openAcceptModal={openAcceptModal} openDetailsModal={openDetailsModal}/>
      ));
    }
  }, [searchId, ordersActive, openAcceptModal, openDetailsModal]);

  const acceptOrderSendData = () => {
    orderApi.acceptOrder({
      'order_id': orderInfo?.id,
    }, closeAcceptModal, {limitCount: limitCount, searchId: searchId})
    dispatch(setOrdersStatisticsKeys({
      count_orders: ordersStatistics.count_orders - 1,
      common_amount: ordersStatistics.common_amount - orderInfo.order_amount,
    }))
  }

  const formatCreditCardNumber = (creditCardNumber) => {
    if (!creditCardNumber) {
      return ''; // Если входная строка пуста или не определена, возвращаем пустую строку
    }

    // Удаляем все символы, кроме цифр
    const cleanedNumber = creditCardNumber.replace(/\D/g, '');

    // Добавляем пробел после каждых 4 символов
    const formattedNumber = cleanedNumber.replace(/(.{4})/g, '$1 ');

    // Возвращаем отформатированный номер карты
    return formattedNumber.trim(); // Удаляем лишние пробелы в начале и конце строки
  };

  const renderOrderBilling = () => {
    return orderBillings?.map(item => (
      <div key={item?.id} className="history__details-modal-billing">
        <div className="history__details-modal-billing-line">
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Сумма</div>
            <div className="history__details-modal-billing-text">{item?.order_amount}</div>
          </div>
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Отвественность</div>
            <div className="history__details-modal-billing-text">{item?.order_substage}</div>
          </div>
        </div>
        <div className="history__details-modal-billing-line">
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Статус</div>
            <Status stage={item?.order_stage} text={item?.order_stage === 'pending' ? 'В обработке' : item?.order_stage === 'success' ? 'Завершен' : item?.order_stage === 'cancel' ? 'Отменен' : 'Апелляция'}/>
          </div>
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Дата создания</div>
            <div className="history__details-modal-billing-text">{moment.utc(item?.created_at).local().format('DD.MM.YYYY')}</div>
            <div className="history__details-modal-billing-text">{moment.utc(item?.created_at).local().format('HH:mm:ss')}</div>
          </div>
        </div>
        {messageData?.message?.length > 0 && (
          <div className="history__details-modal-billing-line">
            <div className="history__details-modal-billing-comment">
              <div className="history__details-modal-billing-title">СМС</div>
              <div className="history__details-modal-billing-text">
                {messageData?.message}
              </div>
            </div>
          </div>
        )}
      </div>
    ))
  }

  return (
    <div className='orders'>
      <Helmet>
        <title>UVION | ПОЛУЧЕНИЕ</title>
      </Helmet>
      {acceptModal && (
        <Popup title='Подтвердить заявку' closePopup={closeAcceptModal}>
          <div className="orders__popup-wrapper">
            <p className="orders__popup-text">Вы уверены что хотите подтвердить заявку?</p>
            <div className="orders__popup-btns">
              <div className="orders__popup-btn">
                <Button
                  text='Подтвердить'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={acceptOrderSendData}
                />
              </div>
              <div className="orders__popup-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeAcceptModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {detailsModal && (
        <Popup size={'large'} closePopup={closeDetailsModal}>
          <div className="history__details-modal-wrapper">
            <div className="history__details-modal-left">
              <p className="history__details-modal-title">Информация</p>
              <p className="history__details-modal-subtitle">Общая информация о сделке</p>
              <div className="history__details-modal-info">
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Тип</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      <Status text={orderDetails?.type === 'buy' ? `Получение ${orderDetails?.order_category.toUpperCase()}` : `Выплата ${orderDetails?.order_category?.toUpperCase()}`}/>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">ID</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает ID*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('id')
                        copyElement(orderDetails?.id)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.id ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        ID:{orderDetails?.id}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Реквизиты</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает наше имя карты*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('card_name')
                        copyElement(orderDetails?.ad_data?.card_holder)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.card_name ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.ad_data?.card_holder ? orderDetails?.ad_data?.card_holder : '--- --- ---'}
                      </p>
                      {/*Тут отображает наш номер карты*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('card_number')
                        copyElement(orderDetails?.ad_data?.card_number !== null ? orderDetails?.ad_data?.card_number?.replaceAll(' ', '') : orderDetails?.ad_data?.phone_number !== null ? orderDetails?.ad_data?.phone_number : orderDetails?.ad_data?.requisite)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.card_number ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.ad_data?.card_number !== null ? formatCreditCardNumber(orderDetails?.ad_data?.card_number) : orderDetails?.ad_data?.phone_number !== null ? orderDetails?.ad_data?.phone_number : orderDetails?.ad_data?.requisite}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Банк</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      <div className="history__details-modal-line-bank">
                        {orderDetails?.payment_method?.bank_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Реквизиты клиента</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает номер карты клиента*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('user_card_number')
                        copyElement(orderDetails?.card_number !== null ? orderDetails?.card_number?.replaceAll(' ', '') : orderDetails?.phone_number !== null ? orderDetails?.phone_number : orderDetails?.requisite)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.user_card_number ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.card_number !== null ? formatCreditCardNumber(orderDetails?.card_number) : orderDetails?.phone_number !== null ? orderDetails?.phone_number : orderDetails?.requisite}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Получаем/Отправляем</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает фиат*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('amount_fiat')
                        copyElement(orderDetails?.order_amount)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.amount_fiat ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.order_amount}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Дата создания</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает дату*/}
                      <p className="history__details-modal-line-text">
                        {moment.utc(orderDetails?.created_at).local().format('DD.MM.YYYY')}
                      </p>
                      {/*Тут отображает время*/}
                      <p className="history__details-modal-line-text">
                        {moment.utc(orderDetails?.created_at).local().format('HH:mm:ss')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Статус</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      <Status
                        stage={orderDetails?.order_stage}
                        text={orderDetails?.order_stage === 'pending' ? 'В обработке' : orderDetails?.order_stage === 'success' ? 'Завершен' : orderDetails?.order_stage === 'cancel' ? 'Отменен' : 'Апелляция'}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="history__details-modal-center">
              {orderDetails?.receipt_url !== null && (
                <>
                  <p className="history__details-modal-title">Прикрепленные файлы</p>
                  <p className="history__details-modal-subtitle">Список всех прикрепленных файлов</p>
                  <div className="history__details-modal-files mb-16">
                    <div className="history__details-modal-file">
                      <div className="history__details-modal-file-wrapper">
                        <div className="history__details-modal-file-icon">
                          <img src="/assets/icons/file-placeholder.svg" alt="file"/>
                        </div>
                        <div className="history__details-modal-file-info">
                          <p className="history__details-modal-file-name">Выписка.pdf</p>
                          <Link target='_blank' to={orderDetails?.receipt_url}
                                className="history__details-modal-file-link">Открыть в новой вкладке</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {orderDetails?.admin_receipts !== null && (
                <>
                  <p className="history__details-modal-title">Файлы прикрепленные администратором</p>
                  <p className="history__details-modal-subtitle">Список всех прикрепленных файлов
                    администратором</p>
                  <div className="history__details-modal-files">
                    {orderDetails?.admin_receipts?.map((item, index) => (
                      <div key={index} className="history__details-modal-file">
                        <div className="history__details-modal-file-wrapper">
                          <div className="history__details-modal-file-icon">
                            <img src="/assets/icons/file-placeholder.svg" alt="file"/>
                          </div>
                          <div className="history__details-modal-file-info">
                            <p className="history__details-modal-file-name">Выписка {index + 1}.pdf</p>
                            <Link target='_blank' to={item?.receipt_id}
                                  className="history__details-modal-file-link">Открыть в новой вкладке</Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="history__details-modal-right">
              <p className="history__details-modal-title">Биллинги</p>
              <p className="history__details-modal-subtitle">Общая информация о сделке</p>
              <div className="history__details-modal-billings-list">
                {renderOrderBilling()}
              </div>
            </div>
          </div>
        </Popup>
      )}
      {ordersActive?.length > 0 && (
        <div className={`orders__top ${ordersActive?.length === 0 ? 'orders__top_mb' : ''}`}>
          <div className="orders__top-left">
            <div className="orders__top-block">
              <p className="orders__top-block-title">Сумма заявок</p>
              <p className="orders__top-block-value">{Number(ordersStatistics?.common_amount).toLocaleString('ru-RU', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              })} {profileInfo?.currency?.currency}</p>
              <p className="orders__top-block-subtitle">Сумма активных заявок в {profileInfo?.currency?.currency}</p>
            </div>
            {/*<div className="orders__top-block">*/}
            {/*  <p className="orders__top-block-title">Сумма в USDT</p>*/}
            {/*  <p className="orders__top-block-value">{Number(ordersStatistics?.common_amount_usdt).toLocaleString('ru-RU', {*/}
            {/*    minimumFractionDigits: 3,*/}
            {/*    maximumFractionDigits: 3*/}
            {/*  })} USDT</p>*/}
            {/*  <p className="orders__top-block-subtitle">Сумма активных заявок в USDT</p>*/}
            {/*</div>*/}
          </div>
          <div className="orders__top-right">
            <div className="orders__top-block orders__top-block_small orders__top-block_yellow">
              <p className="orders__top-block-title">Количество заявок</p>
              <p className="orders__top-block-value">{ordersStatistics?.count_orders}</p>
              <p className="orders__top-block-subtitle">Количество активных заявок на получение</p>
            </div>
          </div>
        </div>
      )}
      {ordersActive?.length === 0 && (
        <div className={`orders__banner ${ordersActive?.length === 0 ? 'orders__banner_empty' : ''}`}>
          <div className="orders__banner-top">
            <p className="orders__banner-name">Получение</p>
            <div className='orders__banner-icon'>
              <img src="/assets/icons/appeal-banner-icon.svg" alt="orders"/>
            </div>
          </div>
          <div className="orders__banner-bottom">
            <p className="orders__banner-title">Будьте бдительны</p>
            <p className="orders__banner-subtitle">Ответственно подходите к работе с получениями, любая ваша ошибка может привести к потере средств.</p>
          </div>
        </div>
      )}
      {ordersActive?.length > 0 && (
        <div className="orders__table">
          <div className="orders__table-top">
            <div className="orders__table-head">
              <p>Тип/ID</p>
            </div>
            <div className="orders__table-head">
              <p>Реквизиты</p>
            </div>
            <div className="orders__table-head">
              <p>Банк</p>
            </div>
            <div className="orders__table-head">
              <p>Получаем</p>
            </div>
            <div className="orders__table-head">
              <p>До отмены</p>
            </div>
            <div className="orders__table-head">
              <p>Дата создания</p>
            </div>
            <div className="orders__table-head">
              <p>Статус</p>
            </div>
            <div className="orders__table-head">
              <p>Действие</p>
            </div>
          </div>
          <div className="orders__table-list">
            {renderOrders}
          </div>
          {ordersActive?.length > limitCount && (
            <div className="loadMore">
              <p onClick={() => {loadMore()}}>
                Загрузить еще...
              </p>
              <span>
            <img src="/assets/icons/arrow-down.svg" alt=""/>
          </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
