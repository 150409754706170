import React from "react";

import './index.scss'

export default function Status({text, stage}) {
  return (
    <div className={`status ${stage === 'cancel' ? 'status_cancel' : stage === 'success' ? 'status_success' : ''}`}>
      <p className="status__text">{text}</p>
    </div>
  )
}
