import React from "react";

import './index.scss'

export default function WalletBillingItem({item}) {
  return (
    <div className='billing'>
      <div className="billing__wrapper">
        <div className="billing__block">
          <p className="billing__block-text">{item?.deposit ? item?.deposit : '--- --- ---'}</p>
        </div>
        <div className="billing__block">
          <p className="billing__block-text">{item?.balance ? item?.balance : '--- --- ---'}</p>
        </div>
        <div className="billing__block">
          <p className="billing__block-text">{item?.frozen_balance ? item?.frozen_balance : '--- --- ---'}</p>
        </div>
        <div className="billing__block">
          <p className="billing__block-text">{item?.currency_type ? item?.currency_type : '--- --- ---'}</p>
        </div>
        <div className="billing__block">
          <p className="billing__block-text">{item?.order_id ? item?.order_id : '--- --- ---'}</p>
        </div>
        <div className="billing__block">
          <p className="billing__block-text">{item?.operation_type ? item?.operation_type : '--- --- ---'}</p>
        </div>
        <div className="billing__block">
          <p className="billing__block-text">{item?.transaction_type ? item?.transaction_type : '--- --- ---'}</p>
        </div>
      </div>
    </div>
  )
}
