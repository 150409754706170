import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import Loader from "./UI/Loader";
import PrivateRoute from "./Components/PrivateRoute";
import Profile from "./Screens/Profile";
import Login from "./Screens/Login";
import NavBar from "./Components/NavBar";
import OrdersHistory from "./Screens/OrdersHistory";
import OrdersBuy from "./Screens/OrdersBuy";
import OrdersSell from "./Screens/OrdersSell";
import OrdersAppeal from "./Screens/OrdersAppeal";
import Ads from "./Screens/Ads";
import Wallet from "./Screens/Wallet";
import Automatic from "./Screens/Automatic";
import FAQ from "./Screens/FAQ";
import {
  setAllOrdersStatistics, setAvgStatistics, setCancelPercent,
  setNewOrderActive, setNewOrderAppeal, setNewOrderBuy, setOrdersActiveCount,
  setOrdersActiveEmpty, setOrdersAppealCount, setOrdersAppealEmpty, setOrdersBuyCount,
  setOrdersBuyEmpty,
  setOrdersHistoryEmpty, setOrdersStatistics, setOrdersStatisticsCount, setOrdersStatisticsFiat,
  setSellAdsEmpty
} from "./Store/Slice/uvionSlice";

import "./Global/app.scss";
import "react-toastify/dist/ReactToastify.css";
import Teams from "./Screens/Teams";
import KPI from "./Screens/KPI";
import KPIStatistic from "./Screens/KPIStatistic";
import Currencies from "./Screens/Currencies";
import Statistics from "./Screens/Statistics";
import SmsHistory from "./Screens/SmsHistory";


function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [reloadInterval, setReloadInterval] = useState(null);

  const socket = useRef(null);
  const connected = useRef(false);
  const {loader, ordersStatistics} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );

  useEffect(() => {
    if (location?.pathname !== '/orders-history') {
      dispatch(setOrdersHistoryEmpty([]))
    }
    if (location?.pathname !== '/ads') {
      dispatch(setSellAdsEmpty([]))
    }
    if (location?.pathname !== '/') {
      dispatch(setOrdersActiveEmpty([]))
    }
    if (location?.pathname !== '/buy') {
      dispatch(setOrdersBuyEmpty([]))
    }
    if (location?.pathname !== '/orders-appeal') {
      dispatch(setOrdersAppealEmpty([]))
    }
    if(location?.pathname !== '/profile') {
      dispatch(setOrdersStatistics(null))
      dispatch(setAllOrdersStatistics(null))
      dispatch(setCancelPercent(null))
      dispatch(setAvgStatistics(null))
    }
  }, [location])

  useEffect(() => {
    if (reloadInterval) {
      clearInterval(reloadInterval); // Остановка предыдущего интервала, если он существует
    }

    if (location.pathname === '/buy' || location.pathname === '/orders-appeal' || location.pathname === '/') {
      const newReloadInterval = setInterval(() => {
        // window.location.reload();
      }, 300000);

      setReloadInterval(newReloadInterval); // Сохранение идентификатора нового интервала
    }

    return () => {
      if (reloadInterval) {
        clearInterval(reloadInterval); // Остановка интервала при размонтировании компонента
      }
    };
  }, [location]);

  useEffect(() => {
    if(location?.pathname === '/login') {
      document.querySelector('body').style.padding = '0'
    } else {
      document.querySelector('body').style.padding = '24px'
    }
  }, [location])

  useEffect(() => {
    if (localStorage.getItem("uvion_token_auth")) {
      if (!connected.value) {
        const socketUrl = `${process.env.REACT_APP_SOCKET_URL}/ws?token=${localStorage.getItem("uvion_token_auth")}`;

        socket.current = new WebSocket(socketUrl);
        socket.current.onopen = () => {
        };

        // Обрабатываем входящие сообщения
        socket.current.onmessage = (event) => {
          if(JSON.parse(event?.data)?.buy_data?.length > 0) {
            dispatch(setNewOrderActive(JSON.parse(event?.data)))
            // dispatch(setOrdersStatisticsFiat(ordersStatistics?.common_amount + JSON.parse(event?.data)?.[0]?.order_amount))
          }
          // if(JSON.parse(event?.data)?.sell_data?.length > 0) {
          //   dispatch(setNewOrderBuy(JSON.parse(event?.data)))
          // }
          if(JSON.parse(event?.data)?.appeal_data?.length > 0) {
            dispatch(setNewOrderAppeal(JSON.parse(event?.data)))
          }
          if(JSON.parse(event?.data)?.buy_type !== 0) {
            dispatch(setOrdersActiveCount(JSON.parse(event?.data)?.buy_type))
            // dispatch(setOrdersStatisticsCount(ordersStatistics?.count_orders + JSON.parse(event?.data)?.buy_type))
          }
          if(JSON.parse(event?.data)?.sell_type !== 0) {
            dispatch(setOrdersBuyCount(JSON.parse(event?.data)?.sell_type))
            // dispatch(setOrdersStatisticsCount(ordersStatistics?.count_orders + JSON.parse(event?.data)?.buy_type))
          }
          if(JSON.parse(event?.data)?.appeal_type !== 0) {
            dispatch(setOrdersAppealCount(JSON.parse(event?.data)?.appeal_type))
          }
        };

        // Обрабатываем ошибки
        socket.current.onerror = (event) => {
          console.error('Произошла ошибка');
        };

        // Обрабатываем закрытие соединения
        socket.current.onclose = (event) => {
          console.log('Соединение закрыто');
        };
        connected.value = true;
      }
    }
  }, [connected, localStorage.getItem("uvion_token_auth")]);

  const navbarDisabled = () => {
    if (
      location?.pathname === "/login"
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {loader && <Loader/>}
      <ToastContainer position="top-right" autoClose={5000}/>
      <div
        className={
          localStorage.getItem("uvion_token_auth") &&
          navbarDisabled()
            ? `nav__wrapper`
            : ""
        }
      >
        {localStorage.getItem("uvion_token_auth") &&
        navbarDisabled() && <NavBar socket={socket}/>}
        <div
          className={
            navbarDisabled()
              ? `nav__wrapper-right ${
                !localStorage.getItem("uvion_token_auth")
                  ? "nav__wrapper-right_none"
                  : ""
              }`
              : ""
          }
        >
          <Routes>
            <Route path="/login" element={<Login/>}/>

            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/orders-history" element={<PrivateRoute/>}>
                <Route path="/orders-history" element={<OrdersHistory/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/profile" element={<PrivateRoute/>}>
                <Route path="/profile" element={<Profile/>}/>
              </Route>
            )}
              <Route path="/" element={<PrivateRoute/>}>
                <Route path="/" element={localStorage.getItem('uvion_role') === 'trader' ? <OrdersSell/> : <Currencies/>}/>
              </Route>
            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/buy" element={<PrivateRoute/>}>
                <Route path="/buy" element={<OrdersBuy/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/orders-appeal" element={<PrivateRoute/>}>
                <Route path="/orders-appeal" element={<OrdersAppeal/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/ads" element={<PrivateRoute/>}>
                <Route path="/ads" element={<Ads/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/wallet" element={<PrivateRoute/>}>
                <Route path="/wallet" element={<Wallet/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/sms-history" element={<PrivateRoute/>}>
                <Route path="/sms-history" element={<SmsHistory/>}/>
              </Route>
            )}
            {/*<Route path="/faq" element={<PrivateRoute/>}>*/}
            {/*  <Route path="/faq" element={<FAQ/>}/>*/}
            {/*</Route>*/}
            {localStorage.getItem('uvion_role') === 'trader' && (
              <Route path="/automatic" element={<PrivateRoute/>}>
                <Route path="/automatic" element={<Automatic/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <Route path="/teams" element={<PrivateRoute/>}>
                <Route path="/teams" element={<Teams/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <Route path="/kpi" element={<PrivateRoute/>}>
                <Route path="/kpi" element={<KPI/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <Route path="/statistic/:id" element={<PrivateRoute/>}>
                <Route path="/statistic/:id" element={<KPIStatistic/>}/>
              </Route>
            )}
            {localStorage.getItem('uvion_role') === 'agent' && (
              <Route path="/statistics" element={<PrivateRoute/>}>
                <Route path="/statistics" element={<Statistics/>}/>
              </Route>
            )}
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
