import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import useCurrency from "../../Global/Api/currency.api";

import './index.scss'
import {useDispatch, useSelector} from "react-redux";
import {CustomSelect} from "../../UI/Select";
import moment from "moment";
import useKPI from "../../Global/Api/kpi.api";
import {setKpiEmpty} from "../../Store/Slice/uvionSlice";
import {Link} from "react-router-dom";

export default function KPI() {
  const dispatch = useDispatch();
  const apiCurrencies = useCurrency();
  const apiKPI = useKPI();
  const [selectCurrency, setSelectCurrency] = useState(null);
  const limitCount = 200;
  const lastCreatedAt = useRef(null);
  const {currencies, kpiData} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );

  useEffect(() => {
    apiCurrencies.get()
  }, [])

  const loadMore = () => {
    lastCreatedAt.current = kpiData[kpiData.length - 1]?.created_at
    apiKPI.get({
      limit: limitCount,
      currency_id: selectCurrency.value,
      last_created_at: lastCreatedAt.current
    })
  }

  const renderKPIData = () => {
      return kpiData?.map(team => (
        <div key={team?.id}
             className={`team`}>
          <div
            className={`team__wrapper team__wrapper_large`}>
            <div className="team__block">
              <Link className="team__block-text team__block-text_yellow team__block-text_under" target="_blank" to={`/statistic/${team?.id}`}>
                {team?.notice !== null ? team?.notice : team?.id}
              </Link>
            </div>
            <div className="team__block">
              <div className="team__block-wrapper">
                <p className="team__block-text">{team?.agent_fee}</p>
              </div>
            </div>
            <div className="team__block">
              <div className="team__block-wrapper">
                <p className="team__block-text">{team?.trader_data?.total_buy_orders} / {team?.trader_data?.total_sell_orders}</p>
              </div>
            </div>
            <div className="team__block">
              <div className="team__block-wrapper">
                <p className="team__block-text">{team?.trader_data?.total_success_buy_orders} / {team?.trader_data?.total_success_sell_orders}</p>
              </div>
            </div>
            <div className="team__block">
              <div className="team__block-wrapper">
                <p className="team__block-text">{team?.trader_data?.total_cancel_buy_orders} / {team?.trader_data?.total_cancel_sell_orders}</p>
              </div>
            </div>
            <div className="team__block">
              <div className="team__block-wrapper">
                <p className="team__block-text">{team?.trader_data?.total_appeal_buy_orders} / {team?.trader_data?.total_appeal_sell_orders}</p>
              </div>
            </div>
          </div>
        </div>
      ))
  }

  return (
    <div className='kpi'>
      <Helmet>
        <title>UVION | KPI</title>
      </Helmet>
      <div className="kpi__top">
        {kpiData?.length === 0 && (
          <div className={`orders__banner`}>
            <div className="orders__banner-top">
              <p className="orders__banner-name">KPI</p>
              <div className='orders__banner-icon'>
                <img src="/assets/icons/appeal-banner-icon.svg" alt="orders"/>
              </div>
            </div>
            <div className="orders__banner-bottom">
              <p className="orders__banner-title">KPI по командам</p>
              <p className="orders__banner-subtitle">Выберите валюту для получения данных</p>
            </div>
          </div>
        )}
        <div className="profile__select mt-12">
          <CustomSelect
            options={currencies.map((currency) => {
              return {
                value: currency.id,
                label: currency.currency,
              };
            })}
            selected={selectCurrency}
            placeholder='Выберите валюту'
            isSearchable={false}
            handleSelect={(e) => {
              dispatch(setKpiEmpty([]))
              setSelectCurrency(e ?? null)
              apiKPI.get({
                limit: limitCount,
                currency_id: e.value,
              })
            }}
            isClearable={false}
          />
        </div>
      </div>
      {kpiData?.length > 0 && (
        <div className="teams__table">
          <div className="teams__table-top teams__table-top_large">
            <div className="teams__table-head">
              <p>Название</p>
            </div>
            <div className="teams__table-head">
              <p>Ставка агента</p>
            </div>
            <div className="teams__table-head">
              <p>Заявки на ввод/вывод</p>
            </div>
            <div className="teams__table-head">
              <p>Заявки на ввод/вывод в успехе</p>
            </div>
            <div className="teams__table-head">
              <p>Заявки на ввод/вывод в отмене</p>
            </div>
            <div className="teams__table-head">
              <p>Апелляции на ввод/вывод</p>
            </div>
          </div>
          <div className="teams__table-list">
            {renderKPIData()}
          </div>
          {kpiData?.length >= limitCount && (
            <div className="loadMore">
              <p onClick={() => {
                loadMore()
              }}>
                Загрузить еще...
              </p>
              <span>
            <img src="/assets/icons/arrow-down.svg" alt=""/>
          </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
