import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import useInputHandler from "../../Global/Hooks/inputHandler";
import useOnboarding from "../../Global/Api/onboarding.api";

import Input from "../../UI/Input";
import Button from "../../UI/Button";

import "./index.scss"
import {Helmet} from "react-helmet";

export default function Login() {
    const navigate = useNavigate()
    const tokenField = useInputHandler("");
    const codeField = useInputHandler("");
    const Onboarding = useOnboarding();

    useEffect(() => {
        if (localStorage.getItem("uvion_token_auth")?.length > 0) {
            navigate("/");
        }
    }, []);

    const isButtonDisabled = !(
        tokenField.value.length >= 30 && codeField.value.length === 6
    );

    const sendData = (event) => {
        event.preventDefault();
        Onboarding.loginHandle({
            'login': tokenField.value,
            'password': codeField.value
        })
    }

    return (
        <>
            <div className="login">
                <Helmet>
                    <title>UVION | ВХОД</title>
                </Helmet>
                <div className="login__box">
                    <form onSubmit={sendData} className="login__form">
                        <h1 className="login__title">Войти</h1>
                        <p className="login__subtitle">Заполните поля для авторизации</p>
                        <div className="login__input">
                            <Input
                              label="Токен"
                              placeholder="Введите токен для входа"
                              type="text"
                              id="token_field"
                              {...tokenField}
                              maxLength={71}
                            />
                        </div>
                        <div className="login__input mb-0">
                            <Input
                              label="Код аутентификации"
                              placeholder="Введите код"
                              type="text"
                              onlyNumber={true}
                              id="code_field"
                              {...codeField}
                              maxLength={6}
                            />
                        </div>
                        <div className="login__btn">
                            <Button
                              text="Войти"
                              onClick={sendData}
                              topPadding={12}
                              disabled={isButtonDisabled}
                              bottomPadding={12}
                              type='accept'
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
