import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { uvionApi } from "./Builder/uvion.api";
import uvionSlice from "./Slice/uvionSlice";
import apiMiddleware from "./apiMiddleware";

const rootReducer = combineReducers({
  uvionSlice,
});

export const store = configureStore({

  reducer: {
    [uvionApi.reducerPath]: uvionApi.reducer,
    rootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(uvionApi.middleware)
      .concat(apiMiddleware)
});
