import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import useGetOrdersHistory from "../../Global/Api/getOrdersHistory.api";
import useOrder from "../../Global/Api/orders.api";
import {setOrderDetails, setOrdersHistoryEmpty} from "../../Store/Slice/uvionSlice";

import Order from "../../Components/Order";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import {CustomSelect} from "../../UI/Select";
import Button from "../../UI/Button";
import Status from "../../Components/Status";
import {Link} from "react-router-dom";
import {useDebounce} from "../../Global/Hooks/useDebounce";
import SearchComponent from "../../Components/Search";
import DatePicker from 'rsuite/DatePicker';
import PhoneInput from 'react-phone-number-input'
import Toggle from "rsuite/Toggle";
import 'rsuite/DatePicker/styles/index.css';
import 'react-phone-number-input/style.css'
import "rsuite/Toggle/styles/index.css";
import {Helmet} from "react-helmet";

import './index.scss'


export default function OrdersHistory() {
  const dispatch = useDispatch();
  const getOrdersHistoryApi = useGetOrdersHistory();
  const getOrderDetails = useOrder();
  const [tab, setTab] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [orderInfo, setOrderInfo] = useState(null)
  const lastId = useRef(null);
  const lastDateTime = useRef(null);
  const limitCount = 50;
  const [filterModal, setFilterModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [statusSelect, setStatusSelect] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [value, setValue] = useState();
  const firstOnline = useRef(false);
  const [checked, setChecked] = useState(false);
  const [appealModal, setAppealModal] = useState(false);
  const [recvSelect, setRecvSelect] = useState(null);
  const [initialState, setInitialState] = useState({
    id: false,
    card_name: false,
    card_number: false,
    user_card_number: false,
    amount_crypto: false,
    amount_fiat: false,
  });
  const statusData = [
    {
      label: 'Завершен',
      value: 'success'
    },
    {
      label: 'Отменен',
      value: 'cancel'
    },
  ]
  const recvData = [
    {
      label: 'Номер карты',
      value: 'card'
    },
    {
      label: 'Номер телефона',
      value: 'phone'
    },
    {
      label: 'Номер счета',
      value: 'transfer'
    },
  ]
  const amount = useInputHandler("");
  const cardNumber = useInputHandler("");
  const checkNumber = useInputHandler("");
  const {ordersHistory, orderDetails, orderBillings, messageData} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );

  const debouncedSearchIdValue = useDebounce(searchId, 300);

  useEffect(() => {
    const formattedParams = formatParams();
    lastId.current = null
    lastDateTime.current = null
    getOrdersHistoryApi.get(formattedParams)
  }, [tab])

  useEffect(() => {
    if (searchId !== '') {
      getOrdersHistoryApi.getId(searchId)
    }
    if (searchId === '' && firstOnline.current === true) {
      const formattedParams = formatParams();
      getOrdersHistoryApi.get(formattedParams)
    }
    firstOnline.current = true
  }, [debouncedSearchIdValue])

  useEffect(() => {
    if (filterModal || detailsModal) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = 'visible'
    }
  }, [filterModal, detailsModal])

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    params.append('order_type', tab ? 'sell' : 'buy');
    params.append('time_filer', 'completed')
    if (amount?.value?.length > 0) params.append('order_amount', amount.value.replaceAll(' ', ''));
    if (dateFrom !== null) params.append('date_from', moment(dateFrom.toString()).format('YYYY-MM-DD'))
    if (dateTo !== null) params.append('date_to', moment(dateTo.toString()).format('YYYY-MM-DD'))
    if (cardNumber?.value?.length > 0) params.append('card_number', cardNumber.value.replaceAll(' ', ''));
    if (statusSelect !== null) params.append('order_status', statusSelect?.value);
    if (recvSelect !== null) params.append('requisite_type', recvSelect?.value);
    if (value?.length > 0) params.append('phone_number', value)
    if (value?.length > 0 && !checked) {
      params.append('ad_phone_number', true)
      params.append('client_phone_number', false)
    }
    if (value?.length > 0 && checked) {
      params.append('ad_phone_number', false)
      params.append('client_phone_number', true)
    }
    return params
  }

  const formatParamsPagination = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    params.append('order_type', tab ? 'sell' : 'buy');
    params.append('time_filer', 'completed')
    if (lastId.current !== null) params.append('order_id', lastId.current);
    if (lastDateTime.current !== null) params.append('last_datetime', lastDateTime.current);
    if (amount.length > 0) params.append('order_amount', amount.replaceAll(' ', ''));
    if (dateFrom !== null) params.append('date_from', moment(dateFrom.toString()).format('YYYY-MM-DD'))
    if (dateTo !== null) params.append('date_to', moment(dateTo.toString()).format('YYYY-MM-DD'))
    if (cardNumber.length > 0) params.append('card_number', cardNumber.replaceAll(' ', ''));
    if (statusSelect !== null) params.append('order_status', statusSelect?.value);
    if (recvSelect !== null) params.append('requisite_type', recvSelect?.value);
    if (value?.length > 0) params.append('phone_number', value)
    if (value?.length > 0 && !checked) {
      params.append('ad_phone_number', true)
      params.append('client_phone_number', false)
    }
    if (value?.length > 0 && checked) {
      params.append('ad_phone_number', false)
      params.append('client_phone_number', true)
    }
    return params
  }

  const loadMore = () => {
    lastDateTime.current = ordersHistory[ordersHistory.length - 1]?.completed_at
    const formattedParams = formatParamsPagination();
    getOrdersHistoryApi.get(formattedParams, 'pagination')
  }

  const renderOrdersHistory = () => {
    return ordersHistory?.map(item => (
      <Order openDetailsModal={openDetailsModal} openAppealModal={openAppealModal} key={item?.id} id={item?.id}
             item={item}/>
    ));
  }

  const openAppealModal = (item) => {
    setOrderInfo(item)
    setAppealModal(true)
  }

  const closeAppealModal = () => {
    setAppealModal(false)
    setOrderInfo(null)
  }

  const appealOrderSendData = () => {
    const body = {
      order_id: orderInfo?.id
    }
    getOrderDetails.openAppealOrder(body, closeAppealModal)
  }

  const renderOrderBilling = () => {
    return orderBillings?.map(item => (
      <div key={item?.id} className="history__details-modal-billing">
        <div className="history__details-modal-billing-line">
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Сумма</div>
            <div className="history__details-modal-billing-text">{item?.order_amount}</div>
          </div>
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Отвественность</div>
            <div className="history__details-modal-billing-text">{item?.order_substage}</div>
          </div>
        </div>
        <div className="history__details-modal-billing-line">
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Статус</div>
            <Status stage={item?.order_stage}
                    text={item?.order_stage === 'pending' ? 'В обработке' : item?.order_stage === 'success' ? 'Завершен' : item?.order_stage === 'cancel' ? 'Отменен' : 'Апелляция'}/>
          </div>
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Дата создания</div>
            <div
              className="history__details-modal-billing-text">{moment.utc(item?.created_at).local().format('DD.MM.YYYY')}</div>
            <div
              className="history__details-modal-billing-text">{moment.utc(item?.created_at).local().format('HH:mm:ss')}</div>
          </div>
        </div>
        {(messageData?.message?.length > 0 && item?.order_stage === 'success') && (
          <div className="history__details-modal-billing-line">
            <div className="history__details-modal-billing-comment">
              <div className="history__details-modal-billing-title">СМС</div>
              <div className="history__details-modal-billing-text">
                {messageData?.message}
              </div>
            </div>
          </div>
        )}
      </div>
    ))
  }

  const toggleFieldTemporarily = (fieldName) => {
    const updatedState = {...initialState};

    updatedState[fieldName] = true;

    setInitialState(updatedState);

    setTimeout(() => {
      setInitialState((prevState) => ({
        ...prevState,
        [fieldName]: false
      }));
    }, 5000);
  };

  const copyElement = (text) => {
    navigator.clipboard.writeText(text)
  }

  const openFilterModal = () => {
    setFilterModal(true)
  }

  const closeFilterModal = () => {
    setFilterModal(false)
  }

  const openDetailsModal = (item) => {
    getOrderDetails.get({order_id: item?.id})
    getOrderDetails.getBillings({order_id: item?.id})
    setDetailsModal(true)
  }

  const closeDetailsModal = () => {
    dispatch(setOrderDetails(null))
    setDetailsModal(false)
  }

  const filterHandle = () => {
    const formattedParams = formatParams();
    lastId.current = null
    lastDateTime.current = null
    getOrdersHistoryApi.get(formattedParams)
    closeFilterModal()
  }

  const handleSearchId = (searchTerm) => {
    setSearchId(searchTerm);
  };

  const formatCreditCardNumber = (creditCardNumber) => {
    if (!creditCardNumber) {
      return ''; // Если входная строка пуста или не определена, возвращаем пустую строку
    }

    // Удаляем все символы, кроме цифр
    const cleanedNumber = creditCardNumber.replace(/\D/g, '');

    // Добавляем пробел после каждых 4 символов
    const formattedNumber = cleanedNumber.replace(/(.{4})/g, '$1 ');

    // Возвращаем отформатированный номер карты
    return formattedNumber.trim(); // Удаляем лишние пробелы в начале и конце строки
  };

  return (
    <div className='history'>
      <Helmet>
        <title>UVION | ИСТОРИЯ ЗАЯВОК</title>
      </Helmet>
      {appealModal && (
        <Popup title='Открыть апелляцию' closePopup={closeAppealModal}>
          <div className="orders__popup-wrapper">
            <p className="orders__popup-text">Вы уверены что хотите открыть апелляцию?</p>
            <div className="orders__popup-btns">
              <div className="orders__popup-btn">
                <Button
                  text='Подтвердить'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={appealOrderSendData}
                />
              </div>
              <div className="orders__popup-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeAppealModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {filterModal && (
        <Popup title='Фильтр' closePopup={closeFilterModal}>
          <div className="history__filter-modal-wrapper">
            <p className="history__filter-modal-text">Настройте фильтр под себя</p>
            <div className="history__filter-modal-input">
              <Input
                placeholder='Сумма'
                type="text"
                id="amount"
                {...amount}
                onlyNumber={true}
                maxLength={8}
              />
            </div>
            <div className="history__filter-modal-fields">
              <div className="history__filter-modal-calendar">
                <DatePicker value={dateFrom} onChange={setDateFrom} locale={{ok: 'Применить'}}
                            className='filter-calendar' placeholder='От'
                            format="MM/dd/yyyy"/>
              </div>
              <div className="history__filter-modal-calendar">
                <DatePicker value={dateTo} onChange={setDateTo} locale={{ok: 'Применить'}} className='filter-calendar'
                            placeholder='До'
                            format="MM/dd/yyyy"/>
              </div>
            </div>
            <div className="history__filter-modal-select">
              <CustomSelect
                options={statusData}
                selected={statusSelect}
                placeholder='Статус'
                isSearchable={false}
                handleSelect={(e) => {
                  setStatusSelect(e ?? null)
                }}
                isClearable={true}
              />
            </div>
            <div className="history__filter-modal-select">
              <CustomSelect
                options={recvData}
                selected={recvSelect}
                placeholder='Тип реквизитов'
                isSearchable={false}
                handleSelect={(e) => {
                  setRecvSelect(e ?? null)
                }}
                isClearable={true}
              />
            </div>
            {recvSelect?.value === 'card' && (
              <div className="history__filter-modal-input mt-4">
                <Input
                  placeholder='Номер карты'
                  type="text"
                  id="cardNumber"
                  {...cardNumber}
                  onlyNumber={true}
                  maxLength={16}
                />
              </div>
            )}
            {recvSelect?.value === 'transfer' && (
              <div className="history__filter-modal-input mt-4">
                <Input
                  placeholder='Номер счета'
                  type="text"
                  id="checkNumber"
                  {...checkNumber}
                  onlyNumber={true}
                  maxLength={20}
                />
              </div>
            )}
            {recvSelect?.value === 'phone' && (
              <div className="history__filter-modal-fields mt-4">
                <div className="history__filter-modal-phone mt-4">
                  <PhoneInput
                    placeholder="Номер телефона"
                    value={value}
                    onChange={setValue}
                    international
                  />
                </div>
                <div className="history__filter-modal-toggle">
                  <Toggle
                    onChange={setChecked}
                    size="lg"
                    checkedChildren="Клиента"
                    unCheckedChildren="Свой"
                    checked={checked}
                  />
                </div>
              </div>
            )}
            <div className="history__filter-modal-btns">
              <div className="history__filter-modal-btn">
                <Button
                  text='Применить'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={filterHandle}
                />
              </div>
              <div className="history__filter-modal-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeFilterModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      <div className="history__details-modal">
        {detailsModal && (
          <Popup size={'large'} closePopup={closeDetailsModal}>
            <div className="history__details-modal-wrapper">
              <div className="history__details-modal-left">
                <p className="history__details-modal-title">Информация</p>
                <p className="history__details-modal-subtitle">Общая информация о сделке</p>
                <div className="history__details-modal-info">
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">Тип</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        <Status
                          text={orderDetails?.type === 'buy' ? `Получение ${orderDetails?.order_category.toUpperCase()}` : `Выплата ${orderDetails?.order_category?.toUpperCase()}`}/>
                      </div>
                    </div>
                  </div>
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">ID</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        {/*Тут отображает ID*/}
                        <p onClick={() => {
                          toggleFieldTemporarily('id')
                          copyElement(orderDetails?.id)
                        }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.id ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                          ID:{orderDetails?.id}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">Реквизиты</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        {/*Тут отображает наше имя карты*/}
                        <p onClick={() => {
                          toggleFieldTemporarily('card_name')
                          copyElement(orderDetails?.ad_data?.card_holder)
                        }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.card_name ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                          {orderDetails?.ad_data?.card_holder ? orderDetails?.ad_data?.card_holder : '--- --- ---'}
                        </p>
                        {/*Тут отображает наш номер карты*/}
                        <p onClick={() => {
                          toggleFieldTemporarily('card_number')
                          copyElement(orderDetails?.ad_data?.card_number !== null ? orderDetails?.ad_data?.card_number?.replaceAll(' ', '') : orderDetails?.ad_data?.phone_number !== null ? orderDetails?.ad_data?.phone_number : orderDetails?.ad_data?.requisite)
                        }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.card_number ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                          {orderDetails?.ad_data?.card_number !== null ? formatCreditCardNumber(orderDetails?.ad_data?.card_number) : orderDetails?.ad_data?.phone_number !== null ? orderDetails?.ad_data?.phone_number : orderDetails?.ad_data?.requisite}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">Банк</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        <div className="history__details-modal-line-bank">
                          {orderDetails?.payment_method?.bank_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">Реквизиты клиента</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        {/*Тут отображает номер карты клиента*/}
                        <p onClick={() => {
                          toggleFieldTemporarily('user_card_number')
                          copyElement(orderDetails?.card_number !== null ? orderDetails?.card_number?.replaceAll(' ', '') : orderDetails?.phone_number !== null ? orderDetails?.phone_number : orderDetails?.requisite)
                        }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.user_card_number ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                          {orderDetails?.card_number !== null ? formatCreditCardNumber(orderDetails?.card_number) : orderDetails?.phone_number !== null ? orderDetails?.phone_number : orderDetails?.requisite}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">Получаем/Отправляем</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        {/*Тут отображает фиат*/}
                        <p onClick={() => {
                          toggleFieldTemporarily('amount_fiat')
                          copyElement(orderDetails?.order_amount)
                        }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.amount_fiat ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                          {orderDetails?.order_amount}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">Дата создания</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        {/*Тут отображает дату*/}
                        <p className="history__details-modal-line-text">
                          {moment.utc(orderDetails?.created_at).local().format('DD.MM.YYYY')}
                        </p>
                        {/*Тут отображает время*/}
                        <p className="history__details-modal-line-text">
                          {moment.utc(orderDetails?.created_at).local().format('HH:mm:ss')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="history__details-modal-line">
                    <div className="history__details-modal-line-wrapper">
                      <div className="history__details-modal-line-left">
                        <p className="history__details-modal-line-title">Статус</p>
                      </div>
                      <div className="history__details-modal-line-right">
                        <Status
                          stage={orderDetails?.order_stage}
                          text={orderDetails?.order_stage === 'pending' ? 'В обработке' : orderDetails?.order_stage === 'success' ? 'Завершен' : orderDetails?.order_stage === 'cancel' ? 'Отменен' : 'Апелляция'}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="history__details-modal-center">
                {orderDetails?.receipt_url !== null && (
                  <>
                    <p className="history__details-modal-title">Прикрепленные файлы</p>
                    <p className="history__details-modal-subtitle">Список всех прикрепленных файлов</p>
                    <div className="history__details-modal-files mb-16">
                      <div className="history__details-modal-file">
                        <div className="history__details-modal-file-wrapper">
                          <div className="history__details-modal-file-icon">
                            <img src="/assets/icons/file-placeholder.svg" alt="file"/>
                          </div>
                          <div className="history__details-modal-file-info">
                            <p className="history__details-modal-file-name">Выписка.pdf</p>
                            <Link target='_blank' to={orderDetails?.receipt_url}
                                  className="history__details-modal-file-link">Открыть в новой вкладке</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {orderDetails?.admin_receipts !== null && (
                  <>
                    <p className="history__details-modal-title">Файлы прикрепленные администратором</p>
                    <p className="history__details-modal-subtitle">Список всех прикрепленных файлов
                      администратором</p>
                    <div className="history__details-modal-files">
                      {orderDetails?.admin_receipts?.map((item, index) => (
                        <div key={index} className="history__details-modal-file">
                          <div className="history__details-modal-file-wrapper">
                            <div className="history__details-modal-file-icon">
                              <img src="/assets/icons/file-placeholder.svg" alt="file"/>
                            </div>
                            <div className="history__details-modal-file-info">
                              <p className="history__details-modal-file-name">Выписка {index + 1}.pdf</p>
                              <Link target='_blank' to={item?.receipt_id}
                                    className="history__details-modal-file-link">Открыть в новой вкладке</Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className="history__details-modal-right">
                <p className="history__details-modal-title">Биллинги</p>
                <p className="history__details-modal-subtitle">Общая информация о сделке</p>
                <div className="history__details-modal-billings-list">
                  {renderOrderBilling()}
                </div>
              </div>
            </div>
          </Popup>
        )}
      </div>
      <div className={`history__top ${ordersHistory?.length === 0 ? 'history__top_mb' : ''}`}>
        <div className="history__tabs">
          <div onClick={() => {
            setTab(false)
            lastDateTime.current = null
            dispatch(setOrdersHistoryEmpty([]));
          }} className={`history__tab ${!tab ? 'history__tab_active' : ''}`}>
            <p className='history__tab-text'>Получение</p>
          </div>
          <div onClick={() => {
            setTab(true)
            lastDateTime.current = null
            dispatch(setOrdersHistoryEmpty([]));
          }} className={`history__tab ${tab ? 'history__tab_active' : ''}`}>
            <p className='history__tab-text'>Выплата</p>
          </div>
        </div>
        <div className="history__top-right">
          <div className="history__top-search">
            <SearchComponent
              searchTerm={searchId}
              onSearch={handleSearchId}
              placeholder={'Поиск по ID'}
            />
          </div>
          <div onClick={() => {
            openFilterModal()
          }} className="history__filter">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M4.56517 3C3.70108 3 3 3.71286 3 4.5904V5.52644C3 6.17647 3.24719 6.80158 3.68936 7.27177L8.5351 12.4243L8.53723 12.4211C9.47271 13.3788 9.99905 14.6734 9.99905 16.0233V20.5952C9.99905 20.9007 10.3187 21.0957 10.584 20.9516L13.3436 19.4479C13.7602 19.2204 14.0201 18.7784 14.0201 18.2984V16.0114C14.0201 14.6691 14.539 13.3799 15.466 12.4243L20.3117 7.27177C20.7528 6.80158 21 6.17647 21 5.52644V4.5904C21 3.71286 20.3 3 19.4359 3H4.56517Z"
                    stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
      {ordersHistory?.length === 0 && (
        <div className={`orders__banner ${ordersHistory?.length === 0 ? 'orders__banner_empty' : ''}`}>
          <div className="orders__banner-top">
            <p className="orders__banner-name">История заявок</p>
            <div className='orders__banner-icon'>
              <img src="/assets/icons/appeal-banner-icon.svg" alt="orders"/>
            </div>
          </div>
          <div className="orders__banner-bottom">
            <p className="orders__banner-title">Будьте бдительны</p>
            <p className="orders__banner-subtitle">Ответственно подходите к работе с заявками, любая ваша ошибка может
              привести к потере средств.</p>
          </div>
        </div>
      )}
      {ordersHistory?.length > 0 && (
        <div className="history__table">
          <div className="history__table-top">
            <div className="history__table-head">
              <p>Тип/ID</p>
            </div>
            <div className="history__table-head">
              <p>Реквизиты</p>
            </div>
            <div className="history__table-head">
              <p>Банк</p>
            </div>
            <div className="history__table-head">
              <p>Получатель/Отправитель</p>
            </div>
            <div className="history__table-head">
              <p>Получаем/Отправляем</p>
            </div>
            <div className="history__table-head">
              <p>Дата создания</p>
            </div>
            <div className="history__table-head">
              <p>Статус</p>
            </div>
            <div className="history__table-head">
              <p>Действие</p>
            </div>
          </div>
          <div className="history__table-list">
            {renderOrdersHistory()}
          </div>
          {ordersHistory?.length >= limitCount && (
            <div className="loadMore">
              <p onClick={() => {
                loadMore()
              }}>
                Загрузить еще...
              </p>
              <span>
            <img src="/assets/icons/arrow-down.svg" alt="arrow icon"/>
          </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
