import React, {useState} from "react";

import "./index.scss";

export default function SearchComponent({
                                          onSearch,
                                          searchTerm,
                                          placeholder = "Placeholder",
                                          id,
                                          text,
                                          imgUrl = "/assets/icons/search.svg",
                                        }) {
  const handleChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div className='search__wrapper'>
      <div className="search">
        {imgUrl !== '' && (<img src={imgUrl} alt="search"/>)}
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          placeholder={placeholder}
          id={id}
        />
      </div>
      <p>{text}</p>
    </div>
  );
}
