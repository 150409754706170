import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {Link} from "react-router-dom";

import Popup from "../../Components/Popup";
import Button from "../../UI/Button";
import useInputHandler from "../../Global/Hooks/inputHandler";
import {CustomSelect} from "../../UI/Select";
import Input from "../../UI/Input";
import useOrder from "../../Global/Api/orders.api";
import {setOrderDetails, setOrdersAppealCountNull} from "../../Store/Slice/uvionSlice";
import useGetOrdersAppeal from "../../Global/Api/getOrdersAppeal.api";
import Order from "../../Components/Order";
import Status from "../../Components/Status";

import './index.scss'
import {Helmet} from "react-helmet";
import {logDOM} from "@testing-library/react";

export default function OrdersAppeal() {
  const dispatch = useDispatch();
  const orderApi = useOrder();
  const getOrderDetails = useOrder();
  const getOrdersAppeal = useGetOrdersAppeal();
  const [acceptModal, setAcceptModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rejectSelect, setRejectSelect] = useState(null);
  const [acceptBuyModal, setAcceptBuyModal] = useState(false);
  // const [selectedFileSell, setSelectedFileSell] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false);
  const [cancelledModal, setCancelledModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [searchArray, setSearchArray] = useState([]);
  const limitCount = 100;
  const lastId = useRef(null);
  const amountValue = useInputHandler("");
  const [initialState, setInitialState] = useState({
    id: false,
    card_name: false,
    card_number: false,
    user_card_number: false,
    amount_crypto: false,
    amount_fiat: false,
  });
  const ordersAppeal = useSelector(
    (state) => state.rootReducer.uvionSlice.ordersAppeal
  );
  const {orderDetails, orderBillings, messageData} = useSelector(
    (state) => state.rootReducer.uvionSlice
  );
  const rejectData = [
    {
      label: 'Клиент указал неверный банк',
      value: 'Клиент указал неверный банк'
    },
    {
      label: 'Карта заблокирована',
      value: 'Карта заблокирована'
    },
    {
      label: 'Высокий риск',
      value: 'Высокий риск'
    },
  ]

  const isButtonDisabled = !(selectedFile !== null);

  useEffect(() => {
    dispatch(setOrdersAppealCountNull())
  }, [])

  useEffect(() => {
    const formattedParams = formatParams();
    getOrdersAppeal.get(formattedParams)
  }, [])

  useEffect(() => {
    if (acceptModal || acceptBuyModal || detailsModal || cancelledModal || rejectModal) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = 'visible'
    }
  }, [acceptModal, acceptBuyModal, detailsModal, cancelledModal, rejectModal])

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    if (lastId.current !== null) params.append('order_id', lastId.current);
    return params
  }

  const loadMore = () => {
    lastId.current = ordersAppeal[ordersAppeal.length - 1]?.id
    const formattedParams = formatParams();
    getOrdersAppeal.get(formattedParams)
  }

  const toggleFieldTemporarily = (fieldName) => {
    const updatedState = {...initialState};

    updatedState[fieldName] = true;

    setInitialState(updatedState);

    setTimeout(() => {
      setInitialState((prevState) => ({
        ...prevState,
        [fieldName]: false
      }));
    }, 5000);
  };

  const copyElement = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      // Создаем временный элемент для копирования
      let tempElement = document.createElement('span');
      tempElement.textContent = text;

      // Скрываем элемент и добавляем его в DOM
      tempElement.style.position = 'fixed';
      tempElement.style.opacity = '0';
      tempElement.style.pointerEvents = 'none';

      document.body.appendChild(tempElement);

      // Создаем Range и выделяем содержимое элемента
      let range = document.createRange();
      range.selectNodeContents(tempElement);

      // Используем Selection для выделения Range
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Копируем в буфер обмена
      try {
        document.execCommand('copy');
      } catch (err) {
      }

      // Удаляем временный элемент и очищаем выделение
      document.body.removeChild(tempElement);
      selection.removeAllRanges();
    }
  };

  const openAcceptModal = (item) => {
    setAcceptModal(true)
    setOrderInfo(item)
  }

  const openAcceptBuyModal = (item) => {
    setAcceptBuyModal(true)
    setOrderInfo(item)
  }

  const openRejectModal = (item) => {
    setRejectModal(true)
    setOrderInfo(item)
  }

  const openCancelledModal = (item) => {
    setCancelledModal(true)
    setOrderInfo(item)
  }

  const openDetailsModal = (item) => {
    getOrderDetails.get({order_id: item?.id})
    getOrderDetails.getBillings({order_id: item?.id})
    setDetailsModal(true)
  }

  const closeAcceptModal = () => {
    setAcceptModal(false)
    setAcceptBuyModal(false)
    setOrderInfo(null)
    setSelectedFile(null)
    setRejectSelect(null)
    amountValue.setChange('')
    localStorage.removeItem('reason')
  }

  const closeAcceptBuyModal = () => {
    setAcceptBuyModal(false)
    setOrderInfo(null)
    setSelectedFile(null)
    // setSelectedFileSell(null)
    setRejectSelect(null)
    amountValue.setChange('')
    localStorage.removeItem('reason')
  }

  const closeCancelledModal = () => {
    setCancelledModal(false)
    setOrderInfo(null)
    setSelectedFile(null)
    setRejectSelect(null)
    amountValue.setChange('')
    localStorage.removeItem('order_id')
    localStorage.removeItem('reason')
  }

  const closeRejectModal = () => {
    setRejectModal(false)
    setOrderInfo(null)
    setSelectedFile(null)
    setRejectSelect(null)
    amountValue.setChange('')
    localStorage.removeItem('order_id')
    localStorage.removeItem('reason')
  }

  const closeDetailsModal = () => {
    dispatch(setOrderDetails(null))
    setDetailsModal(false)
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileBuyChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const acceptOrderSendData = () => {
    orderApi.acceptOrderInAppeal({
      'order_id': orderInfo?.id,
    }, closeAcceptModal, {limitCount: limitCount, searchId: lastId}, selectedFile, orderInfo?.order_amount)
  }

  const acceptOrderSendDataBuy = () => {
    orderApi.acceptOrderInAppealBuy({
      'order_id': orderInfo?.id,
    }, closeAcceptBuyModal, {limitCount: limitCount, searchId: lastId}, orderInfo?.order_amount)
  }

  const recalculationOrderSendData = () => {
    orderApi.acceptOrderInAppealBuy({
      'order_id': orderInfo?.id,
    }, closeRejectModal, {limitCount: limitCount, searchId: searchId}, amountValue.value)
  }

  const rejectOrderSendData = () => {
    orderApi.rejectOrderInAppeal({
      'order_id': orderInfo?.id,
    }, closeCancelledModal, {limitCount: limitCount, searchId: searchId}, selectedFile, orderInfo?.order_amount)
  }

  const renderOrders = useMemo(() => {
    if (searchId !== '') {
      if (searchArray?.length === 0) {
        return (
          <div className='orders__empty'>
          </div>
        )
      } else {
        return searchArray?.map(item => (
          <Order key={item?.id} item={item} openAcceptModal={openAcceptModal} openAcceptBuyModal={openAcceptBuyModal}
                 openRejectModal={openRejectModal} openCancelledModal={openCancelledModal}
                 openDetailsModal={openDetailsModal}/>
        ))
      }
    } else {
      if (ordersAppeal?.length === 0) {
        return (
          <div className='orders__empty'>
          </div>
        )
      } else {
        return ordersAppeal?.map(item => (
          <Order key={item?.id} item={item} openAcceptModal={openAcceptModal} openAcceptBuyModal={openAcceptBuyModal}
                 openRejectModal={openRejectModal} openCancelledModal={openCancelledModal}
                 openDetailsModal={openDetailsModal}/>
        ))
      }
    }
  }, [searchId, searchArray, ordersAppeal, openAcceptModal, openRejectModal, openAcceptBuyModal, openCancelledModal, openDetailsModal]);

  const renderOrderBilling = () => {
    return orderBillings?.map(item => (
      <div key={item?.id} className="history__details-modal-billing">
        <div className="history__details-modal-billing-line">
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Сумма</div>
            <div className="history__details-modal-billing-text">{item?.order_amount}</div>
          </div>
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Отвественность</div>
            <div className="history__details-modal-billing-text">{item?.order_substage}</div>
          </div>
        </div>
        <div className="history__details-modal-billing-line">
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Статус</div>
            <Status stage={item?.order_stage}
                    text={item?.order_stage === 'pending' ? 'В обработке' : item?.order_stage === 'success' ? 'Завершен' : item?.order_stage === 'cancel' ? 'Отменен' : 'Апелляция'}/>
          </div>
          <div className="history__details-modal-billing-block">
            <div className="history__details-modal-billing-title">Дата создания</div>
            <div
              className="history__details-modal-billing-text">{moment.utc(item?.created_at).local().format('DD.MM.YYYY')}</div>
            <div
              className="history__details-modal-billing-text">{moment.utc(item?.created_at).local().format('HH:mm:ss')}</div>
          </div>
        </div>
        {messageData?.message?.length > 0 && (
          <div className="history__details-modal-billing-line">
            <div className="history__details-modal-billing-comment">
              <div className="history__details-modal-billing-title">СМС</div>
              <div className="history__details-modal-billing-text">
                {messageData?.message}
              </div>
            </div>
          </div>
        )}
      </div>
    ))
  }

  const formatCreditCardNumber = (creditCardNumber) => {
    if (!creditCardNumber) {
      return ''; // Если входная строка пуста или не определена, возвращаем пустую строку
    }

    // Удаляем все символы, кроме цифр
    const cleanedNumber = creditCardNumber.replace(/\D/g, '');

    // Добавляем пробел после каждых 4 символов
    const formattedNumber = cleanedNumber.replace(/(.{4})/g, '$1 ');

    // Возвращаем отформатированный номер карты
    return formattedNumber.trim(); // Удаляем лишние пробелы в начале и конце строки
  };


  return (
    <div className="orders">
      <Helmet>
        <title>UVION | АПЕЛЛЯЦИИ</title>
      </Helmet>
      {acceptModal && (
        <Popup title='Подтвердить заявку' closePopup={closeAcceptModal}>
          <div className="orders__popup-wrapper">
            <p className="orders__popup-text">Вы уверены что хотите подтвердить заявку?</p>
            <div className="orders__popup-btns">
              <div className="orders__popup-btn">
                <Button
                  text='Подтвердить'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={acceptOrderSendData}
                />
              </div>
              <div className="orders__popup-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeAcceptModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {acceptBuyModal && (
        <Popup title='Подтвердить заявку' closePopup={closeAcceptBuyModal}>
          <div className="orders__popup-wrapper">
            <p className="orders__popup-text">Вы уверены что хотите принять заявку?</p>
            {
              orderInfo?.type === 'buy' ? (
                <div className="orders__popup-btns">
                  <div className="orders__popup-btn">
                    <Button
                      text='Подтвердить'
                      type='accept'
                      bottomPadding={12}
                      topPadding={12}
                      onClick={acceptOrderSendDataBuy}
                    />
                  </div>
                  <div className="orders__popup-btn">
                    <Button
                      text='Отмена'
                      bottomPadding={12}
                      topPadding={12}
                      onClick={closeAcceptBuyModal}
                    />
                  </div>
                </div>

              ) : null
            }
            {
              orderInfo?.type === 'sell' ? (
                <div>
                  <div className="orders__popup-upload">
                    <label htmlFor="receipt">
                      <div className="orders__popup-upload-icon">
                        <img src="/assets/icons/upload-file.svg" alt="upload file"/>
                      </div>
                      <p className="orders__popup-upload-text">Прикрепить (чек, выписку)</p>
                      <div className='orders__popup-upload-badges'>
                        <div className="orders__popup-upload-badge">
                          <p className="orders__popup-upload-badge-text">PDF</p>
                        </div>
                        <div className="orders__popup-upload-badge">
                          <p className="orders__popup-upload-badge-text">> 10 MB</p>
                        </div>
                      </div>
                    </label>
                    <input onChange={handleFileBuyChange} className="orders__popup-upload-input" type="file"
                           id='receipt'
                           accept="application/pdf"/>
                  </div>
                  {selectedFile !== null && (
                    <div className="orders__popup-file">
                      <div className="orders__popup-file-left">
                        <p className="orders__popup-file-title">{selectedFile?.name}</p>
                        <p className="orders__popup-file-subtitle">Загрузка завершена</p>
                      </div>
                      <div className="orders__popup-file-right">
                        <div onClick={() => {
                          setSelectedFile([])
                        }} className="orders__popup-file-check">
                          <img src="/assets/icons/upload-check.svg" alt="check"/>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="orders__popup-btns">
                    <div className="orders__popup-btn">
                      <Button
                        text='Подтвердить'
                        type='accept'
                        bottomPadding={12}
                        topPadding={12}
                        disabled={selectedFile === null}
                        onClick={acceptOrderSendData}
                      />
                    </div>
                    <div className="orders__popup-btn">
                      <Button
                        text='Отмена'
                        bottomPadding={12}
                        topPadding={12}
                        onClick={closeAcceptBuyModal}
                      />
                    </div>
                  </div>
                </div>

              ) : null
            }
          </div>
        </Popup>
      )}
      {cancelledModal && (
        <Popup title='Отменить заявку' closePopup={closeCancelledModal}>
          <div className="orders__popup-wrapper">
            <p className="orders__popup-text">Вы уверены что хотите отменить заявку?</p>
            <div className="orders__popup-upload">
              <label htmlFor="receipt">
                <div className="orders__popup-upload-icon">
                  <img src="/assets/icons/upload-file.svg" alt="upload file"/>
                </div>
                <p className="orders__popup-upload-text">Прикрепить (чек, выписку)</p>
                <div className='orders__popup-upload-badges'>
                  <div className="orders__popup-upload-badge">
                    <p className="orders__popup-upload-badge-text">PDF</p>
                  </div>
                  <div className="orders__popup-upload-badge">
                    <p className="orders__popup-upload-badge-text">> 10 MB</p>
                  </div>
                </div>
              </label>
              <input onChange={handleFileChange} className="orders__popup-upload-input" type="file" id='receipt'
                     accept="application/pdf"/>
            </div>
            {selectedFile !== null && (
              <div className="orders__popup-file">
                <div className="orders__popup-file-left">
                  <p className="orders__popup-file-title">{selectedFile?.name}</p>
                  <p className="orders__popup-file-subtitle">Загрузка завершена</p>
                </div>
                <div className="orders__popup-file-right">
                  <div onClick={() => {
                    setSelectedFile([])
                  }} className="orders__popup-file-check">
                    <img src="/assets/icons/upload-check.svg" alt="check"/>
                  </div>
                </div>
              </div>
            )}
            <div className="orders__popup-btns">
              <div className="orders__popup-btn">
                <Button
                  text='Отменить заявку'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={rejectOrderSendData}
                  disabled={isButtonDisabled}
                />
              </div>
              <div className="orders__popup-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeCancelledModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {detailsModal && (
        <Popup size={'large'} closePopup={closeDetailsModal}>
          <div className="history__details-modal-wrapper">
            <div className="history__details-modal-left">
              <p className="history__details-modal-title">Информация</p>
              <p className="history__details-modal-subtitle">Общая информация о сделке</p>
              <div className="history__details-modal-info">
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Тип</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      <Status
                        text={orderDetails?.type === 'buy' ? `Получение ${orderDetails?.order_category.toUpperCase()}` : `Выплата ${orderDetails?.order_category?.toUpperCase()}`}/>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">ID</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает ID*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('id')
                        copyElement(orderDetails?.id)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.id ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        ID:{orderDetails?.id}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Реквизиты</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает наше имя карты*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('card_name')
                        copyElement(orderDetails?.ad_data?.card_holder)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.card_name ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.ad_data?.card_holder ? orderDetails?.ad_data?.card_holder : '--- --- ---'}
                      </p>
                      {/*Тут отображает наш номер карты*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('card_number')
                        copyElement(orderDetails?.ad_data?.card_number !== null ? orderDetails?.ad_data?.card_number?.replaceAll(' ', '') : orderDetails?.ad_data?.phone_number !== null ? orderDetails?.ad_data?.phone_number : orderDetails?.ad_data?.requisite)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.card_number ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.ad_data?.card_number !== null ? formatCreditCardNumber(orderDetails?.ad_data?.card_number) : orderDetails?.ad_data?.phone_number !== null ? orderDetails?.ad_data?.phone_number : orderDetails?.ad_data?.requisite}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Банк</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      <div className="history__details-modal-line-bank">
                        {orderDetails?.payment_method?.bank_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Реквизиты клиента</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает номер карты клиента*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('user_card_number')
                        copyElement(orderDetails?.card_number !== null ? orderDetails?.card_number?.replaceAll(' ', '') : orderDetails?.phone_number !== null ? orderDetails?.phone_number : orderDetails?.requisite)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.user_card_number ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.card_number !== null ? formatCreditCardNumber(orderDetails?.card_number) : orderDetails?.phone_number !== null ? orderDetails?.phone_number : orderDetails?.requisite}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Получаем/Отправляем</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает фиат*/}
                      <p onClick={() => {
                        toggleFieldTemporarily('amount_fiat')
                        copyElement(orderDetails?.order_amount)
                      }} className="history__details-modal-line-text cur-p">
                          <span className='history__details-modal-line-copy'>
                            {!initialState?.amount_fiat ? (
                              <img src="/assets/icons/copy.svg" alt="copy"/>
                            ) : (
                              <img src="/assets/icons/check-circle.svg" alt="check"/>
                            )}
                          </span>
                        {orderDetails?.order_amount}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Дата создания</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      {/*Тут отображает дату*/}
                      <p className="history__details-modal-line-text">
                        {moment.utc(orderDetails?.created_at).local().format('DD.MM.YYYY')}
                      </p>
                      {/*Тут отображает время*/}
                      <p className="history__details-modal-line-text">
                        {moment.utc(orderDetails?.created_at).local().format('HH:mm:ss')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="history__details-modal-line">
                  <div className="history__details-modal-line-wrapper">
                    <div className="history__details-modal-line-left">
                      <p className="history__details-modal-line-title">Статус</p>
                    </div>
                    <div className="history__details-modal-line-right">
                      <Status
                        stage={orderDetails?.order_stage}
                        text={orderDetails?.order_stage === 'pending' ? 'В обработке' : orderDetails?.order_stage === 'success' ? 'Завершен' : orderDetails?.order_stage === 'cancel' ? 'Отменен' : 'Апелляция'}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="history__details-modal-center">
              {orderDetails?.receipt_url !== null && (
                <>
                  <p className="history__details-modal-title">Прикрепленные файлы</p>
                  <p className="history__details-modal-subtitle">Список всех прикрепленных файлов</p>
                  <div className="history__details-modal-files mb-16">
                    <div className="history__details-modal-file">
                      <div className="history__details-modal-file-wrapper">
                        <div className="history__details-modal-file-icon">
                          <img src="/assets/icons/file-placeholder.svg" alt="file"/>
                        </div>
                        <div className="history__details-modal-file-info">
                          <p className="history__details-modal-file-name">Выписка.pdf</p>
                          <Link target='_blank' to={orderDetails?.receipt_url}
                                className="history__details-modal-file-link">Открыть в новой вкладке</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {orderDetails?.admin_receipts !== null && (
                <>
                  <p className="history__details-modal-title">Файлы прикрепленные администратором</p>
                  <p className="history__details-modal-subtitle">Список всех прикрепленных файлов
                    администратором</p>
                  <div className="history__details-modal-files">
                    {orderDetails?.admin_receipts?.map((item, index) => (
                      <div key={index} className="history__details-modal-file">
                        <div className="history__details-modal-file-wrapper">
                          <div className="history__details-modal-file-icon">
                            <img src="/assets/icons/file-placeholder.svg" alt="file"/>
                          </div>
                          <div className="history__details-modal-file-info">
                            <p className="history__details-modal-file-name">Выписка {index + 1}.pdf</p>
                            <Link target='_blank' to={item?.receipt_id}
                                  className="history__details-modal-file-link">Открыть в новой вкладке</Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="history__details-modal-right">
              <p className="history__details-modal-title">Биллинги</p>
              <p className="history__details-modal-subtitle">Общая информация о сделке</p>
              <div className="history__details-modal-billings-list">
                {renderOrderBilling()}
              </div>
            </div>
          </div>
        </Popup>
      )}
      <div className={`orders__banner ${ordersAppeal?.length === 0 ? 'orders__banner_empty' : ''}`}>
        <div className="orders__banner-top">
          <p className="orders__banner-name">Апелляции</p>
          <div className='orders__banner-icon'>
            <img src="/assets/icons/appeal-banner-icon.svg" alt="orders"/>
          </div>
        </div>
        <div className="orders__banner-bottom">
          <p className="orders__banner-title">Будьте бдительны</p>
          <p className="orders__banner-subtitle">Ответственно подходите к работе с апелляциями, любая ваша ошибка может
            привести к потере средств.</p>
        </div>
      </div>
      {ordersAppeal?.length > 0 && (
        <div className="orders__table">
          <div className="orders__table-top">
            <div className="orders__table-head">
              <p>ID/Тип</p>
            </div>
            {/*<div className="orders__table-head">*/}
            {/*  <p>ID</p>*/}
            {/*</div>*/}
            <div className="orders__table-head">
              <p>Реквизиты</p>
            </div>
            <div className="orders__table-head">
              <p>Банк</p>
            </div>
            <div className="orders__table-head">
              <p>Получатель/Отправитель</p>
            </div>
            <div className="orders__table-head">
              <p>Получаем/Отправляем</p>
            </div>
            <div className="orders__table-head">
              <p>Дата создания</p>
            </div>
            <div className="orders__table-head">
              <p>Статус</p>
            </div>
            <div className="orders__table-head">
              <p>Действие</p>
            </div>
          </div>
          <div className="orders__table-list">
            {renderOrders}
          </div>
          {ordersAppeal?.length >= limitCount && (
            <div className="loadMore">
              <p onClick={() => {
                loadMore()
              }}>
                Загрузить еще...
              </p>
              <span>
            <img src="/assets/icons/arrow-down.svg" alt=""/>
          </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
