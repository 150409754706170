import React from "react";
import Select from "react-select";
import Button from "../Button";
import "./index.scss";

export const CustomSelect = ({
                               id,
                               defaultValue,
                               options,
                               placeholder,
                               handleSelect,
                               showButton = false,
                               handleButton,
                               buttonText,
                               typeButton,
                               customRef,
                               disabled,
                               isSearchable = true,
                               isClearable = true,
                               selected = null,
                             }) => {
  const customOption = ({innerRef, innerProps, isDisabled, children}) =>
    !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="customMenu">
        <div className="customMenu__children">{children}</div>
        {showButton && (
          <Button type={typeButton} action={handleButton} text={buttonText}/>
        )}
      </div>
    ) : null;

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
    }),

    control: () => ({
      width: "100%",
      border: "1px solid #E5E7E9",
      borderRadius: 8,
      display: "flex",
      paddingTop: 4,
      paddingBottom: 4,
      fontSize: 10,
      backgroundColor: "#ffffff",
    }),
    indicatorSeparator: () => ({}),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#172B4D" : "#172B4D",
      backgroundColor: state.isSelected ? "#ffffff" : "#FFFFFF",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return {...provided, opacity, transition};
    },
  };

  return (
    <>
      <Select
        id={id}
        ref={customRef && customRef}
        value={selected}
        options={options}
        defaultValue={defaultValue}
        styles={customStyles}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isDisable
        isDisabled={disabled}
        onChange={handleSelect}
        components={{Menu: customOption}}
        isClearable={isClearable}
      />
    </>
  );
};
