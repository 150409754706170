import { useDispatch } from "react-redux";
import {useGetAdsMutation} from "../../Store/Builder/uvion.api";
import {setLoader, setSellAds} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";

const useGetAds = () => {
  const dispatch = useDispatch();
  const [getAds] = useGetAdsMutation();
  const get = (params) => {
    dispatch(setLoader(true))
    getAds(params)
      .unwrap()
      .then((res) => {
        dispatch(setSellAds(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    get,
  };
};

export default useGetAds;


