import {useDispatch, useSelector} from "react-redux";
import {
  useGetAvgStatisticsMutation,
  useGetCancelPercentMutation,
  useGetOrdersActiveMutation,
  useGetOrdersStatisticMutation,
  useGetStatisticsMutation
} from "../../Store/Builder/uvion.api";
import {
  setAllOrdersStatistics, setAvgStatistics, setCancelPercent, setCountLimit, setCurrentOrder,
  setLoader, setNewOrderBuy,
  setOrdersActive,
  setOrdersAppeal,
  setOrdersBuy, setOrdersBuyEmpty,
  setOrdersStatistics
} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";
import {useLocation} from "react-router-dom";

const useGetOrders = () => {
  const location = useLocation()
  const dispatch = useDispatch();
  const [getOrders] = useGetOrdersActiveMutation();
  const [apiGetOrdersStatistic] = useGetOrdersStatisticMutation();
  const [apiGetAllOrdersStatistics] = useGetStatisticsMutation();
  const [apiGetCancelPercent] = useGetCancelPercentMutation();
  const [apiGetAvgStatistics] = useGetAvgStatisticsMutation();
  const ordersBuy = useSelector(
    (state) => state.rootReducer.uvionSlice.ordersBuy
  );

  const get = (params, pagination) => {
    dispatch(setLoader(true))
    if (pagination !== 'pagination') {
      dispatch(setOrdersBuyEmpty([]))
    }
    getOrders(params)
      .unwrap()
      .then((res) => {
        if (location?.pathname === '/') {
          dispatch(setOrdersActive(res))
        }
        if (location?.pathname === '/buy') {
          dispatch(setOrdersBuy(res))
          if (res?.length > 0) {
            if (ordersBuy?.length > 0) {
              dispatch(setCountLimit(ordersBuy?.length + res?.length))
            }
          }
        }
        if (location?.pathname === '/orders-appeal') {
          dispatch(setOrdersAppeal(res?.data?.filter(item => item?.status === 'appeal')))
        }
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getStatistic = (params) => {
    dispatch(setLoader(true))
    apiGetOrdersStatistic(params)
      .unwrap()
      .then((res) => {
        dispatch(setOrdersStatistics(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getAllStatistics = () => {
    dispatch(setLoader(true))
    apiGetAllOrdersStatistics()
      .unwrap()
      .then((res) => {
        dispatch(setAllOrdersStatistics(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getCancelPercent = (params) => {
    dispatch(setLoader(true))
    apiGetCancelPercent(params)
      .unwrap()
      .then((res) => {
        dispatch(setCancelPercent(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getAvgStatistics = (params) => {
    dispatch(setLoader(true))
    apiGetAvgStatistics(params)
      .unwrap()
      .then((res) => {
        dispatch(setAvgStatistics(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    get,
    getStatistic,
    getAllStatistics,
    getCancelPercent,
    getAvgStatistics
  };
};

export default useGetOrders;
