const authMiddleware = () => (next) => async (action) => {
  const { error, payload } = action;

  if (error) {
    if (payload?.status === 401) {
      localStorage.removeItem('uvion_refresh_token')
      localStorage.removeItem('uvion_token_auth')
      localStorage.removeItem('token_expiration')
      localStorage.removeItem('uvion_role')
      setTimeout(() => {
        localStorage.removeItem('uvion_token_auth')
        localStorage.removeItem('token_expiration')
        localStorage.removeItem('uvion_role')
        // window.location.href = 'http://localhost:3000/login'
        window.location.href = 'https://trader.unitpay.io/login'
      }, 800)
    }
  }

  return next(action);
};

export default authMiddleware;
