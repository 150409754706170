import { useDispatch } from "react-redux";
import {useGetDevisesMutation, useLazyDeleteDeviseQuery} from "../../Store/Builder/uvion.api";
import {setDevisesData, setLoader} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";

const useDevises = () => {
  const dispatch = useDispatch();
  const [getDevises] = useGetDevisesMutation();
  const [deleteDevise] = useLazyDeleteDeviseQuery();
  const get = () => {
    dispatch(setLoader(true))
    getDevises()
      .unwrap()
      .then((res) => {
        dispatch(setDevisesData(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const deletedDevise = (data, closeDeleteModal) => {
    deleteDevise(data)
      .unwrap()
      .then(() => {
        get()
        showToast("Девайс успешно удален", "success");
        closeDeleteModal()
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  return {
    get,
    deletedDevise
  };
};

export default useDevises;


