import {useGetMessageIdMutation} from "../../Store/Builder/uvion.api";
import showToast from "../Hooks/toast";
import {useDispatch} from "react-redux";
import {setMessageData} from "../../Store/Slice/uvionSlice";

const useGetMessage = () => {
  const dispatch = useDispatch();
  const [getMessage] = useGetMessageIdMutation();

  const get = (body) => {
    getMessage(body)
      .unwrap()
      .then((res) => {
        dispatch(setMessageData(res))
      })
      .catch((err) => {
        showToast(err?.data?.message ?? "Error", "error");
      });
  }

  return {
    get,
  };
};

export default useGetMessage;
