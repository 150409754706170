import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const uvionApi = createApi({
  reducerPath: "uvion/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("uvion_token_auth");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("time", String(Date.now()));
      }

      return headers;
    },
  }),
  keepUnusedDataFor: 0.0001,
  endpoints: (build) => ({
    login: build.query({
      query: (body) => ({
        url: `/api/v1/auth/sign-in`,
        body,
        method: "POST",
      }),
    }),
    logout: build.query({
      query: (body) => ({
        url: `/api/v1/refresh-logout/sign-out`,
        body,
        method: "POST",
      }),
    }),
    createAd: build.query({
      query: (body) => ({
        url: `/api/v1/web/announcements/`,
        body,
        method: "POST",
      }),
    }),
    editAd: build.query({
      query: (body,) => ({
        url: `/api/v1/web/announcements/${body.id}`,
        body,
        method: "PUT",
      }),
    }),
    acceptOrder: build.mutation({
      query: (body) => ({
        url: `/api/v2/web/orders/${localStorage.getItem('order_id')}?status=${'success'}`,
        body,
        method: "PATCH",
      }),
    }),
    accountIsActive: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/profile/is-active`,
        body,
        method: "PATCH",
      }),
    }),
    rejectOrder: build.query({
      query: () => ({
        url: `/api/v2/web/orders/${localStorage.getItem('order_id')}?reason=${localStorage.getItem('reason')}&status=${'cancel'}`,
        method: "PATCH",
      }),
    }),
    inAppealOrderAccept: build.query({
      query: (body,) => ({
        url: `/api/v2/web/orders/arbitration/${localStorage.getItem('order_id')}?status=${'success'}`,
        body,
        method: "PATCH",
      }),
    }),
    inAppealOrderCancel: build.query({
      query: (body,) => ({
        url: `/api/v2/web/orders/arbitration/${localStorage.getItem('order_id')}?status=${'cancel'}`,
        body,
        method: "PATCH",
      }),
    }),
    deleteAd: build.query({
      query: (body) => ({
        url: `/api/v1/web/announcements/${body?.ad_id}`,
        method: "DELETE",
      }),
    }),
    deleteDevise: build.query({
      query: (body) => ({
        url: `/api/v1/web/devices/${body?.devise_id}`,
        method: "DELETE",
      }),
    }),
    makeAds: build.query({
      query: (body) => ({
        url: `make-ads`,
        body,
        method: "POST",
      }),
    }),
    getProfileInfo: build.query({
      query: () => ({
        url: `/api/v1/web/profile/info/`,
        method: "GET",
      }),
    }),
    getProfileAgentInfo: build.query({
      query: () => ({
        url: `/api/v1/agent/profile/info/`,
        method: "GET",
      }),
    }),
    getOrderDetails: build.query({
      query: (body) => ({
        url: `/api/v2/web/orders/${body?.order_id}`,
        method: "GET",
      }),
    }),
    getOrderBillings: build.query({
      query: (body) => ({
        url: `/api/v2/web/orders/billing/${body?.order_id}`,
        method: "GET",
      }),
    }),
    getOrdersActive: build.mutation({
      query: (params) => ({
        url: `/api/v2/web/orders/current-orders/?${params}`,
        method: "GET",
      }),
    }),
    getBanks: build.query({
      query: () => ({
        url: `/api/v1/web/payment-methods/?limit=100000&offset=0`,
        method: "GET",
      }),
    }),
    getAds: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/announcements/${params ? `?${params}` : ''}`,
        method: "GET",
      }),
    }),
    uploadFile: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/orders/recalculation/${localStorage.getItem('order_id')}`,
        body,
        method: "PATCH",
      }),
    }),
    getOrdersHistory: build.mutation({
      query: (params) => ({
        url: `/api/v2/web/orders/order-history/?${params}`,
        method: "GET",
      }),
    }),
    getOrdersAppeal: build.mutation({
      query: (params) => ({
        url: `/api/v2/web/orders/appeal-orders/?${params}`,
        method: "GET",
      }),
    }),
    getWalletBillings: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/crypto-wallets/billing/?${params}`,
        method: "GET",
      }),
    }),
    generateQrCode: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/crypto-wallets/create_qr_code/`,
        method: "POST",
      }),
    }),
    cryptoWallets: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/crypto-wallets/`,
        method: "GET",
      }),
    }),
    getMessageId: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/sms-messages/${params}`,
        method: "GET",
      }),
    }),
    getStatistics: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/statistics/orders/`,
        method: "GET",
      }),
    }),
    getDevises: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/devices/`,
        method: "GET",
      }),
    }),
    getOrdersHistoryId: build.mutation({
      query: (body) => ({
        url: `/api/v2/web/orders/search/${body}`,
        method: "GET",
      }),
    }),
    getOrdersStatistic: build.mutation({
      query: (params) => ({
        url: `/api/v2/web/orders/statistics/?${params}`,
        method: "GET",
      }),
    }),
    getCancelPercent: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/statistics/orders/common-cancel-percents/?${params}`,
        method: "GET",
      }),
    }),
    getAvgStatistics: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/statistics/orders/avg-completed/?${params}`,
        method: "GET",
      }),
    }),
    addMinLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/announcements/add-min-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    subMinLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/announcements/substract-min-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    addMaxLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/announcements/add-max-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    subMaxLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/announcements/substract-max-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    transferBalance: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/crypto-wallets/transfer-balance`,
        body,
        method: "PATCH",
      }),
    }),
    openAppealOrder: build.mutation({
      query: (body) => ({
        url: `/api/v2/web/orders/appeal/${body?.order_id}`,
        method: "PATCH",
      }),
    }),
    editMaxLimitPerHour: build.mutation({
      query: (body) => ({
        url: `/api/v1/web/announcements/change-order-amount-per-hour/${body.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    getTeams: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/users/?${params}`,
        method: "GET",
      }),
    }),
    getCurrencies: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/currencies/`,
        method: "GET",
      }),
    }),
    getKPI: build.mutation({
      query: (body) => ({
        url: `/api/v1/agent/users/traders-kpi/traders/${body?.currency_id}?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    getKPIStatistic: build.mutation({
      query: (body) => ({
        url: `/api/v1/agent/users/traders-kpi/statistic/${body?.trader_id}?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    getProfitTraders: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/statistics/traders/profit/${params.get('currency_id')}/?${params}`,
        method: "GET",
      }),
    }),
    getProfitMerchants: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/statistics/merchants/profit/${params.get('currency_id')}/?${params}`,
        method: "GET",
      }),
    }),
    getAvg: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/statistics/orders/traders/avg-completed/${params.get('trader_id')}/?${params}`,
        method: "GET",
      }),
    }),
    getPercent: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/statistics/orders/traders/percentage-success-orders/${params.get('trader_id')}/?${params}`,
        method: "GET",
      }),
    }),
    getTraderAmounts: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/statistics/orders/traders/total-amounts/${params.get('trader_id')}/?${params}`,
        method: "GET",
      }),
    }),
    getMerchantAmounts: build.mutation({
      query: (params) => ({
        url: `/api/v1/agent/statistics/orders/merchants/total-amounts/${params.get('currency_id')}/${params.get('merchant_id')}/?${params}`,
        method: "GET",
      }),
    }),
    getSmsHistory: build.mutation({
      query: (params) => ({
        url: `/api/v1/web/sms-history/?${params}`,
        method: "GET",
      }),
    }),
    isHighPriority: build.mutation ({
      query: (body) => ({
        url: `/api/v1/web/profile/is-highest-priority`,
        body,
        method: "PATCH",
      })
    }),
  }),
});

export const {
  useLazyLoginQuery,
  useGetMessageIdMutation,
  useLazyLogoutQuery,
  useLazyCreateAdQuery,
  useLazyEditAdQuery,
  useAcceptOrderMutation,
  useAccountIsActiveMutation,
  useLazyRejectOrderQuery,
  useLazyInAppealOrderCancelQuery,
  useLazyInAppealOrderAcceptQuery,
  useLazyDeleteAdQuery,
  useLazyDeleteDeviseQuery,
  useLazyMakeAdsQuery,
  useLazyTestQuery,
  useLazyGetProfileInfoQuery,
  useLazyGetProfileAgentInfoQuery,
  useGetOrdersActiveMutation,
  useGetAdsMutation,
  useLazyGetOrderDetailsQuery,
  useLazyGetOrderBillingsQuery,
  useLazyGetBanksQuery,
  useUploadFileMutation,
  useGetOrdersHistoryMutation,
  useGetOrdersHistoryIdMutation,
  useGetOrdersAppealMutation,
  useGetWalletBillingsMutation,
  useGetStatisticsMutation,
  useCryptoWalletsMutation,
  useGetDevisesMutation,
  useGenerateQrCodeMutation,
  useGetOrdersStatisticMutation,
  useAddMinLimitAdMutation,
  useSubMinLimitAdMutation,
  useAddMaxLimitAdMutation,
  useSubMaxLimitAdMutation,
  useEditMaxLimitPerHourMutation,
  useTransferBalanceMutation,
  useGetCancelPercentMutation,
  useGetAvgStatisticsMutation,
  useOpenAppealOrderMutation,
  useGetTeamsMutation,
  useGetCurrenciesMutation,
  useGetKPIMutation,
  useGetKPIStatisticMutation,
  useGetProfitTradersMutation,
  useGetProfitMerchantsMutation,
  useGetAvgMutation,
  useGetPercentMutation,
  useGetTraderAmountsMutation,
  useGetMerchantAmountsMutation,
  useGetSmsHistoryMutation,
  useIsHighPriorityMutation,
} = uvionApi;
