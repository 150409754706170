import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  profile_info: null,
  banks: [],
  smsData:[],
  selectedBanks: [],
  sellAds: [],
  ordersActive: [],
  ordersBuy: [],
  ordersAppeal: [],
  ordersActiveCount: 0,
  ordersBuyCount: 0,
  ordersAppealCount: 0,
  ordersHistory: [],
  devisesData: [],
  orderBillings: [],
  walletBillings: [],
  walletData: null,
  statisticsData: null,
  messageData: null,
  currentPage: 1,
  orderDetails: null,
  cryptoWallets: null,
  ordersStatistics: null,
  allOrdersStatistics: null,
  cancelPercent: null,
  avgStatistics: null,
  currentOrder: null,
  countLimit: 200,
  teams: [],
  currencies: [],
  kpiData: [],
  kpiStatisticData: null,
  kpiStatisticArray: [],
  profitTraders: null,
  profitMerchants: null,
  agentAvgStatistic: null,
  percentStatistic: null,
  traderAmountsStatistic: null,
  merchantAmountsStatistic: null,
};


const uvionSlice = createSlice({
  name: "uvion",
  initialState,
  reducers: {
    setLoader(state, action) {
      state.loader = action.payload;
    },
    setProfileInfo(state, action) {
      state.profile_info = action?.payload
    },
    setBanks(state, action) {
      state.banks = action?.payload
    },
    setSelectedBanks(state, action) {
      const idToAdd = action?.payload;
      if (state.selectedBanks.includes(idToAdd)) {
        state.selectedBanks = state.selectedBanks.filter(id => id !== idToAdd);
      } else {
        state.selectedBanks.push(idToAdd);
      }
    },

    setSelectedBanksClear(state, action) {
      state.selectedBanks = action?.payload
    },
    setSellAds(state, action) {
      const uniqueOrders = action?.payload.filter((newOrder) => !state.sellAds.some((existingOrder) => existingOrder.created_at === newOrder.created_at));
      state.sellAds = [...state.sellAds, ...uniqueOrders];
    },
    setSellAdsEmpty(state, action) {
      state.sellAds = action?.payload;
    },
    setOrdersActive(state, action) {
      const uniqueOrders = action?.payload.filter((newOrder) => !state.ordersActive.some((existingOrder) => existingOrder.created_at === newOrder.created_at));
      state.ordersActive = [...state.ordersActive, ...uniqueOrders];
    },
    setDeleteOrder(state, action) {
      const deletedOrderId = action?.payload;

      if (deletedOrderId) {
        state.ordersActive = state.ordersActive.filter(order => order.id !== deletedOrderId);
      }
    },
    setDeleteBuy(state, action) {
      const deletedOrderId = action?.payload;

      if (deletedOrderId) {
        state.ordersBuy = state.ordersBuy.filter(order => order.id !== deletedOrderId);
      }
    },
    setDeleteAd(state, action) {
      const deletedAdId = action?.payload;

      if (deletedAdId) {
        state.sellAds = state.sellAds.filter(ad => ad.id !== deletedAdId);
      }
    },
    setDeleteAppeal(state, action) {
      const deletedAdId = action?.payload;

      if (deletedAdId) {
        state.ordersAppeal = state.ordersAppeal.filter(order => order.id !== deletedAdId);
      }
    },
    setDeleteBuyId(state, action) {
      const deletedAdId = action?.payload;

      if (deletedAdId) {
        state.ordersBuy = state.ordersBuy.filter(order => order.id !== deletedAdId);
      }
    },
    setDeleteSellId(state, action) {
      const deletedAdId = action?.payload;

      if (deletedAdId) {
        state.ordersActive = state.ordersActive.filter(order => order.id !== deletedAdId);
      }
    },
    setToggleAd(state, action) {
      const targetId = action?.payload;

      if (targetId) {
        state.sellAds = state.sellAds.map(ad => {
          if (ad.id === targetId) {
            return {...ad, is_active: !ad.is_active};
          } else {
            return ad;
          }
        });
      }
    },
    setEditAd(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.sellAds = state.sellAds.map(ad => {
          if (ad.id === targetId) {
            return {
              ...ad,
              id: action?.payload?.id,
              type: action?.payload?.type,
              card_holder: action?.payload?.card_holder,
              card_number: action?.payload?.card_number,
              cvv: action?.payload?.cvv,
              month: action?.payload?.month,
              year: action?.payload?.year,
              phone_number: action?.payload?.phone_number,
              device_id: action?.payload?.device_id,
              comment: action?.payload?.comment,
              payment_method_id: action?.payload?.payment_method_id,
              currency_id: action?.payload?.currency_id,
              is_active: action?.payload?.is_active,
              max_order_amount: action?.payload?.max_order_amount,
            };
          } else {
            return ad;
          }
        });
      }
    },
    setEditAdLimit(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.sellAds = state.sellAds.map(ad => {
          if (ad.id === targetId) {
            return {
              ...ad,
              id: action?.payload?.id,
              type: action?.payload?.type,
              card_holder: action?.payload?.card_holder,
              card_number: action?.payload?.card_number,
              max_amount: action?.payload?.max_amount,
              min_amount:action?.payload?.min_amount,
              cvv: action?.payload?.cvv,
              month: action?.payload?.month,
              year: action?.payload?.year,
              phone_number: action?.payload?.phone_number,
              device_id: action?.payload?.device_id,
              comment: action?.payload?.comment,
              payment_method_id: action?.payload?.payment_method_id,
              currency_id: action?.payload?.currency_id,
              is_active: action?.payload?.is_active,
              max_order_amount: action?.payload?.max_order_amount,
              max_order_amount_per_hour: action?.payload?.max_order_amount_per_hour
            };
          } else {
            return ad;
          }
        });
      }
    },
    setNewOrderActive(state, action) {
      const uniqueOrders = action?.payload?.buy_data?.filter((newOrder) => !state.ordersActive.some((existingOrder) => existingOrder.id === newOrder.id));
      state.ordersActive = [...state.ordersActive, ...uniqueOrders,];
    },
    // setNewOrderBuy(state, action) {
    //   const uniqueOrders = action?.payload?.sell_data?.filter((newOrder) => !state.ordersBuy.some((existingOrder) => existingOrder.id === newOrder.id));
    //   state.ordersBuy = [...state.ordersBuy, ...uniqueOrders];
    // },
    setNewOrderBuy(state, action) {
      const newOrders = action?.payload?.sell_data || [];

      // Создаем новый массив для хранения обновленных или добавленных заявок
      const updatedOrdersBuy = state.ordersBuy.map(existingOrder => {
        const newOrder = newOrders.find(order => order.id === existingOrder.id);
        return newOrder ? newOrder : existingOrder;
      });

      // Фильтруем новые заявки, которые еще не существуют в массиве
      const uniqueNewOrders = newOrders.filter(newOrder => !state.ordersBuy.some(existingOrder => existingOrder.id === newOrder.id));

      // Объединяем обновленные заявки с новыми уникальными заявками
      const combinedOrders = [...updatedOrdersBuy, ...uniqueNewOrders];

      // Создаем окончательный массив без дубликатов
      const ordersBuyWithoutDuplicates = [];
      const seenIds = new Set();

      for (const order of combinedOrders) {
        if (!seenIds.has(order.id)) {
          ordersBuyWithoutDuplicates.push(order);
          seenIds.add(order.id);
        }
      }

      state.ordersBuy = ordersBuyWithoutDuplicates;
    },
    // setNewOrderAppeal(state, action) {
    //   const newOrders = action?.payload?.appeal_data || [];
    //
    //   // Создаем новый массив для хранения обновленных или добавленных заявок
    //   const updatedOrdersAppeal = state.ordersAppeal.map(existingOrder => {
    //     const newOrder = newOrders.find(order => order.id === existingOrder.id);
    //     return newOrder ? newOrder : existingOrder;
    //   });
    //
    //   // Фильтруем новые заявки, которые еще не существуют в массиве
    //   const uniqueNewOrders = newOrders.filter(newOrder => !state.ordersAppeal.some(existingOrder => existingOrder.id === newOrder.id));
    //
    //   // Объединяем обновленные заявки с новыми уникальными заявками
    //   state.ordersAppeal = [...updatedOrdersAppeal, ...uniqueNewOrders];
    // },
    setNewOrderAppeal(state, action) {
      const newOrders = action?.payload?.appeal_data || [];

      // Создаем новый массив для хранения обновленных или добавленных заявок
      const updatedOrdersAppeal = state.ordersAppeal.map(existingOrder => {
        const newOrder = newOrders.find(order => order.id === existingOrder.id);
        return newOrder ? newOrder : existingOrder;
      });

      // Фильтруем новые заявки, которые еще не существуют в массиве
      const uniqueNewOrders = newOrders.filter(newOrder => !state.ordersAppeal.some(existingOrder => existingOrder.id === newOrder.id));

      // Объединяем обновленные заявки с новыми уникальными заявками
      const combinedOrders = [...updatedOrdersAppeal, ...uniqueNewOrders];

      // Создаем окончательный массив без дубликатов
      const ordersAppealWithoutDuplicates = [];
      const seenIds = new Set();

      for (const order of combinedOrders) {
        if (!seenIds.has(order.id)) {
          ordersAppealWithoutDuplicates.push(order);
          seenIds.add(order.id);
        }
      }

      state.ordersAppeal = ordersAppealWithoutDuplicates;
    },
    setOrdersActiveCount(state, action) {
      state.ordersActiveCount = action?.payload
    },
    setOrdersActiveCountNull(state) {
      state.ordersActiveCount = 0
    },
    setOrdersBuyCount(state, action) {
      state.ordersBuyCount = action?.payload
    },
    setOrdersBuyCountNull(state) {
      state.ordersBuyCount = 0
    },
    setOrdersAppealCount(state, action) {
      state.ordersAppealCount = action?.payload
    },
    setOrdersAppealCountNull(state) {
      state.ordersAppealCount = 0
    },
    setOrdersBuy(state, action) {
      // Получаем новые заказы из payload
      const newOrders = action?.payload;

      // Объединяем старые и новые заказы
      const combinedOrders = [...state.ordersBuy, ...newOrders];

      // Удаляем дубликаты по id
      const uniqueOrders = [];
      const seenIds = new Set();

      for (const order of combinedOrders) {
        if (!seenIds.has(order.id)) {
          uniqueOrders.push(order);
          seenIds.add(order.id);
        }
      }

      // Обновляем состояние ordersBuy без дубликатов
      state.ordersBuy = uniqueOrders;
    },
    setOrdersAppeal(state, action) {
      const uniqueOrders = action?.payload.filter((newOrder) => !state.ordersAppeal.some((existingOrder) => existingOrder.id === newOrder.id));
      state.ordersAppeal = [...state.ordersAppeal, ...uniqueOrders];
    },
    setOrderDetails(state, action) {
      state.orderDetails = action?.payload
    },
    setOrderBillings(state, action) {
      state.orderBillings = action?.payload
    },
    setOrdersHistory(state, action) {
      state.ordersHistory = action?.payload
    },
    setDeleteOrderInHistory(state, action) {
      const idToRemove = action.payload?.id;
      state.ordersHistory = state.ordersHistory.filter(item => item.id !== idToRemove);
    },
    setOrdersHistoryPagination(state, action) {
      const uniqueOrders = action?.payload.filter((newOrder) => !state.ordersHistory.some((existingOrder) => existingOrder.id === newOrder.id));
      state.ordersHistory = [...state.ordersHistory, ...uniqueOrders];
    },
    setOrdersHistoryEmpty(state, action) {
      state.ordersHistory = action?.payload;
    },
    setCurrentPage2(state, action) {
      state.currentPage = action?.payload;
    },
    setStatisticsData(state, action) {
      state.statisticsData = action?.payload
    },
    setWalletData(state, action) {
      state.walletData = action?.payload
    },
    setMessageData(state, action) {
      state.messageData = action?.payload
    },
    setWalletBillings(state, action) {
      const uniqueOrders = action?.payload.filter((newOrder) => !state.walletBillings.some((existingOrder) => existingOrder.id === newOrder.id));
      state.walletBillings = [...state.walletBillings, ...uniqueOrders];
    },
    setWalletBillingsEmpty(state, action) {
      state.walletBillings = action?.payload
    },
    setOrdersActiveEmpty(state, action) {
      state.ordersActive = action?.payload
    },
    setOrdersBuyEmpty(state, action) {
      state.ordersBuy = action?.payload
    },
    setOrdersAppealEmpty(state, action) {
      state.ordersAppeal = action?.payload
    },
    setDevisesData(state, action) {
      const updatedPayload = action?.payload.map(item => ({
        ...item,
        label: `${item.name} ${item?.phone_number !== null ? `(${item?.phone_number})` : '(PUSH)'}`,
        value: item.id
      }));
      state.devisesData = updatedPayload;
    },
    setProfileData(state, action) {
      state.cryptoWallets.qr_code_path = action.payload;
    },
    setCryptoWallets(state, action) {
      state.cryptoWallets = action?.payload
    },
    setOrdersStatistics(state, action) {
      state.ordersStatistics = action?.payload
    },
    setAllOrdersStatistics(state, action) {
      state.allOrdersStatistics = action?.payload
    },
    setCancelPercent(state, action) {
      state.cancelPercent = action?.payload
    },
    setAvgStatistics(state, action) {
      state.avgStatistics = action?.payload
    },
    setOrdersStatisticsCount(state, action) {
      state.ordersStatistics.count_orders = action?.payload
    },
    setOrdersStatisticsFiat(state, action) {
      state.ordersStatistics.common_amount = action?.payload
    },
    setOrdersStatisticsUSDT(state, action) {
      state.ordersStatistics.common_amount_usdt = action?.payload
    },
    setOrdersStatisticsKeys(state, action) {
      const { count_orders, common_amount, common_amount_usdt } = action.payload;
      if (count_orders !== undefined) {
        state.ordersStatistics.count_orders = count_orders;
      }
      if (common_amount !== undefined) {
        state.ordersStatistics.common_amount = common_amount;
      }
      if (common_amount_usdt !== undefined) {
        state.ordersStatistics.common_amount_usdt = common_amount_usdt;
      }
    },
    setCurrentOrder(state, action) {
      state.currentOrder = action?.payload
    },
    setCountLimit(state, action) {
      state.countLimit = action?.payload
    },
    setTeams(state, action) {
      // Получаем новые заказы из payload
      const newTeams = action?.payload;

      // Объединяем старые и новые заказы
      const combinedTeams = [...state.teams, ...newTeams];

      // Удаляем дубликаты по id
      const uniqueTeams = [];
      const seenIds = new Set();

      for (const team of combinedTeams) {
        if (!seenIds.has(team.id)) {
          uniqueTeams.push(team);
          seenIds.add(team.id);
        }
      }

      // Обновляем состояние ordersBuy без дубликатов
      state.teams = uniqueTeams;
    },
    setCurrencies(state, action) {
      state.currencies = action?.payload
    },
    setKpiData(state, action) {
      // Получаем новые заказы из payload
      const newKpiData = action?.payload;

      // Объединяем старые и новые заказы
      const combinedKpiData = [...state.kpiData, ...newKpiData];

      // Удаляем дубликаты по id
      const uniqueKpiData = [];
      const seenIds = new Set();

      for (const kpi of combinedKpiData) {
        if (!seenIds.has(kpi.id)) {
          uniqueKpiData.push(kpi);
          seenIds.add(kpi.id);
        }
      }

      // Обновляем состояние ordersBuy без дубликатов
      state.kpiData = uniqueKpiData;
    },
    setKpiEmpty(state, action) {
      state.kpiData = action?.payload
    },
    setKpiStatisticData(state, action) {
      state.kpiStatisticData = action?.payload
    },
    setKpiStatisticArray(state, action) {
      const newKpiData = action?.payload;

      // Объединяем старые и новые заказы
      const combinedKpiData = [...state.kpiStatisticArray, ...newKpiData];

      // Удаляем дубликаты по id
      const uniqueKpiData = [];
      const seenIds = new Set();

      for (const kpi of combinedKpiData) {
        if (!seenIds.has(kpi.id)) {
          uniqueKpiData.push(kpi);
          seenIds.add(kpi.id);
        }
      }

      // Обновляем состояние ordersBuy без дубликатов
      state.kpiStatisticArray = uniqueKpiData;
    },
    setProfitTraders(state, action) {
      state.profitTraders = action?.payload
    },
    setProfitMerchants(state, action) {
      state.profitMerchants = action?.payload
    },
    setAgentAvgStatistic(state, action) {
      state.agentAvgStatistic = action?.payload
    },
    setPercentStatistic(state, action) {
      state.percentStatistic = action?.payload
    },
    setTraderAmountsStatistic(state, action) {
      state.traderAmountsStatistic = action?.payload
    },
    setMerchantAmountsStatistic(state, action) {
      state.merchantAmountsStatistic = action?.payload
    },
    setSmsHistory(state, action) {
      const newSmsData = action?.payload || [];

      // Проверка на дубликаты и добавление новых данных
      const combinedSmsData = [...state.smsData, ...newSmsData];
      const uniqueSmsData = [];
      const seenIds = new Set();

      for (const sms of combinedSmsData) {
        if (!seenIds.has(sms.id)) {
          uniqueSmsData.push(sms);
          seenIds.add(sms.id);
        }
      }

      state.smsData = uniqueSmsData;
    },
    setSmsHistoryEmpty(state, action) {
      state.smsData = action?.payload;
    },
    setIsHighPriority(state, action) {
      state.profile_info = action?.payload
    }
  },
});

export const {
  setCountLimit,
  setLoader,
  setProfileInfo,
  setBanks,
  setSelectedBanks,
  setSellAds,
  setOrdersActive,
  setOrderDetails,
  setSelectedBanksClear,
  setOrdersBuy,
  setOrdersAppeal,
  setOrdersHistory,
  setCurrentPage2,
  setNewOrderActive,
  setNewOrderBuy,
  setOrdersActiveCount,
  setOrdersBuyCount,
  setOrdersAppealCount,
  setOrdersActiveCountNull,
  setOrdersBuyCountNull,
  setOrdersAppealCountNull,
  setOrdersHistoryEmpty,
  setNewOrderAppeal,
  setSellAdsEmpty,
  setStatisticsData,
  setDevisesData,
  setDeleteAd,
  setToggleAd,
  setEditAd,
  setDeleteOrder,
  setDeleteBuy,
  setOrderBillings,
  setWalletData,
  setWalletBillings,
  setOrdersHistoryPagination,
  setMessageData,
  setDeleteAppeal,
  setDeleteBuyId,
  setDeleteSellId,
  setOrdersActiveEmpty,
  setOrdersBuyEmpty,
  setOrdersAppealEmpty,
  setProfileData,
  setCryptoWallets,
  setWalletBillingsEmpty,
  setOrdersStatistics,
  setOrdersStatisticsKeys,
  setOrdersStatisticsCount,
  setOrdersStatisticsFiat,
  setEditAdLimit,
  setAllOrdersStatistics,
  setCancelPercent,
  setAvgStatistics,
  setDeleteOrderInHistory,
  setCurrentOrder,
  setTeams,
  setCurrencies,
  setKpiData,
  setKpiEmpty,
  setKpiStatisticData,
  setKpiStatisticArray,
  setProfitTraders,
  setProfitMerchants,
  setAgentAvgStatistic,
  setPercentStatistic,
  setTraderAmountsStatistic,
  setMerchantAmountsStatistic,
  setOrdersStatisticsUSDT,
  setSmsHistory,
  setSmsHistoryEmpty,
  setIsHighPriority,
} = uvionSlice.actions;

export default uvionSlice.reducer;
