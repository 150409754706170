import {useDispatch} from "react-redux";
import {
  setLoader,
  setTeams,
} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";
import {useGetTeamsMutation} from "../../Store/Builder/uvion.api";

const useTeams = () => {
  const dispatch = useDispatch();
  const [getTeams] = useGetTeamsMutation();

  const get = (params) => {
    dispatch(setLoader(true))
    getTeams(params)
      .unwrap()
      .then((res) => {
        dispatch(setTeams(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    get,
  };
};

export default useTeams;


