import { useState } from "react";

const useInputHandler = (initialValue = "") => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const setChange = (text) => {
    setValue(text);
  };

  return {
    value,
    setChange,
    handleChange: handleChange,
  };
};

export default useInputHandler;
