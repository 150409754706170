import React, {useEffect, useRef, useState} from 'react';
import {CustomSelect} from "../../UI/Select";
import DatePicker from "rsuite/DatePicker";
import Button from "../../UI/Button";
import {useDispatch, useSelector} from "react-redux";
import useDevises from "../../Global/Api/devises.api";
import useSmsHistory from "../../Global/Api/smsHistory.api";
import "./index.scss"
import moment from "moment";
import {setSmsHistoryEmpty} from "../../Store/Slice/uvionSlice";
import {DateRangePicker} from "rsuite";
import {FaClock} from "react-icons/fa";
import Popup from "../../Components/Popup";

const SmsHistory = () => {
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [timeValue, setTimeValue] = useState(null);
  const lastCreatedAt = useRef(null);
  const getDevises = useDevises();
  const limitCount = 200;
  const getSmsHistory = useSmsHistory();

  const devisesData = useSelector(
    (state) => state.rootReducer.uvionSlice.devisesData
  );

  const smsHistoryData = useSelector(
    (state) => state.rootReducer.uvionSlice.smsData
  )

  useEffect(() => {
    getDevises.get()
  }, [])

  useEffect(() => {
    const formattedParams = formatParams();
    getSmsHistory.get(formattedParams)
  }, [])

  function extractTime(datetimeStr) {
    return moment(datetimeStr).format('HH:mm:ss');
  }

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    params.append('pagination_order', 'end');
    if (dateFrom !== null) {
      if (timeValue !== null) {
        let newArray
        newArray = timeValue?.map(extractTime)
        params.append('date_from', moment(dateFrom.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[0]}`)
      }
    }
    if (dateTo !== null) {
      if (timeValue !== null) {
        let newArray
        newArray = timeValue?.map(extractTime)
        params.append('date_to', moment(dateTo.toString()).format('YYYY-MM-DD') + 'T' + `${newArray[1]}`)
      }
    }
    if (selectedDevice !== null) params.append('device_id', selectedDevice?.value);
    if (selectedStatus !== null) params.append('sms_types', selectedStatus?.value);
    if (lastCreatedAt.current !== null) params.append('last_created_at', lastCreatedAt.current);
    return params
  }

  const getData = () => {
    dispatch(setSmsHistoryEmpty([]))
    const formattedParams = formatParams();
    getSmsHistory.get(formattedParams)
    closeFilterModal()
  }

  const loadMore = () => {
    lastCreatedAt.current = smsHistoryData[smsHistoryData.length - 1]?.created_at;
    const formattedParams = formatParams();
    getSmsHistory.get(formattedParams);
  }

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const closeFilterModal = () => {
    setFilterModal(false)
  }

  const openFilterModal = () => {
    setFilterModal(true)
  }


  return (
    <div className="smsHistory">
      {filterModal && (
        <Popup title='Фильтр' closePopup={closeFilterModal}>
          <div className="history__filter-modal-wrapper">
            <p className="history__filter-modal-text">Настройте фильтр под себя</p>
            <div className="history__filter-modal-fields">
              <div className="history__filter-modal-calendar">
                <DatePicker value={dateFrom} onChange={setDateFrom} locale={{ok: 'Применить'}}
                            className='filter-calendar' placeholder='От'
                            format="MM/dd/yyyy"/>
              </div>
              <div className="history__filter-modal-calendar">
                <DatePicker value={dateTo} onChange={setDateTo} locale={{ok: 'Применить'}}
                            className='filter-calendar'
                            placeholder='До'
                            format="MM/dd/yyyy"/>
              </div>
              <div className="history__filter-modal-calendar">
                <DateRangePicker
                  format="HH:mm:ss"
                  caretAs={FaClock}
                  value={timeValue}
                  onChange={setTimeValue}
                  locale={{ok: 'Применить'}}
                  placeholder={'Выберите время'}/>
              </div>
            </div>
            <p className="profile__box-filter-subtitle">Подсчет времени ведется в формате UTC+0</p>
            <div className="history__filter-modal-select">
              <CustomSelect
                options={devisesData}
                selected={selectedDevice}
                placeholder='Девайс'
                isSearchable={false}
                handleSelect={(e) => {
                  setSelectedDevice(e ?? null)
                }}
                isClearable={true}
              />
            </div>
            <div className="history__filter-modal-select">
              <CustomSelect
                options={[
                  {value: 'failed', label: 'Ошибка'},
                  {value: 'success', label: 'Успех'},
                ]}
                selected={selectedStatus}
                placeholder='Тип смс'
                isSearchable={false}
                handleSelect={(e) => {
                  setSelectedStatus(e ?? null)
                }}
                isClearable={true}
              />
            </div>
            <div className="history__filter-modal-btns">
              <div className="history__filter-modal-btn">
                <Button
                  text='Применить'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  disabled={(dateFrom !== null || dateTo !== null) && timeValue === null ? true : false}
                  onClick={getData}
                />
              </div>
              <div className="history__filter-modal-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeFilterModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      <div className="smsHistory__title">
        <p className="automatic__title">История СМС сообщений</p>
        <div onClick={() => {
          openFilterModal()
        }} className="history__filter">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.56517 3C3.70108 3 3 3.71286 3 4.5904V5.52644C3 6.17647 3.24719 6.80158 3.68936 7.27177L8.5351 12.4243L8.53723 12.4211C9.47271 13.3788 9.99905 14.6734 9.99905 16.0233V20.5952C9.99905 20.9007 10.3187 21.0957 10.584 20.9516L13.3436 19.4479C13.7602 19.2204 14.0201 18.7784 14.0201 18.2984V16.0114C14.0201 14.6691 14.539 13.3799 15.466 12.4243L20.3117 7.27177C20.7528 6.80158 21 6.17647 21 5.52644V4.5904C21 3.71286 20.3 3 19.4359 3H4.56517Z"
                  stroke="#525252" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
      <div className="smsHistory__table-container">
        <div className="smsHistory__table">
          <div className="smsHistory__table-top">
            <div className="smsHistory__table-head">
              <p>Дата</p>
            </div>
            <div className="smsHistory__table-head">
              <p>Версия андроида</p>
            </div>
            <div className="smsHistory__table-head">
              <p>Девайс</p>
            </div>
            <div className="smsHistory__table-head">
              <p>Сообщение</p>
            </div>
            <div className="smsHistory__table-head">
              <p>Тип</p>
            </div>
          </div>
          <div className="smsHistory__table-list">
            {smsHistoryData?.map((item) => {
              return (
                <div key={item.id}
                     className={`smsHistory__table-item ${item?.type_history === 'failed' ? 'smsHistory__table-item_error' : ''}`}>
                  <p>
                    {formatDate(item.created_at)}
                  </p>
                  <p>
                    {item.android_version}
                  </p>
                  <p>
                    {item.device_name}
                  </p>
                  <p>
                    {item.message}
                  </p>
                  <p>
                    {item.type_history === 'failed' ? 'Ошибка' : 'Успех'}
                  </p>
                </div>
              )
            })}

          </div>
        </div>
      </div>
      {smsHistoryData?.length >= limitCount && (
        <div className="loadMore">
          <p onClick={() => {
            loadMore()
          }}>
            Загрузить еще...
          </p>
          <span>
            <img src="/assets/icons/arrow-down.svg" alt=""/>
          </span>
        </div>
      )}
    </div>
  );
};

export default SmsHistory;
