import {useDispatch} from "react-redux";
import showToast from "../Hooks/toast";
import {
  setDeleteAppeal,
  setDeleteBuyId, setDeleteSellId,
  setLoader, setOrderBillings,
  setOrderDetails,
  setDeleteOrderInHistory
} from "../../Store/Slice/uvionSlice";
import {
  useAcceptOrderMutation,
  useLazyGetOrderBillingsQuery,
  useLazyGetOrderDetailsQuery,
  useLazyInAppealOrderAcceptQuery,
  useLazyInAppealOrderCancelQuery,
  useLazyRejectOrderQuery,
  useOpenAppealOrderMutation
} from "../../Store/Builder/uvion.api";
import useGetOrdersAppeal from "./getOrdersAppeal.api";
import useGetOrders from "./getOrders.api";
import {useLocation} from "react-router-dom";
import useGetMessage from "./getMessage.api";

const useOrder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [getOrderDetails] = useLazyGetOrderDetailsQuery();
  const [acceptOrderInAppealPost] = useLazyInAppealOrderAcceptQuery();
  const [cancelOrderInAppealPost] = useLazyInAppealOrderCancelQuery();
  const [acceptOrderPost] = useAcceptOrderMutation();
  const [apiOpenAppealOrder] = useOpenAppealOrderMutation();
  const [getOrderBillings] = useLazyGetOrderBillingsQuery();
  const [rejectOrderPost] = useLazyRejectOrderQuery();
  const getAppeals = useGetOrdersAppeal();
  const getOrders = useGetOrders();
  const apiGetMessage = useGetMessage();

  const rejectOrder = (data, closeRejectModal, infoData) => {
    dispatch(setLoader(true));

    rejectOrderPost()
      .unwrap()
      .then((res) => {
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          params.append('order_type', location?.pathname === '/' ? 'buy' : 'sell');
          if (infoData?.searchId.length > 0) params.append('last_time_to_end', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        const formatParamsAppeal = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          if (infoData?.searchId.length > 0) params.append('order_id', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        showToast("Заявка успешно отменена", "success");
        closeRejectModal();
        if(location?.pathname === `/order/${data?.order_id}`) {
          get(data)
        } else if(location?.pathname === '/orders-appeal') {
          getAppeals.get(formatParamsAppeal());
          dispatch(setDeleteAppeal(res?.id))
        } else {
          getOrders.get(formatParams())
        }
        if(location?.pathname === '/') {
          dispatch(setDeleteSellId(res?.id))
        }
        if(location?.pathname === '/buy') {
          dispatch(setDeleteBuyId(res?.id))
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const acceptOrder = (data, closeAcceptModal, infoData, file) => {
    dispatch(setLoader(true));

    let formData = new FormData()
    if(file) {
      formData.append('receipt', file)
    }
    if(file) {
      acceptOrderPost(formData)
        .unwrap()
        .then((res) => {
          const formatParams = () => {
            const params = new URLSearchParams();
            params.append('limit', infoData?.limitCount);
            params.append('order_type', location?.pathname === '/' ? 'buy' : 'sell');
            if (infoData?.searchId.length > 0) params.append('last_time_to_end', infoData?.searchId?.replaceAll(' ', ''));
            return params
          }
          const formatParamsAppeal = () => {
            const params = new URLSearchParams();
            params.append('limit', infoData?.limitCount);
            if (infoData?.searchId.length > 0) params.append('order_id', infoData?.searchId?.replaceAll(' ', ''));
            return params
          }
          showToast("Заявка успешно принята", "success");
          closeAcceptModal();
          if(location?.pathname === `/order/${data?.order_id}`) {
            get(data)
          } else if(location?.pathname === '/orders-appeal') {
            getAppeals.get(formatParamsAppeal());
            dispatch(setDeleteAppeal(res?.id))
          } else {
            getOrders.get(formatParams())
          }

          if(location?.pathname === '/') {
            dispatch(setDeleteSellId(res?.id))
          }
          if(location?.pathname === '/buy') {
            dispatch(setDeleteBuyId(res?.id))
          }
          dispatch(setLoader(false));
        })
        .catch((err) => {
          dispatch(setLoader(false));
          showToast(err?.data?.message ?? "Error", "error");
        });
    } else {
      acceptOrderPost()
        .unwrap()
        .then((res) => {
          const formatParams = () => {
            const params = new URLSearchParams();
            params.append('limit', infoData?.limitCount);
            params.append('order_type', location?.pathname === '/' ? 'buy' : 'sell');
            if (infoData?.searchId.length > 0) params.append('last_time_to_end', infoData?.searchId?.replaceAll(' ', ''));
            return params
          }
          const formatParamsAppeal = () => {
            const params = new URLSearchParams();
            params.append('limit', infoData?.limitCount);
            if (infoData?.searchId.length > 0) params.append('order_id', infoData?.searchId?.replaceAll(' ', ''));
            return params
          }
          showToast("Заявка успешно принята", "success");
          closeAcceptModal();
          if(location?.pathname === `/order/${data?.order_id}`) {
            get(data)
          } else if(location?.pathname === '/orders-appeal') {
            getAppeals.get(formatParamsAppeal());
            dispatch(setDeleteAppeal(res?.id))
          } else {
            getOrders.get(formatParams())
          }
          if(location?.pathname === '/') {
            dispatch(setDeleteSellId(res?.id))
          }
          if(location?.pathname === '/buy') {
            dispatch(setDeleteBuyId(res?.id))
          }
          dispatch(setLoader(false));
        })
        .catch((err) => {
          dispatch(setLoader(false));
          showToast(err?.data?.message ?? "Error", "error");
        });
    }
  };

  const openAppealOrder = (body, closeAppealModal) => {
    dispatch(setLoader(true))
    apiOpenAppealOrder(body)
      .unwrap()
      .then((res) => {
        dispatch(setDeleteOrderInHistory(res))
        dispatch(setLoader(false))
        closeAppealModal()
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  }

  const get = (body) => {
    dispatch(setLoader(true))
    getOrderDetails(body)
      .unwrap()
      .then((res) => {
        dispatch(setOrderDetails(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  }

  const acceptOrderInAppeal = (data, closeAcceptModal, infoData, file) => {
    dispatch(setLoader(true));
    let formData = new FormData()
    formData.append('receipt', file)

    acceptOrderInAppealPost(formData)
      .unwrap()
      .then((res) => {
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          params.append('order_type', location?.pathname === '/' ? 'buy' : 'sell');
          if (infoData?.searchId.length > 0) params.append('last_time_to_end', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        const formatParamsAppeal = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          if (infoData?.searchId.length > 0) params.append('order_id', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        showToast("Заявка успешно исполнена", "success");
        closeAcceptModal();
        if (location?.pathname === `/order/${data?.order_id}`) {
          get(data)
        } else if (location?.pathname === '/orders-appeal') {
          getAppeals.get(formatParamsAppeal());
          dispatch(setDeleteAppeal(res?.id))
        } else {
          getOrders.get(formatParams())
        }
        if (location?.pathname === '/') {
          dispatch(setDeleteSellId(res?.id))
        }
        if (location?.pathname === '/buy') {
          dispatch(setDeleteBuyId(res?.id))
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  }

  const rejectOrderInAppeal = (data, closeRejectModal, infoData, file) => {
    dispatch(setLoader(true));
    let formData = new FormData()
    formData.append('receipt', file)

    cancelOrderInAppealPost(formData)
      .unwrap()
      .then((res) => {
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          params.append('order_type', location?.pathname === '/' ? 'buy' : 'sell');
          if (infoData?.searchId.length > 0) params.append('last_time_to_end', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        const formatParamsAppeal = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          if (infoData?.searchId.length > 0) params.append('order_id', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        showToast("Заявка успешно отменена", "success");
        closeRejectModal();
        if (location?.pathname === `/order/${data?.order_id}`) {
          get(data)
        } else if (location?.pathname === '/orders-appeal') {
          getAppeals.get(formatParamsAppeal());
          dispatch(setDeleteAppeal(res?.id))
        } else {
          getOrders.get(formatParams())
        }
        if (location?.pathname === '/') {
          dispatch(setDeleteSellId(res?.id))
        }
        if (location?.pathname === '/buy') {
          dispatch(setDeleteBuyId(res?.id))
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  }

  const acceptOrderInAppealBuy = (data, closeAcceptModal, infoData, amount) => {
    dispatch(setLoader(true));
    let formData = new FormData()
    formData.append('amount', amount)

    acceptOrderInAppealPost(formData)
      .unwrap()
      .then((res) => {
        const formatParams = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          params.append('order_type', location?.pathname === '/' ? 'buy' : 'sell');
          if (infoData?.searchId.length > 0) params.append('last_time_to_end', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        const formatParamsAppeal = () => {
          const params = new URLSearchParams();
          params.append('limit', infoData?.limitCount);
          if (infoData?.searchId.length > 0) params.append('order_id', infoData?.searchId?.replaceAll(' ', ''));
          return params
        }
        showToast("Заявка успешно исполнена", "success");
        closeAcceptModal();
        if (location?.pathname === `/order/${data?.order_id}`) {
          get(data)
        } else if (location?.pathname === '/orders-appeal') {
          getAppeals.get(formatParamsAppeal());
          dispatch(setDeleteAppeal(res?.id))
        } else {
          getOrders.get(formatParams())
        }
        if (location?.pathname === '/') {
          dispatch(setDeleteSellId(res?.id))
        }
        if (location?.pathname === '/buy') {
          dispatch(setDeleteBuyId(res?.id))
        }
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        showToast(err?.data?.message ?? "Error", "error");
      });
  }

  const getBillings = (body) => {
    dispatch(setLoader(true))
    getOrderBillings(body)
      .unwrap()
      .then((res) => {
        dispatch(setOrderBillings(res?.order_billings))
        dispatch(setLoader(false))
        for (let i = 0; res.order_billings?.length > i; i++) {
          if (res?.order_billings[i]?.sms_message_id !== null && res?.order_billings[i]?.order_stage === 'success') {
            apiGetMessage.get(res.order_billings[i]?.sms_message_id)
          }
        }
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  }


  return {
    get,
    acceptOrderInAppeal,
    rejectOrderInAppeal,
    acceptOrderInAppealBuy,
    getBillings,
    acceptOrder,
    rejectOrder,
    openAppealOrder
  };
};

export default useOrder
