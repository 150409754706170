import {useDispatch} from "react-redux";
import {
  setKpiData, setKpiStatisticArray, setKpiStatisticData,
  setLoader,
} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";
import {useGetKPIMutation, useGetKPIStatisticMutation} from "../../Store/Builder/uvion.api";

const useKPI = () => {
  const dispatch = useDispatch();
  const [getKPI] = useGetKPIMutation();
  const [getKPIStatistic] = useGetKPIStatisticMutation();

  const get = (params) => {
    dispatch(setLoader(true))
    getKPI(params)
      .unwrap()
      .then((res) => {
        dispatch(setKpiData(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const getStatistic = (params) => {
    dispatch(setLoader(true))
    getKPIStatistic(params)
      .unwrap()
      .then((res) => {
        dispatch(setKpiStatisticData(res))
        dispatch(setKpiStatisticArray(res?.list_traders_kpi))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      });
  };

  return {
    get,
    getStatistic
  };
};

export default useKPI;


