import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedBanksClear, setSellAdsEmpty} from "../../Store/Slice/uvionSlice";
import PhoneInput from 'react-phone-number-input'

import Button from "../../UI/Button";
import Announcements from "../../Components/Announcements";
import Popup from "../../Components/Popup";
import useInputHandler from "../../Global/Hooks/inputHandler";
import {CustomSelect} from "../../UI/Select";
import Input from "../../UI/Input";
import useAds from "../../Global/Api/ads.api";
import useDevises from "../../Global/Api/devises.api";
import useGetBanks from "../../Global/Api/getBanks.api";
import useGetAds from "../../Global/Api/getAds.api";

import './index.scss'
import {Helmet} from "react-helmet";

export default function Ads() {
  const dispatch = useDispatch();
  const createAd = useAds();
  const getDevises = useDevises();
  const getBanks = useGetBanks();
  const getAds = useGetAds();
  const [tab, setTab] = useState(false);
  const [modal, setModal] = useState(false);
  const [adInfo, setAdInfo] = useState(null);
  const [bankSelect, setBankSelect] = useState(null);
  const [typeSelect, setTypeSelect] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [comment, setComment] = useState('');
  const [recvSelect, setRecvSelect] = useState(null);
  const [value, setValue] = useState();
  const [typePayment, setTypePayment] = useState('');
  const [isActive, setIsActive] = useState(false);
  const limitCount = 100;
  const [deviseSelect, setDeviseSelect] = useState(null);
  const [addMinLimitModal, setAddMinLimitModal] = useState(false);
  const [addMinLimitNumber, setAddMinLimitNumber] = useState(null);
  const [subMinLimitModal, setSubMinLimitModal] = useState(false);
  const [subMinLimitNumber, setSubMinLimitNumber] = useState(null);
  const [addMaxLimitModal, setAddMaxLimitModal] = useState(false);
  const [addMaxLimitNumber, setAddMaxLimitNumber] = useState(null);
  const [subMaxLimitModal, setSubMaxLimitModal] = useState(false);
  const [subMaxLimitNumber, setSubMaxLimitNumber] = useState(null);
  const [editMaxOrderAmountPerHourModal, setEditMaxOrderAmountPerHourModal] = useState(false);
  const [interbankSelect, setInterbankSelect] = useState(null)

  const lastCreated = useRef(null);
  const devisesData = useSelector(
    (state) => state.rootReducer.uvionSlice.devisesData
  );
  const sellData = useSelector(
    (state) => state.rootReducer.uvionSlice.sellAds
  );
  const banksData = useSelector(
    (state) => state.rootReducer.uvionSlice.banks
  );
  const typeData = [
    {
      label: 'Получение',
      value: 'sell'
    },
    {
      label: 'Выплата',
      value: 'buy',
    },
  ]
  const recvDataRu = [
    {
      label: 'Номер карты',
      value: 'card_number'
    },
    {
      label: 'Номер мобильного телефона',
      value: 'phone_number',
    },
    {
      label: 'Номер счета',
      value: 'requisite',
    },
    {
      label: 'С2С',
      value: 'c2c',
    },
  ]
  const interbanksData = [
    {
      label: 'Межбанк',
      value: true
    },
    {
      label: 'Не межбанк',
      value: false
    },
  ]
  const cardNumber = useInputHandler("");
  const cardName = useInputHandler("");
  const minLimit = useInputHandler("");
  const maxLimit = useInputHandler("");
  const maxOrderAmount = useInputHandler("");
  const maxOrderAmountPerHour = useInputHandler("");
  const cvv = useInputHandler("");
  const mm = useInputHandler("");
  const yy = useInputHandler("");
  const requisite = useInputHandler("");

  console.log(bankSelect)

  const isButtonDisabled = !(
    (cardNumber?.value?.length === 16 || value?.length > 6 ||
      (bankSelect?.bank_name === 'Tinkoff' ? requisite?.value?.length > 9 : requisite?.value?.length > 19)) &&
    cardName?.value?.length > 2 &&
    maxLimit?.value?.length > 0 &&
    (typeSelect !== null && bankSelect !== null)
  );

  const isButtonDisabledBuy = !(
    maxLimit?.value?.length > 0 && (typeSelect !== null && bankSelect !== null)
  );

  useEffect(() => {
    getBanks.get();
    getDevises.get();
  }, [])

  useEffect(() => {
    if (modal ||
      deleteModal ||
      addMinLimitModal ||
      subMinLimitModal ||
      addMaxLimitModal ||
      subMaxLimitModal) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = 'visible'
    }
  }, [modal,
    deleteModal,
    addMinLimitModal,
    subMinLimitModal,
    addMaxLimitModal,
    subMaxLimitModal])

  useEffect(() => {
    const formattedParams = formatParams();
    getAds.get(formattedParams)
    dispatch(setSelectedBanksClear([]))
  }, [tab])

  useEffect(() => {
    if (recvSelect === null) {
      setValue('')
    }
  }, [recvSelect])

  useEffect(() => {
    if (adInfo === null) {
      cardNumber.setChange('')
      cardName.setChange('')
      minLimit.setChange('')
      maxLimit.setChange('')
      maxOrderAmount.setChange('')
      maxOrderAmountPerHour.setChange('')
      requisite.setChange('')
      cvv.setChange('')
      mm.setChange('')
      yy.setChange('')
      setBankSelect(null)
      setComment('')
      setRecvSelect(null)
      setValue('')
      dispatch(setSelectedBanksClear([]))
      setTypePayment('')
      setDeviseSelect(null)
      setIsActive(false)
      setInterbankSelect(null)
    }
  }, [typeSelect])

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    params.append('ad_type', tab ? 'sell' : 'buy');
    if (lastCreated.current !== null) params.append('last_created_at', lastCreated.current);
    return params
  }

  const loadMore = () => {
    lastCreated.current = sellData[sellData.length - 1]?.created_at
    const formattedParams = formatParams();
    getAds.get(formattedParams)
  }

  const openModal = () => {
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
    setAdInfo(null)
    cardNumber.setChange('')
    cardName.setChange('')
    minLimit.setChange('')
    maxLimit.setChange('')
    maxOrderAmount.setChange('')
    maxOrderAmountPerHour.setChange('')
    requisite.setChange('')
    cvv.setChange('')
    mm.setChange('')
    yy.setChange('')
    setBankSelect(null)
    setTypeSelect(null)
    setComment('')
    setRecvSelect(null)
    setValue('')
    dispatch(setSelectedBanksClear([]))
    setTypePayment('')
    setIsActive(false)
    setDeviseSelect(null)
    setInterbankSelect(null)
  }

  const editSendData = () => {
    const isCardNumberPayment = typePayment === 'card_number';
    const cardHolderValue = cardName?.value?.length > 0 ? cardName.value : null;

    const body = {
      'id': adInfo?.id,
      'type': adInfo?.type,
      'ad_category': adInfo?.ad_category,
      'card_holder': cardHolderValue,
      'max_order_amount': maxOrderAmount?.value !== '' ? maxOrderAmount?.value : maxLimit?.value,
      'card_number': isCardNumberPayment ? cardNumber.value : null,
      'requisite': adInfo?.requisite,
      'requisite_type': (typePayment === "card_number" || typePayment === "c2c") ? 'card' : typePayment === "phone_number" ? 'phone' : 'transfer',
      'cvv': typePayment === 'c2c' ? cvv.value : null,
      'month': typePayment === 'c2c' ? mm.value : null,
      'year': typePayment === 'c2c' ? yy.value : null,
      'phone_number': isCardNumberPayment ? null : value,
      'device_id': deviseSelect?.[0]?.id === null ? null : deviseSelect?.[0]?.id,
      'comment': comment,
      "payment_method_id": bankSelect[0]?.id,
      "currency_id": bankSelect[0]?.currency?.id,
      "is_active": isActive,
      "is_interbank": adInfo?.type === 'sell' ? interbankSelect.value : true
    };

    createAd.editedAd(body, closeModal, tab, {action: "edit"})
  }

  const sendData = () => {
    const isSell = typeSelect?.value === 'sell';
    const cardHolderValue = cardName.value;
    const phoneNumberValue = typePayment === 'card_number' ? null : value;
    const requisiteValue = requisite?.value?.length > 0 ? requisite?.value : null;

    const body = {
      'type': isSell ? 'buy' : 'sell',
      'max_amount': maxLimit?.value,
      'max_order_amount': maxOrderAmount?.value !== '' ? maxOrderAmount?.value : maxLimit?.value,
      'max_order_amount_per_hour': maxOrderAmountPerHour?.value !== '' ? maxOrderAmountPerHour?.value : maxLimit?.value,
      'card_holder': cardHolderValue?.length > 0 ? cardHolderValue : null,
      'card_number': typePayment === 'card_number' || typePayment === 'c2c' ? cardNumber.value : null,
      'requisite': requisiteValue,
      'requisite_type': (typePayment === "card_number" || typePayment === "c2c") ? 'card' : typePayment === "phone_number" ? 'phone' : 'transfer',
      'cvv': typePayment === 'c2c' ? cvv.value : null,
      'month': typePayment === 'c2c' ? mm.value : null,
      'year': typePayment === 'c2c' ? yy.value : null,
      'ad_category': typePayment === 'c2c' ? 'c2c' : 'p2p',
      'phone_number': phoneNumberValue ? phoneNumberValue : null,
      'device_id': deviseSelect === null ? null : deviseSelect?.id,
      'comment': comment,
      "payment_method_id": bankSelect?.id,
      "currency_id": bankSelect?.currency?.id,
      "is_active": isActive,
      "is_interbank": !isSell ? interbankSelect.value : true
    };
    createAd.createdAd(body, closeModal, tab)
  }

  const editAd = (item) => {
    openModal()
    setAdInfo(item)
    cardNumber.setChange(item?.card_number)
    cardName.setChange(item?.card_holder)
    minLimit.setChange(Math.round(item?.min_amount))
    maxLimit.setChange(Math.round(item?.max_amount))
    maxOrderAmount.setChange(Math.round(item?.max_order_amount))
    maxOrderAmountPerHour.setChange(Math.round(item?.max_order_amount_per_hour))
    cvv.setChange(item?.cvv)
    mm.setChange(item?.month)
    yy.setChange(item?.year)
    setValue(item?.phone_number)
    setBankSelect(banksData?.filter(el => el?.id === item?.payment_method_id))
    if (item?.device_id !== null) {
      setDeviseSelect(devisesData?.filter(el => el?.id === item?.device_id))
    }
    if (item?.is_interbank !== null) {
      setInterbankSelect(interbanksData?.filter(el => el?.value === item?.is_interbank))
    }
    if (item?.type === 'buy') {
      setTypeSelect(typeData?.filter(el => el?.value === 'sell'))
    } else {
      setTypeSelect(typeData?.filter(el => el?.value === 'buy'))
    }
    setComment(item?.comment)
    setIsActive(item?.is_active)
    if (item?.card_number !== null && item?.phone_number === null) {
      setTypePayment('card_number')
      setRecvSelect(recvDataRu?.filter(el => el?.value === 'card_number'))
    }
    if (item?.phone_number !== null && item?.card_number === null) {
      setTypePayment('phone_number')
      setRecvSelect(recvDataRu?.filter(el => el?.value === 'phone_number'))
    }
    if (item?.year !== null && item?.month !== null && item?.cvv !== null) {
      setRecvSelect(recvDataRu?.filter(el => el?.value === 'c2c'))
    }
    if (item?.requisite !== null) {
      setTypePayment("requisite");
      setRecvSelect(recvDataRu?.filter((el) => el?.value === "requisite"));
    }
  }

  const deleteAd = (item) => {
    setDeleteModal(true)
    setAdInfo(item)
  }

  const closeDeleteModal = () => {
    setDeleteModal(false)
    setAdInfo(null)
  }

  const deleteSendData = () => {
    createAd.deletedAd({
      'ad_id': adInfo?.id
    }, closeDeleteModal, tab)
  }

  const renderSellAds = () => {
    return sellData?.map(item => (
      <Announcements
        editAd={editAd}
        deleteAd={deleteAd}
        typePayment={typePayment}
        closeModal={closeModal}
        id={item?.id}
        item={item}
        key={item?.id}
        tab={tab}
        openAddMinLimitModal={openAddMinLimitModal}
        openSubMinLimitModal={openSubMinLimitModal}
        openAddMaxLimitModal={openAddMaxLimitModal}
        openSubMaxLimitModal={openSubMaxLimitModal}
        openEditMaxOrderAmountModal={openEditMaxOrderAmountModal}
      />
    ))
  }

  const closeAddMinLimitModal = () => {
    setAdInfo(null)
    setAddMinLimitModal(false)
    setAddMinLimitNumber(null)
  }

  const openAddMinLimitModal = (item) => {
    setAdInfo(item)
    setAddMinLimitModal(true)
  }

  const openEditMaxOrderAmountModal = (item) => {
    setAdInfo(item)
    maxOrderAmountPerHour.setChange(Math.round(item?.max_order_amount_per_hour))
    setEditMaxOrderAmountPerHourModal(true)
  }

  const closeEditMaxOrderAmountModal = () => {
    setAdInfo(null)
    maxOrderAmountPerHour.setChange('')
    setEditMaxOrderAmountPerHourModal(false)
  }

  const closeSubMinLimitModal = () => {
    setAdInfo(null)
    setSubMinLimitModal(false)
    setSubMinLimitNumber(null)
  }

  const closeAddMaxLimitModal = () => {
    setAdInfo(null)
    setAddMaxLimitModal(false)
    setAddMaxLimitNumber(null)
  }

  const closeSubMaxLimitModal = () => {
    setAdInfo(null)
    setSubMaxLimitModal(false)
    setSubMaxLimitNumber(null)
  }

  const openSubMinLimitModal = (item) => {
    setAdInfo(item)
    setSubMinLimitModal(true)
  }

  const openAddMaxLimitModal = (item) => {
    setAdInfo(item)
    setAddMaxLimitModal(true)
  }

  const openSubMaxLimitModal = (item) => {
    setAdInfo(item)
    setSubMaxLimitModal(true)
  }

  const addMinLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: addMinLimitNumber
    }
    createAd.addMinLimitAd(body, closeAddMinLimitModal)
  }

  const subMinLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: subMinLimitNumber
    }
    createAd.subMinLimitAd(body, closeSubMinLimitModal)
  }

  const addMaxLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: addMaxLimitNumber
    }
    createAd.addMaxLimitAd(body, closeAddMaxLimitModal)
  }

  const subMaxLimit = () => {
    const body = {
      ad_id: adInfo?.id,
      amount: subMaxLimitNumber
    }
    createAd.subMaxLimitAd(body, closeSubMaxLimitModal)
  }

  const editMaxAmountPerHour = () => {
    const data = {
      ad_id: adInfo?.id,
      amount: maxOrderAmountPerHour?.value !== '' ? Number(maxOrderAmountPerHour?.value) : Number(maxLimit?.value)
    }
    createAd.editedMaxLimitPerHour(data, closeEditMaxOrderAmountModal)
  }

  return (
    <div className="ads">
      <Helmet>
        <title>UVION | ОБЪЯВЛЕНИЯ</title>
      </Helmet>
      {modal && (
        <Popup title={adInfo !== null ? 'Редактировать объявление' : 'Создать объявление'} closePopup={closeModal}>
          <div className="ads__modal">
            <p className="ads__modal-text">Настройка объявления по данным параметрам</p>
            <div className="ads__modal-select">
              <CustomSelect
                disabled={adInfo !== null}
                options={typeData}
                selected={typeSelect}
                placeholder='Тип'
                isSearchable={false}
                handleSelect={(e) => {
                  setTypeSelect(e ?? null)
                }}
                isClearable={true}
              />
            </div>
            <div className="ads__modal-select">
              <CustomSelect
                placeholder='Способ оплаты'
                disabled={adInfo !== null}
                options={banksData}
                selected={bankSelect}
                handleSelect={(e) => {
                  setBankSelect(e ?? null)
                }}
                isClearable={true}
                isSearch={true}
              />
            </div>
            <div className="ads__modal-select">
              <CustomSelect
                placeholder='Платежные реквизиты'
                options={recvDataRu}
                selected={recvSelect}
                isSearchable={false}
                handleSelect={(e) => {
                  setRecvSelect(e ?? null)
                  if (e !== null) {
                    setTypePayment(e.value)
                  } else {
                    setTypePayment('')
                  }
                }}
                isClearable={false}
              />
            </div>
            <div className="ads__modal-inputs">
              {(typePayment === 'phone_number') && (
                <div className="ads__modal-input">
                  <PhoneInput
                    placeholder="Номер мобильного телефона"
                    value={value}
                    onChange={setValue}
                    international
                  />
                </div>
              )}
              {(typePayment === 'requisite') && (
                <div className="ads__modal-input">
                  <Input
                    label='Номер счета'
                    placeholder='Введите номер счета'
                    type="text"
                    id="requisite"
                    {...requisite}
                    onlyNumber={true}
                  />
                </div>
              )}
              {(typePayment === 'card_number' || typePayment === 'c2c') && (
                <div className="ads__modal-input">
                  <Input
                    label='Номер карты'
                    placeholder='Введите номер карты'
                    type="text"
                    id="card_number"
                    {...cardNumber}
                    onlyNumber={true}
                    maxLength={16}
                  />
                </div>
              )}
              <div className={`ads__modal-input ${recvSelect === null ? 'ads__modal-input_100' : ''}`}>
                <Input
                  label='ФИО владельца карты'
                  placeholder='Введите ФИО владельца карты'
                  type="text"
                  id="card_name"
                  {...cardName}
                />
              </div>
            </div>
            {((recvSelect?.[0]?.value === 'c2c' || recvSelect?.value === 'c2c')) && (
              <div className="ads__modal-inputs">
                <div className="ads__modal-inputs">
                  <div className="ads__modal-input">
                    <Input
                      label='ММ'
                      placeholder={'ММ'}
                      type="text"
                      id="mm"
                      {...mm}
                      onlyNumber={true}
                      maxLength={2}
                    />
                  </div>
                  <p className='ads__modal-slash'>/</p>
                  <div className="ads__modal-input">
                    <Input
                      label='ГГ'
                      placeholder={'ГГ'}
                      type="text"
                      id="yy"
                      {...yy}
                      onlyNumber={true}
                      maxLength={2}
                    />
                  </div>
                </div>
                <div className="ads__modal-input">
                  <Input
                    label='CVV'
                    placeholder={'CVV'}
                    type="text"
                    id="cvv"
                    {...cvv}
                    onlyNumber={true}
                    maxLength={3}
                  />
                </div>
              </div>
            )}
            {adInfo === null && (
              <div className="ads__modal-inputs">
                <div className="ads__modal-input">
                  <Input
                    label='Макс. лимит'
                    placeholder='Введите макс. лимит'
                    type="text"
                    id="max_limit"
                    {...maxLimit}
                    onlyNumber={true}
                  />
                </div>
                <div className="ads__modal-separate"></div>
                <div className="ads__modal-input">
                  <Input
                    label='Макс. сумма заявки'
                    placeholder='Введите макс. сумма заявки'
                    type="text"
                    id="max_order_amount"
                    {...maxOrderAmount}
                    onlyNumber={true}
                  />
                </div>
              </div>
            )}
            {adInfo !== null && (
              <div className="ads__modal-inputs">
                <div className="ads__modal-input">
                  <Input
                    label='Макс. сумма заявки'
                    placeholder='Введите макс. сумма заявки'
                    type="text"
                    id="max_order_amount"
                    {...maxOrderAmount}
                    onlyNumber={true}
                  />
                </div>
              </div>
            )}
            {adInfo === null && (
              <div className="ads__modal-inputs">
                <div className="ads__modal-input">
                  <Input
                    label='Макс. объем за час'
                    placeholder='Введите макс. объем за час'
                    type="text"
                    id="max_order_amount_per_hour"
                    {...maxOrderAmountPerHour}
                    onlyNumber={true}
                  />
                </div>
              </div>
            )}
            {(typeSelect?.value !== 'buy' && adInfo === null && (recvSelect?.value === 'phone_number' || recvSelect?.value === 'card_number')) && (
              <div className="ads__modal-select">
                <CustomSelect
                  placeholder='Выберите девайс'
                  options={devisesData}
                  selected={deviseSelect}
                  handleSelect={(e) => {
                    setDeviseSelect(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
            )}
            {(adInfo !== null && typeSelect[0]?.value !== 'buy' && (recvSelect[0]?.value === 'phone_number' || recvSelect[0]?.value === 'card_number')) && (
              <div className="ads__modal-select">
                <CustomSelect
                  placeholder='Выберите девайс'
                  options={devisesData}
                  selected={deviseSelect}
                  handleSelect={(e) => {
                    setDeviseSelect(e ?? null)
                  }}
                  isClearable={true}
                />
              </div>
            )}
            {(typeSelect?.[0]?.value === 'buy' || typeSelect?.value === 'buy') && (
              <div className="ads__modal-select">
                <CustomSelect
                  placeholder='Межбанк'
                  options={interbanksData}
                  selected={interbankSelect}
                  handleSelect={(e) => {
                    setInterbankSelect(e ?? null)
                  }}
                  isClearable={true}
                  isSearch={false}
                />
              </div>
            )}
            <div className="ads__modal-textarea">
              <textarea value={comment}
                        placeholder='Комментарий'
                        onChange={(e) => {
                          setComment(e.target.value)
                        }} maxLength={50}/>
            </div>
            <div className="ads__modal-btns">
              <div className="ads__modal-btn">
                <Button
                  disabled={(adInfo !== null && typeSelect?.value !== 'buy') ? false : (adInfo === null && typeSelect?.value !== 'buy') ? isButtonDisabled : isButtonDisabledBuy}
                  key={cardNumber.value}
                  type='accept'
                  text='Сохранить'
                  onClick={() => {
                    if (adInfo) {
                      editSendData()
                    } else {
                      sendData()
                    }
                  }}
                  topPadding={10}
                  bottomPadding={10}
                />
              </div>
              <div className="ads__modal-btn">
                <Button
                  text='Отмена'
                  topPadding={10}
                  onClick={closeModal}
                  bottomPadding={10}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {deleteModal && (
        <Popup title='Удалить объявление' closePopup={closeDeleteModal}>
          <div className="ads__modal-wrapper">
            <p className="ads__modal-text">Вы уверены что хотите удалить объявление?</p>
            <div className="ads__modal-btns">
              <div className="ads__modal-btn">
                <Button
                  text='Подтвердить'
                  type='accept'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={deleteSendData}
                />
              </div>
              <div className="ads__modal-btn">
                <Button
                  text='Отмена'
                  bottomPadding={12}
                  topPadding={12}
                  onClick={closeDeleteModal}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
      {editMaxOrderAmountPerHourModal && (
        <Popup title={"Изменить макс. объем за час"} closePopup={closeEditMaxOrderAmountModal}>
          <div className="add-limit__inner mb-16">
            <Input
              placeholder='Макс. объем за час'
              type="text"
              id="max_order_amount_per_hour"
              {...maxOrderAmountPerHour}
              onlyNumber={true}
            />
          </div>
          <Button
            disabled={maxOrderAmountPerHour?.value !== '' ? false : true}
            text={"Сохранить"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={editMaxAmountPerHour}
          />
        </Popup>
      )}
      {addMinLimitModal && (
        <Popup title={"Добавить минимальный лимит"} closePopup={closeAddMinLimitModal}>
          <div className="add-limit__inner mb-16">
            <Input
              placeholder="Введите число"
              type="number"
              id="add"
              value={addMinLimitNumber}
              handleChange={(e) => setAddMinLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={addMinLimitNumber !== null ? false : true}
            text={"Добавить"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={addMinLimit}
          />
        </Popup>
      )}
      {subMinLimitModal && (
        <Popup title={"Вычесть минимальный лимит"} closePopup={closeSubMinLimitModal}>
          <div className="add-deposit__inner mb-16">
            <Input
              placeholder="Введите число"
              type="number"
              id="add"
              value={subMinLimitNumber}
              handleChange={(e) => setSubMinLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={subMinLimitNumber !== null ? false : true}
            text={"Вычесть"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={subMinLimit}
          />
        </Popup>
      )}
      {addMaxLimitModal && (
        <Popup title={"Добавить максимальный лимит"} closePopup={closeAddMaxLimitModal}>
          <div className="add-deposit__inner mb-16">
            <Input
              placeholder="Введите число"
              type="number"
              id="add"
              value={addMaxLimitNumber}
              handleChange={(e) => setAddMaxLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={addMaxLimitNumber !== null ? false : true}
            text={"Добавить"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={addMaxLimit}
          />
        </Popup>
      )}
      {subMaxLimitModal && (
        <Popup title={"Вычесть максимальный лимит"} closePopup={closeSubMaxLimitModal}>
          <div className="add-deposit__inner mb-16">
            <Input
              placeholder="Введите число"
              type="number"
              id="add"
              value={subMaxLimitNumber}
              handleChange={(e) => setSubMaxLimitNumber(e.target.value)}
            />
          </div>
          <Button
            disabled={subMaxLimitNumber?.length > 0 ? false : true}
            text={"Вычесть"}
            topPadding={10}
            bottomPadding={10}
            type="accept"
            onClick={subMaxLimit}
          />
        </Popup>
      )}
      <div className="ads__btn">
        <Button
          text='Создать объявление'
          bottomPadding={8}
          type='accept'
          onClick={openModal}
          topPadding={8}
        />
      </div>
      <div className={`ads__tabs ${sellData?.length === 0 ? 'ads__tabs_mb' : ''}`}>
        <div onClick={() => {
          lastCreated.current = null
          dispatch(setSellAdsEmpty([]))
          setTab(false)
        }} className={`ads__tab ${!tab ? 'ads__tab_active' : ''}`}>
          <p className='ads__tab-text'>Получение</p>
        </div>
        <div onClick={() => {
          lastCreated.current = null
          dispatch(setSellAdsEmpty([]))
          setTab(true)
        }} className={`ads__tab ${tab ? 'ads__tab_active' : ''}`}>
          <p className='ads__tab-text'>Выплата</p>
        </div>
      </div>
      {sellData?.length === 0 && (
        <div className={`orders__banner ${sellData?.length === 0 ? 'orders__banner_empty' : ''}`}>
          <div className="orders__banner-top">
            <p className="orders__banner-name">Объявления</p>
            <div className='orders__banner-icon'>
              <img src="/assets/icons/appeal-banner-icon.svg" alt="orders"/>
            </div>
          </div>
          <div className="orders__banner-bottom">
            <p className="orders__banner-title">Будьте бдительны</p>
            <p className="orders__banner-subtitle">Ответственно подходите к работе с объявлениями, любая ваша ошибка
              может привести к потере средств.</p>
          </div>
        </div>
      )}
      {sellData?.length > 0 && (
        <div className="ads__table-container">
          <div className="ads__table">
            <div className="ads__table-top">
              <div className="ads__table-head">
                <p>Вкл/Выкл</p>
              </div>
              <div className="ads__table-head">
                <p>Тип</p>
              </div>
              <div className="ads__table-head">
                <p>Реквизиты</p>
              </div>
              <div className="ads__table-head">
                <p>Банк</p>
              </div>
              <div className="ads__table-head">
                <p>Мин. лимит</p>
              </div>
              <div className="ads__table-head">
                <p>Макс. лимит/Макс. сумма заявки</p>
              </div>
              <div className="ads__table-head">
                <p>Макс. объем на карту за час</p>
              </div>
              <div className="ads__table-head">
                <p>Комментарий</p>
              </div>
              <div className="ads__table-head">
                <p>Действие</p>
              </div>
            </div>
            <div className="ads__table-list">
              {renderSellAds()}
            </div>
            {sellData?.length >= limitCount && (
              <div className="loadMore">
                <p onClick={() => {
                  loadMore()
                }}>
                  Загрузить еще...
                </p>
                <span>
            <img src="/assets/icons/arrow-down.svg" alt="arrow icon"/>
          </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
