import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Button from "../../UI/Button";
import useGetWalletBillings from "../../Global/Api/getWalletBillings.api";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import WalletBillingItem from "../../Components/WalletBillingItem";
import {setWalletBillingsEmpty} from "../../Store/Slice/uvionSlice";

import './index.scss'
import {Helmet} from "react-helmet";

export default function Wallet() {
  const dispatch = useDispatch();
  const apiWallet = useGetWalletBillings();
  const [tab, setTab] = useState(false);
  const [addressCopy, setAddressCopy] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const limitCount = 100;
  const lastCreatedAt = useRef(null);
  const amountValue = useInputHandler("");

  const {walletData, walletBillings, cryptoWallets} = useSelector(
      (state) => state.rootReducer.uvionSlice
  );

  const profileInfo = useSelector(
      (state) => state.rootReducer.uvionSlice.profile_info
  );

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append('limit', limitCount);
    params.append('transaction_type', !tab ? 'web3' : 'order')
    if (lastCreatedAt.current !== null) params.append('last_created_at', lastCreatedAt.current);
    return params
  }

  useEffect(() => {
    apiWallet.getCryptoWallets()
  }, [])

  useEffect(() => {
    if (transferModal) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = 'visible'
    }
  }, [transferModal])

  useEffect(() => {
    const formattedParams = formatParams();
    apiWallet.get(formattedParams)
  }, [tab])

  const loadMore = () => {
    lastCreatedAt.current = walletBillings[walletBillings.length - 1]?.created_at
    const formattedParams = formatParams();
    apiWallet.get(formattedParams)
  }

  const toggleFieldTemporarily = () => {
    setAddressCopy(true)

    setTimeout(() => {
      setAddressCopy(false)
    }, 3000);
  };

  const closeTransferModal = () => {
    setTransferModal(false)
    amountValue.setChange('')
  }

  const renderBillingsData = () => {
    return walletBillings?.map((billing, index) => (
        <WalletBillingItem key={index} item={billing}/>
    ))
  }

  const transferSendData = () => {
    const body = {
      balance: amountValue.value
    }
    apiWallet.transferBalance(body, closeTransferModal)
  }

  const copyElement = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      // Создаем временный элемент для копирования
      let tempElement = document.createElement('span');
      tempElement.textContent = text;

      // Скрываем элемент и добавляем его в DOM
      tempElement.style.position = 'fixed';
      tempElement.style.opacity = '0';
      tempElement.style.pointerEvents = 'none';

      document.body.appendChild(tempElement);

      // Создаем Range и выделяем содержимое элемента
      let range = document.createRange();
      range.selectNodeContents(tempElement);

      // Используем Selection для выделения Range
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Копируем в буфер обмена
      try {
        document.execCommand('copy');
      } catch (err) {
      }

      // Удаляем временный элемент и очищаем выделение
      document.body.removeChild(tempElement);
      selection.removeAllRanges();
    }
  };

  return (
      <div className='wallet'>
        <Helmet>
          <title>UVION | КОШЕЛЁК</title>
        </Helmet>
        {transferModal && (
            <Popup title='Перевод средств' closePopup={closeTransferModal}>
              <div className="orders__popup-wrapper">
                <p className="orders__popup-text">Перевод средств с баланса на депозит</p>
                <div className="order__popup-input mt-4">
                  <Input
                      placeholder='Введите сумму перерасчета'
                      type="text"
                      id="amountValue"
                      {...amountValue}
                      onlyNumber={true}
                      maxLength={8}
                  />
                </div>
                <div className="orders__popup-btns">
                  <div className="orders__popup-btn">
                    <Button
                        text='Перевести'
                        type='accept'
                        bottomPadding={12}
                        topPadding={12}
                        onClick={transferSendData}
                        disabled={amountValue.value?.length === 0 ? true : false}
                    />
                  </div>
                  <div className="orders__popup-btn">
                    <Button
                        text='Отмена'
                        bottomPadding={12}
                        topPadding={12}
                        onClick={closeTransferModal}
                    />
                  </div>
                </div>
              </div>
            </Popup>
        )}
        <div className="wallet__wrapper">
          <div className="wallet__left">
            <div className="wallet__left-qrcode">
              <div className="wallet__left-bep">
                BEP20
              </div>
              <div className="wallet__qr">
                <img src={cryptoWallets?.qr_code_path} alt="qr"/>
              </div>
            </div>
            <div className="wallet__left-tablet">
              <div>
                <p className="wallet__address-title">Адрес кошелька</p>
                <div className="wallet__address">
            <span>
              <img src={!addressCopy ? "/assets/icons/copy.svg" : "/assets/icons/check-circle.svg"} alt="copy"/>
            </span>
                  <p>{cryptoWallets?.crypto_address}</p>
                </div>
              </div>
              <div className="wallet__address-btn">
                <Button
                    text='Копировать адрес кошелька'
                    type='accept'
                    bottomPadding={12}
                    topPadding={12}
                    onClick={() => {
                      toggleFieldTemporarily()
                      copyElement(cryptoWallets?.crypto_address)
                    }}
                />
              </div>
            </div>
          </div>

          <div className="wallet__right">
            <div className="wallet__block">
              <div className="wallet__block-tablet">
                <p className="wallet__block-title">Баланс в USDT / {profileInfo?.currency?.currency}</p>
                <p className="wallet__block-value">
                  {cryptoWallets && cryptoWallets.balance && profileInfo?.currency?.usdt_price && profileInfo?.currency?.usdt_price !== 0 ?
                      (cryptoWallets.balance > 0 ?
                          Number(cryptoWallets.balance).toLocaleString('ru-RU', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          }).replace(',', '.')
                          : 0)
                      : 0
                  } {profileInfo?.currency?.currency}</p>
                <p className="wallet__block-curr">
                  {cryptoWallets && cryptoWallets.balance && profileInfo?.currency?.usdt_price && profileInfo?.currency?.usdt_price !== 0 ?
                      (cryptoWallets.balance > 0 ?
                          Number(cryptoWallets.balance / profileInfo?.currency?.usdt_price).toLocaleString('ru-RU', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          }).replace(',', '.')
                          : 0)
                      : 0
                  } USDT</p>
                <p className="wallet__block-subtitle">Ваш заработанный баланс в USDT / {profileInfo?.currency?.currency} на данный момент</p>
              </div>
              <div className="wallet__block-btn">
                <Button
                    className='wallet__block-btn-padding'
                    text='Перевести'
                    type='accept'
                    bottomPadding={12}
                    topPadding={12}
                    onClick={() => {
                      setTransferModal(true)
                    }}
                />
              </div>
            </div>

            <div className="wallet__block">
              <div>
                <p className="wallet__block-title">Депозит в USDT / {profileInfo?.currency?.currency}</p>
                <p className="wallet__block-value">
                  {cryptoWallets && cryptoWallets.deposit && profileInfo?.currency?.usdt_price && profileInfo?.currency?.usdt_price !== 0 ?
                      (cryptoWallets.deposit > 0 ?
                          Number(cryptoWallets.deposit).toLocaleString('ru-RU', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          }).replace(',', '.')
                          : 0)
                      : 0
                  } {profileInfo?.currency?.currency}</p>
                <p className="wallet__block-curr">
                  {cryptoWallets && cryptoWallets.deposit && profileInfo?.currency?.usdt_price && profileInfo?.currency?.usdt_price !== 0 ?
                      (cryptoWallets.deposit > 0 ?
                          Number(cryptoWallets.deposit / profileInfo?.currency?.usdt_price).toLocaleString('ru-RU', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          }).replace(',', '.')
                          : 0)
                      : 0
                  } USDT</p>
                <p className="wallet__block-subtitle">Ваш текущий депозит в USDT / {profileInfo?.currency?.currency} на данный момент</p>
              </div>

            </div>

            <div className="wallet__block">
              <div>
                <p className="wallet__block-title">Замороженный депозит в USDT / {profileInfo?.currency?.currency}</p>

                <p className="wallet__block-value wallet__block-value-blue"
                >
                  {cryptoWallets && cryptoWallets.frozen_balance && profileInfo?.currency?.usdt_price ?
                      (cryptoWallets.frozen_balance > 0 ?
                          Number(cryptoWallets.frozen_balance).toLocaleString('ru-RU', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          }).replace(',', '.')
                          : 0)
                      : 0
                  } {profileInfo?.currency?.currency}</p>

                <p className="wallet__block-curr wallet__block-curr-blue">
                  {cryptoWallets && cryptoWallets.frozen_balance && profileInfo?.currency?.usdt_price ?
                      (cryptoWallets.frozen_balance > 0 ?
                          Number(cryptoWallets.frozen_balance / profileInfo?.currency?.usdt_price).toLocaleString('ru-RU', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          }).replace(',', '.')
                          : 0)
                      : 0
                  } USDT</p>
                <p className="wallet__block-subtitle">Ваш замороженный депозит в USDT / {profileInfo?.currency?.currency} на данный момент</p>
              </div>
            </div>

            <div className="wallet__block">
              <div>

                <p className="wallet__block-title">Курс в {profileInfo?.currency?.currency}</p>
                <p
                    className="wallet__block-value">
                  {profileInfo?.currency?.usdt_price ? Number(profileInfo?.currency?.usdt_price).toLocaleString('ru-RU', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                  }).replace(',', '.') : 0} {profileInfo?.currency?.currency}</p>
                <p className="wallet__block-subtitle">Курс на пополнение USDT (ByBit)</p>

              </div>
            </div>

            <div className="wallet__block">
              <div>


                <p className="wallet__block-title">Страховой депозит</p>
                <p
                    className="wallet__block-value">
                  {cryptoWallets?.is_deposit ? 'Внесен' : 'Не внесен'}
                </p>
                <p className="wallet__block-subtitle">Ваш внесенный страховой депозит</p>


              </div>
            </div>
          </div>

        </div>
        <div className="wallet__tabs">
          <div onClick={() => {
            dispatch(setWalletBillingsEmpty([]))
            lastCreatedAt.current = null
            setTab(false)
          }} className={`wallet__tab ${!tab ? 'wallet__tab_active' : ''}`}>
            <p className='wallet__tab-text'>История пополнений</p>
          </div>
          <div onClick={() => {
            dispatch(setWalletBillingsEmpty([]))
            lastCreatedAt.current = null
            setTab(true)
          }} className={`wallet__tab ${tab ? 'wallet__tab_active' : ''}`}>
            <p className='wallet__tab-text'>Биллинги</p>
          </div>
        </div>
        <div className="wallet__table-container">
          <div className='wallet__table'>
            <div className="wallet__table-top">
              <div className="wallet__table-head">
                <p>Депозит</p>
              </div>
              <div className="wallet__table-head">
                <p>Баланс</p>
              </div>
              <div className="wallet__table-head">
                <p>Замороженный баланс</p>
              </div>
              <div className="wallet__table-head">
                <p>Валюта</p>
              </div>
              <div className="wallet__table-head">
                <p>ID заявки</p>
              </div>
              <div className="wallet__table-head">
                <p>Тип операции</p>
              </div>
              <div className="wallet__table-head">
                <p>Тип транзакции</p>
              </div>
            </div>
            <div className="wallet__table-list">
              {renderBillingsData()}
            </div>
            {walletBillings?.length >= limitCount && (
                <div className="loadMore">
                  <p onClick={() => {
                    loadMore()
                  }}>
                    Загрузить еще...
                  </p>
                  <span>
            <img src="/assets/icons/arrow-down.svg" alt=""/>
          </span>
                </div>
            )}
          </div>
        </div>
      </div>
  )
}
