import {useDispatch} from "react-redux";
import {useGetSmsHistoryMutation} from "../../Store/Builder/uvion.api";
import {setLoader, setSmsHistory} from "../../Store/Slice/uvionSlice";
import showToast from "../Hooks/toast";

const useSmsHistory = () => {
    const dispatch = useDispatch();
    const [apiGetSmsHistory] = useGetSmsHistoryMutation();

    const get = (params) => {
        dispatch(setLoader(true));
        apiGetSmsHistory(params)
            .unwrap()
            .then((res) => {
                dispatch(setSmsHistory(res));
                dispatch(setLoader(false));
                // closeFilterModal()
            })
            .catch((err) => {
                dispatch(setLoader(false));
                showToast(err?.data?.message ?? "Error", "error");
            });
    };

    return {
        get,
    };
};

export default useSmsHistory;
