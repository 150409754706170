import React, {useEffect, useState} from "react";
import moment from "moment";

import Status from "../Status";
import {useDispatch} from "react-redux";
import {setDeleteBuy, setDeleteOrder} from "../../Store/Slice/uvionSlice";
import {useLocation} from "react-router-dom";

import './index.scss'
import parsePhoneNumberFromString from "libphonenumber-js";

export default function Order({
                                openDetailsModal,
                                item,
                                openRejectModal,
                                openAcceptModal,
                                openAcceptBuyModal,
                                openCancelledModal,
                                openAppealModal
                              }) {
  const [more, setMore] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const createdAtDate = new Date();
  const timeToEndDate = moment.utc(item?.time_to_end_trader !== null ? item?.time_to_end_trader : item?.time_to_end).toDate();
  const timeDifferenceInMilliseconds = timeToEndDate - createdAtDate;
  const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60)) + 1;
  const [remainingTime, setRemainingTime] = useState(timeDifferenceInMinutes);
  const [initialState, setInitialState] = useState({
    id: false,
    card_name: false,
    card_number: false,
    user_card_number: false,
    amount_crypto: false,
    amount_fiat: false,
  })

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (remainingTime <= 0) {
        if (location?.pathname === '/') {
          dispatch(setDeleteOrder(item?.id))
        }
        if (location?.pathname === '/buy') {
          dispatch(setDeleteBuy(item?.id))
        }
        clearInterval(countdownInterval);
      } else {
        const hours = Math.floor((remainingTime + 1) / 60);
        const minutes = (remainingTime) % 60;
        const seconds = 0; // если вы хотите учитывать секунды, раскомментируйте эту строку и присвойте соответствующее значение

        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);

      }
    }, 60 * 1000); // Обновление каждую минуту

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(countdownInterval);
  }, [remainingTime]);

  const formatSecondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

    return formattedTime;
  }

  const toggleFieldTemporarily = (fieldName) => {
    const updatedState = {...initialState};

    updatedState[fieldName] = true;

    setInitialState(updatedState);

    setTimeout(() => {
      setInitialState((prevState) => ({
        ...prevState,
        [fieldName]: false
      }));
    }, 5000);
  };

  const copyElement = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      // Создаем временный элемент для копирования
      let tempElement = document.createElement('span');
      tempElement.textContent = text;

      // Скрываем элемент и добавляем его в DOM
      tempElement.style.position = 'fixed';
      tempElement.style.opacity = '0';
      tempElement.style.pointerEvents = 'none';

      document.body.appendChild(tempElement);

      // Создаем Range и выделяем содержимое элемента
      let range = document.createRange();
      range.selectNodeContents(tempElement);

      // Используем Selection для выделения Range
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Копируем в буфер обмена
      try {
        document.execCommand('copy');
      } catch (err) {
      }

      // Удаляем временный элемент и очищаем выделение
      document.body.removeChild(tempElement);
      selection.removeAllRanges();
    }
  };

  const formatCreditCardNumber = (creditCardNumber) => {
    if (!creditCardNumber) {
      return ''; // Если входная строка пуста или не определена, возвращаем пустую строку
    }

    // Удаляем все символы, кроме цифр
    const cleanedNumber = creditCardNumber.replace(/\D/g, '');

    // Добавляем пробел после каждых 4 символов
    const formattedNumber = cleanedNumber.replace(/(.{4})/g, '$1 ');

    // Возвращаем отформатированный номер карты
    return formattedNumber.trim(); // Удаляем лишние пробелы в начале и конце строки
  };

  const formatPhoneNumber = (phoneNumber) => {
    try {
      const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
      if (phoneNumberObject) {
        const country = phoneNumberObject.country;
        if (country === 'RU') {
          // Для российского номера используем кастомное форматирование
          return '+7' + phoneNumberObject.formatNational().replace(/^8/, '');
        } else {
          // Для других номеров используем международное форматирование с заменой пробелов на дефисы
          return phoneNumberObject.formatInternational().replace(/ /g, '-');
        }
      }
    } catch (error) {
      console.error("Error formatting phone number:", error);
    }
    return phoneNumber;
  };

  return (
    <div key={item?.id}
         className={`order ${location?.pathname === '/buy' ? 'order_small' : ''} ${location?.pathname === '/orders-history' || location?.pathname === '/' ? 'order_medium' : ''}`}>
      <div
        className={`order__wrapper ${location?.pathname === '/buy' ? 'order__wrapper_small' : ''} ${location?.pathname === '/orders-history' || location?.pathname === '/' ? 'order__wrapper_medium' : ''}`}>
        <div className="order__block">
          <Status
            text={item?.type === 'buy' ? `Получение ${item?.order_category.toUpperCase()}` : `Выплата ${item?.order_category?.toUpperCase()}`}/>
          <p onClick={() => {
            toggleFieldTemporarily('id')
            copyElement(item?.id)
          }} className="order__block-text cur-p mt-4">
            <span className='order__copy'>
            {!initialState?.id ? (
              <img src="/assets/icons/copy.svg" alt="copy"/>
            ) : (
              <img src="/assets/icons/check-circle.svg" alt="check"/>
            )}
          </span>
            ID:{item?.id}
          </p>
        </div>
        {location?.pathname !== '/buy' && (
          <div className="order__block">
            <div className="order__block-wrapper">
              {/*Тут сделаны наша имя карты*/}
              <p onClick={() => {
                toggleFieldTemporarily('card_name')
                copyElement(item?.ad_data?.card_holder)
              }} className="order__block-text cur-p">
            <span className='order__copy'>
            {!initialState?.card_name ? (
              <img src="/assets/icons/copy.svg" alt="copy"/>
            ) : (
              <img src="/assets/icons/check-circle.svg" alt="check"/>
            )}
          </span>
                {item?.ad_data?.card_holder ? item?.ad_data?.card_holder : '--- --- ---'}
              </p>
              {/*Тут сделаны наш номер карты*/}
              <p onClick={() => {
                toggleFieldTemporarily('card_number')
                copyElement(item?.ad_data?.card_number !== null ? item?.ad_data?.card_number?.replaceAll(' ', '') : item?.ad_data?.phone_number !== null ? item?.ad_data?.phone_number : item?.ad_data?.requisite)
              }} className="order__block-text cur-p">
            <span className='order__copy'>
            {!initialState?.card_number ? (
              <img src="/assets/icons/copy.svg" alt="copy"/>
            ) : (
              <img src="/assets/icons/check-circle.svg" alt="check"/>
            )}
          </span>
                {item?.ad_data?.card_number !== null ? formatCreditCardNumber(item?.ad_data?.card_number) : formatPhoneNumber(item?.ad_data?.phone_number) !== null ? formatPhoneNumber(item?.ad_data?.phone_number) : formatCreditCardNumber(item?.ad_data?.requisite)}
              </p>
              {item?.ad_data?.comment !== '' && (
                <p className='comment'>{item?.ad_data?.comment}</p>
              )}
            </div>
          </div>
        )}
        <div className="order__block">
          {/*Тут сделаны название банка*/}
          <div className="order__block-bank ">
            <p
              className={`order__block-bankText ${(item?.type === 'sell' && item?.payment_method?.bank_name !== 'Sberbank') || (item?.type === 'buy' && item?.ad_data?.payment_method?.bank_name !== 'Sberbank') ? 'order__block-bankText_color' : ''}`}>{item?.type === 'sell' ? item?.payment_method?.bank_name : item?.ad_data?.payment_method?.bank_name}</p>
          </div>
        </div>
        {location?.pathname !== '/' && (
          <div className="order__block">
            {/*Тут сделаны номер карты получателя*/}
            <p onClick={() => {
              toggleFieldTemporarily('user_card_number')
              copyElement(item?.card_number !== null ? item?.card_number?.replaceAll(' ', '') : item?.phone_number !== null ? item?.phone_number : item?.requisite)
            }} className="order__block-text order__block-text_yellow cur-p">
            <span className='order__copy'>
            {!initialState?.user_card_number ? (
              <img src="/assets/icons/copy.svg" alt="copy"/>
            ) : (
              <img src="/assets/icons/check-circle.svg" alt="check"/>
            )}
          </span>
              {item?.card_number !== null ? formatCreditCardNumber(item?.card_number) : formatPhoneNumber(item?.phone_number) !== null ? formatPhoneNumber(item?.phone_number) : formatCreditCardNumber(item?.requisite)}
            </p>
            {(item?.ad_data?.comment !== '' && location?.pathname === '/buy') && (
              <p className='comment'>{item?.ad_data?.comment}</p>
            )}
          </div>
        )}
        <div className="order__block">
          <div className="order__block-wrapper">
            {/*Тут сделаны сумма в фиате*/}
            <p onClick={() => {
              toggleFieldTemporarily('amount_fiat')
              copyElement(item?.order_amount)
            }} className="order__block-text cur-p">
            <span className='order__copy'>
            {!initialState?.amount_fiat ? (
              <img src="/assets/icons/copy.svg" alt="copy"/>
            ) : (
              <img src="/assets/icons/check-circle.svg" alt="check"/>
            )}
          </span>
              {item?.order_amount}
            </p>
          </div>
        </div>
        {item?.order_stage === 'pending' ? (
          <>
            <div className="order__block">
              <p
                className="order__block-text">{remainingTime > 0 ? formatSecondsToTime(remainingTime * 60) : '00:00:00'}</p>
            </div>
            {location?.pathname !== '/buy' && (
              <div className="order__block">
                <div className="order__block-wrapper">
                  <p className="order__block-text">{moment.utc(item?.created_at).local().format('DD.MM.YYYY')}</p>
                  <p className="order__block-text">{moment.utc(item?.created_at).local().format('HH:mm:ss')}</p>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="order__block">
            <div className="order__block-wrapper">
              <p className="order__block-text">{moment.utc(item?.created_at).local().format('DD.MM.YYYY')}</p>
              <p className="order__block-text">{moment.utc(item?.created_at).local().format('HH:mm:ss')}</p>
            </div>
          </div>
        )}
        <div className="order__block">
          <Status
            stage={item?.order_stage}
            text={item?.order_stage === 'pending' ? 'В обработке' : item?.order_stage === 'success' ? 'Завершен' : item?.order_stage === 'cancel' ? 'Отменен' : 'Апелляция'}/>
        </div>
        <div className="order__block">
          {(location?.pathname === '/orders-history' && item?.order_stage === 'cancel' && item?.type === 'buy') && (
            <div onClick={() => {
              openAppealModal(item)
            }} className="order__block-btn mr-10">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 13.0391L8.00022 5.72903C8.04922 3.65003 9.75425 1.99203 11.8343 2.00003L16.935 2.00008L20.5002 6.03906L20.5 14.0274C20.515 16.1234 18.865 17.8534 16.7709 17.9384L11.8343 17.9384C11.8033 17.9394 11.7733 17.9384 11.7423 17.9384C11.3481 17.9296 10.9691 17.8615 10.6137 17.7429"
                  stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.5 2.575V4C16.5 5.575 17.3232 6.575 19.068 6.575H20.3232" stroke="#fff" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.5 8H11.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13 5.5H12.25H11.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                  d="M8 9.02734L5.72223 10.9797C4.94647 11.6447 4.5 12.6154 4.5 13.6371V22.5391M4.5 22.5391H3.51953C3.50874 22.5391 3.5 22.5303 3.5 22.5195V22.5195C3.5 22.5087 3.50874 22.5 3.51953 22.5H12.4805C12.4913 22.5 12.5 22.5087 12.5 22.5195V22.5195C12.5 22.5303 12.4913 22.5391 12.4805 22.5391H11.5M4.5 22.5391H11.5M11.5 22.5391V20.5L12.6708 19.9146C13.179 19.6605 13.5 19.1411 13.5 18.5729V17.9883"
                  stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                  d="M7.5 13.5L11.0498 10.3939C11.2843 10.1887 11.6474 10.2457 11.8078 10.513L12.2903 11.3171C12.7175 12.0292 12.6603 12.9313 12.1464 13.5837L11 15.0391V16.5391C11 17.6436 10.1046 18.5391 9 18.5391V18.5391"
                  stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          )}
          {(item?.order_stage === 'success' || item?.order_stage === 'cancel') && (
            <div onClick={() => {
              openDetailsModal(item)
            }} className="order__block-btn">
              <svg className="order__size-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12 2.75C17.108 2.75 21.25 6.891 21.25 12C21.25 17.108 17.108 21.25 12 21.25C6.891 21.25 2.75 17.108 2.75 12C2.75 6.891 6.891 2.75 12 2.75Z"
                      stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.9951 8.2041V12.6231" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M11.995 15.7959H12.005" stroke="white" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
          )}
          {(item?.order_stage !== 'success' && item?.order_stage !== 'cancel') && (
            <div className={`order__block-btn`}>
              <div className={`order__block-more-btns`}>
                {(location?.pathname !== '/orders-appeal' && item?.order_stage !== 'success' && item?.order_stage !== 'cancel') && (
                  <div onClick={() => {
                    openAcceptModal(item)
                    localStorage.setItem('order_id', item?.id)
                  }} className="order__block-more-btn">
                    <svg className="order__size-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z"
                            stroke="green" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8.43945 12L10.8135 14.373L15.5595 9.62695" stroke="green" strokeWidth="1.5"
                            strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                )}
                {(location?.pathname === '/orders-appeal' && item?.order_stage !== 'success' && item?.order_stage !== 'cancel' && item?.type !== 'sell') && (
                  <div onClick={() => {
                    openAcceptBuyModal(item)
                    localStorage.setItem('order_id', item?.id)
                  }} className="order__block-more-btn">
                    <svg className="order__size-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z"
                            stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M8.43945 12L10.8135 14.373L15.5595 9.62695" stroke="green" strokeWidth="1.5"
                            strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                )}
                {(location?.pathname !== '/orders-appeal' && item?.type === 'sell' && item?.order_stage !== 'success' && item?.order_stage !== 'cancel') && (
                  <div onClick={() => {
                    localStorage.setItem('order_id', item?.id)
                    openRejectModal(item)
                  }} className="order__block-more-btn">
                    <svg className="order__size-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.3955 9.59473L9.60352 14.3867" stroke="red" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M14.3976 14.3898L9.60156 9.59277" stroke="red" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M16.335 2.75H7.66598C4.64498 2.75 2.75098 4.889 2.75098 7.916V16.084C2.75098 19.111 4.63598 21.25 7.66598 21.25H16.334C19.365 21.25 21.251 19.111 21.251 16.084V7.916C21.251 4.889 19.365 2.75 16.335 2.75Z"
                            stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                )}
                {/*{(location?.pathname === '/orders-appeal' && item?.order_stage !== 'success' && item?.order_stage !== 'cancel' && item?.type !== 'buy') && (*/}
                {/*  <div onClick={() => {*/}
                {/*    localStorage.setItem('order_id', item?.id)*/}
                {/*    openCancelledModal(item)*/}
                {/*  }} className="order__block-more-btn">*/}
                {/*    <svg className="order__size-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*      <path d="M14.3955 9.59473L9.60352 14.3867" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*      <path d="M14.3976 14.3898L9.60156 9.59277" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*      <path fill-rule="evenodd" clipRule="evenodd" d="M16.335 2.75H7.66598C4.64498 2.75 2.75098 4.889 2.75098 7.916V16.084C2.75098 19.111 4.63598 21.25 7.66598 21.25H16.334C19.365 21.25 21.251 19.111 21.251 16.084V7.916C21.251 4.889 19.365 2.75 16.335 2.75Z" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*    </svg>*/}
                {/*    <p>4</p>*/}
                {/*  </div>*/}
                {/*)}*/}
                <div onClick={() => {
                  openDetailsModal(item)
                }} className="order__block-more-btn">
                  <svg className="order__size-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 2.75C17.108 2.75 21.25 6.891 21.25 12C21.25 17.108 17.108 21.25 12 21.25C6.891 21.25 2.75 17.108 2.75 12C2.75 6.891 6.891 2.75 12 2.75Z"
                          stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.9951 8.2041V12.6231" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M11.995 15.7959H12.005" stroke="white" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
